import React from 'react'
import HubspotContactForm from './HubspotContactForm';
import Logo from "../../assets/kontevo-logo.png";

const Newsletter = () => {
  
   const handleOnSubmit = (e) => {
    e.preventDefault();
     // Agregar aquí la lógica para procesar el formulario sin recargar la página
  }
  
  return (
    <div className="w-full h-screen flex flex-col gap-4 items-center justify-center bg-white">
     <img src={Logo} alt="logo-kontevo" className="md:w-1/3 xs:w-1/2"/>
     <HubspotContactForm />
    </div>
  );
}

export default Newsletter
