import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/* Obtener todas las ciudades */
export const getCities = createAsyncThunk(
  "cities/getCities",
  async (arg, thunkAPI) => {
    try {
      const response = await axios.get(`${SERVER_URL}/cities`);
      const cities = response.data;
      return cities;
    } catch (error) {
      console.log(`error en GetCities thunk`, error);
    }
  }
);

/* Obtener una sola ciudad */
export const getSingleCity = createAsyncThunk(
  "cities/getSingleCity",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get(`${SERVER_URL}/cities/${id}`);
      const city = response.data;
      return city;
    } catch (error) {
      console.log(`error en GetCities thunk`, error);
    }
  }
);

/* Agregar una ciudad */
export const addCity = createAsyncThunk(
  "cities/addCity",
  async ({ full_name }, thunkAPI) => {
    try {
      const response = await axios.post(`${SERVER_URL}/cities/add-city`, {
        full_name,
      });
      const newCity = response.data;
      return newCity;
    } catch (error) {
      console.log(`error on addCity thunk`, error);
    }
  }
);

/* Eliminar una ciudad */
export const deleteCity = createAsyncThunk(
  "cities/deleteCity",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.delete(`${SERVER_URL}/cities/${id}`);
      const deletedCity = response.data;
      return deletedCity;
    } catch (error) {
      console.log(`error en deleteCity thunk`, error);
    }
  }
);

/* Actualizar una ciudad */
export const updateCity = createAsyncThunk(
  "cities/updateCity",
  async ({ id, full_name }, thunkAPI) => {
    try {
      const response = await axios.put(`${SERVER_URL}/cities/${id}`, {
        full_name,
      });
      const updatedCity = response.data;
      return updatedCity;
    } catch (error) {
      console.log(`error on addCity thunk`, error);
    }
  }
);

export const citiesSlice = createSlice({
  name: "cities",
  initialState: {
    cities: [],
    single_city: null,
    isLoading: false,
    hasError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSingleCity.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getSingleCity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const singleCity = action.payload;
        state.single_city = singleCity;
      })
      .addCase(getSingleCity.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(getCities.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getCities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const cities = action.payload;
        state.cities = cities;
      })
      .addCase(getCities.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(updateCity.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(updateCity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const updatedCity = action.payload;
        state.single_city = updatedCity;
        const index = current(state).cities.findIndex(
          (city) => city.id === updatedCity.id
        );
        if (index !== -1) {
          state.cities[index] = updatedCity;
          console.log("Ciuddad actualizada", state.cities[index]);
        } else {
          console.log("Ciucad no encontrada");
        }
      })
      .addCase(updateCity.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(deleteCity.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(deleteCity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const deletedCity = action.payload;
        state.cities = state.cities.filter(
          (city) => city.id !== parseInt(deletedCity.id)
        );
      })
      .addCase(deleteCity.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(addCity.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(addCity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const newCity = action.payload;
        state.cities.push(newCity);
      })
      .addCase(addCity.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      });
  },
});

export const select_cities_is_loading = (state) => state.cities.isLoading;
export const select_single_city = (state) => state.cities.single_city;
export const select_cities = (state) => state.cities.cities;
export default citiesSlice.reducer;
