import React from "react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { AuthContext } from "../../App";
import { useNavigate } from "react-router-dom";

const AccountPage = () => {
  const { user } = React.useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <div className="w-full">
      {!user && (
        <li className="custom-li flex gap-2 items-baseline !pl-10 cursor-pointer">
          <span>
            <AccountCircleOutlinedIcon />
          </span>
          <span className="text-[13px]" onClick={() => navigate("/signin")}>
            Iniciar Sesión
          </span>
        </li>
      )}
    </div>
  );
};

export default AccountPage;
