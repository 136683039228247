import React, { useState } from "react";
import { useSelector } from "react-redux";
/* Componentes */
import Results from "../../features/results/Results";
/* Bootstrap */
import { Spinner } from "react-bootstrap";


export const SearchResultsPage = () => {
  const isMediaLoading = useSelector((state) => state.igFeed.isLoading);
  /* isOpen is used for mobile filters modal */
  const [isMobileFilterOpen, setIsMobileFilterOpen ] = useState(false);


  return (
    <div
      className={`${
        isMobileFilterOpen && `overflow-hidden`
      } w-full flex xs:flex-col md:flex-row`}
      style={{ height: "100vh" }}
    >
      {!isMediaLoading ? (
        <div className={`md:flex md:flex-row xs:w-full xs:flex xs:flex-col`}>
          <Results
            setIsMobileFilterOpen={setIsMobileFilterOpen}
            isMobileFilterOpen={isMobileFilterOpen}
          />
        </div>
      ) : (
        <div className="flex w-full justify-center text-center py-4">
          <Spinner animation="border" />
        </div>
      )}
    </div>
  );
};

export default SearchResultsPage;

