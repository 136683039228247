import React, { useState, useEffect } from "react";
import { AuthContext } from "../../App";
/* React Router */
import { Navigate, Outlet } from "react-router-dom";

const AuthLayout = () => {

  const {user} = React.useContext(AuthContext);
  //const user = JSON.parse(localStorage.getItem("user"));
  

  return <>{!user ? <Outlet /> : <Navigate to="/" />}</>;
};

export default AuthLayout;
