import React from "react"

const AdminHome = (props) => {
  return (
    <div className="admin-home">
      Admin Home
    </div>
  )
};

export default AdminHome;
