import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Isologo } from "../../assets/isologo.svg";
/* React Bootstrap */
import { Form, Col, Row, Button, Container } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import "./signup.css";
import {
  addUser,
  checkUserExistence,
  select_is_loading,
  select_user_exists,
} from "../../features/users/usersSlice";

/* react router */
import { useNavigate } from "react-router-dom";

const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [emailAlert, setEmailAlert] = useState(false);
  const [emptyAlert, setEmptyAlert] = useState(false);
  const [emailValidFormat, setEmailValidFormat] = useState(true);
  const isLoading = useSelector(select_is_loading);
  const userExists = useSelector(select_user_exists);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("is clickedd", isClicked);
    console.log("user exists?", userExists);
    if (userExists) {
      setEmailAlert(true);
      setIsClicked(false);
    } else if (!userExists && isClicked) {
      setEmailAlert(false);
      setIsClicked(false);
      dispatch(addUser({ email, password }));
      navigate("/signin");
    }
  }, [userExists]);

  /* Al hacer click en Crear tu cuenta */
  const handleOnClick = (e) => {
    e.preventDefault();
    //Validamos que se haya ingresado un email y un password y que además el email cumpla con el formato deseado
    if (email && emailValidFormat && password) {
      dispatch(checkUserExistence({ email }));
      setEmptyAlert(false);
      setIsClicked(true);
    } else {
      setEmptyAlert(true);
    }
  };

  /* Validar campo email con el formato @.com */
  const validateEmail = () => {
    //Patrón deseado
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = regexEmail.test(email);
    setEmailValidFormat(isValid);
  };

  return (
    <Row>
      <Col md={6} id="signup-left" className="flex flex-column">
        <Container
          className="flex flex-column justify-baseline justify-evenly"
          style={{ height: "300px" }}
        >
          <Row>
            <Col>
              <Isologo className="isologo" />
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 className="h1-title">
                Regístrate en <br />
                Kontevo
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-light-blue">
                Inicia tu prueba gratuita
                <br />
                No necesitas tarjeta de crédito
              </p>
            </Col>
          </Row>
        </Container>
      </Col>
      <Col md={6} id="signup-right" className="full-height">
        <Row>
          <Col className="flex aling-end justify-end">
            Ya tienes una cuenta?
            <a href="/signin" id="signin-link">
              Iniciar Sesión
            </a>
          </Col>
        </Row>
        <Row className="">
          <Form id="signup-form" className="flex flex-col gap-4">
            <Form.Group controlId="formEmail">
              <Form.Label>Correo electrónico*</Form.Label>
              <Form.Control
                className="form-input border border-solid !border-[#000066] email-input"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                onBlur={validateEmail} //Se llama este evento cuando el input   pierde el foco
              />
              {!emailValidFormat && (
                <span className="form-alert">
                  El formato de email no es válido.
                </span>
              )}
              {emailAlert && (
                <span className="form-alert">
                  Este usuario ya tiene cuenta en Kontevo
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Contraseña*</Form.Label>
              <Form.Control
                className="form-input border border-solid !border-[#000066]"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Button
              className="py-[17.5px] bg-[#66CC33] text-white border-none hover:bg-[#FF9933]"
              type="submit"
              onClick={handleOnClick}
            >
              {/* {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Crea tu cuenta"
              )} */}
              Crea tu cuenta
            </Button>
            {emptyAlert && (
              <span className="form-alert">Rellena todos los campos</span>
            )}
          </Form>
        </Row>
      </Col>
    </Row>
  );
};

export default SignupPage;
