/* Reeact / Redux */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import MobileFiltersMenu from "../filters/mobileFiltersMenu/MobileFiltersMenu";
import SidebarFilters from "../filters/sidebarFilters/SidebarFilters";
import FilterListIcon from "@mui/icons-material/FilterList";
import FavsModal from "../favsModal/FavsModal";
import Post from "../media/feedPost/Post";
import { setPage } from "../postTracker/igFeedSlice";
import {
  select_filtered_media,
  applyActiveFilters,
} from "../postTracker/igFeedSlice";
import Feedback from "./Feedback";


const filterNames = ["Categoria", "Cuentas", "Fecha"];







/*COMPONENT */
const Results = ({setIsMobileFilterOpen, isMobileFilterOpen}) => {
  const { media, searchedTag, currentPage, isLoading, matchedCategories } =
    useSelector((state) => state.igFeed);

  const filteredMedia = useSelector(select_filtered_media);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [favPost, setFavPost] = useState({});
  const [posts, setPosts] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.width);
  const [notFound, setNotFound] = useState(
    "No se han encontrado más resultados"
  );

  /* infinite Scrolling variables */
  const itemsPerPage = 30;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  //const media = useSelector(select_media);
  const dispatch = useDispatch();

  /* USE EFFECTS */
  useEffect(() => {
    setPosts(media);
  }, [media]);

  useEffect(() => {
    setPosts(filteredMedia);
    if (filteredMedia.length > 0) {
      setNotFound("");
    } else {
      setNotFound("No se han encontrado resultados");
    }
  }, [filteredMedia]);

  /* Agregar event listener apuntando a la función handleScroll para infinite scrolling*/
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Limpia el listener al desmontar el componente
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentPage, isLoading]);

   useEffect(() => {
     const handleResize = () => {
       setWindowWidth(window.innerWidth);
     };
     window.addEventListener("resize", handleResize);
     // Retornar una función de limpieza para eliminar el event listener cuando el componente se desmonte
     return () => {
       window.removeEventListener("resize", handleResize);
     };
   }, []);

  /* FUNCTIONS */
  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    // Verifica si el usuario llegó al final de la página
    if (scrollTop + clientHeight >= scrollHeight - 10 && !isLoading) {
      dispatch(setPage(currentPage + 1)); // Incrementa el número de la página
    }
  };

  const handleCategoryClick = (category) => {
    dispatch(applyActiveFilters({ filteredCategoryIds: [category.id] }));
  };

  
  return (
    /* MAIN CONTAINER */
    <div className="w-full h-full flex relative">
      {/*LEFT COLUMN */}
      <div
        className={`md:flex xs:hidden ${
          isSidebarOpen ? "w-[30%]" : "w-0"
        } transition-all duration-300`}
      >
        <SidebarFilters
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      </div>
      {/*RIGTH COLUMN*/}
      <div
        className={`pl-3 md:w-[70%] xs:w-full md:h-[100%] flex flex-col ${
          isSidebarOpen ? "flex-none" : "flex-1"
        }`}
      >
        {/* HEADER */}
        <div className="flex flex-col justify-center items-center gap-2 xs:pt-16 xs:pb-2 md:w-full md:h-[10%] md:py-16">
          <div className="w-full flex justify-center">
            <span className="text-[16px]">Categorias</span>
          </div>
          {/* SECOND ROW */}
          <div className="w-full md:relative flex justify-center items-baseline gap-4">
            {/* category list */}
            <div className="flex gap-2">
              {matchedCategories
                .slice(0, windowWidth < 640 ? 3 : 5)
                .map((cat, index) => (
                  <span
                    className="py-1 px-2 border rounded-full !border-[#000066] text-[#0066CC] shadow text-[14px] cursor-pointer hover:font-bold"
                    key={index}
                    onClick={() => {
                      handleCategoryClick(cat);
                    }}
                  >
                    {cat.name}
                  </span>
                ))}
            </div>
            {/* FILTER BUTTON*/}
            <div className="flex md:absolute md:bottom-0 md:left-16">
              <button
                onClick={() => {
                  setIsOpen(true);
                  setIsSidebarOpen(!isSidebarOpen);
                  setIsMobileFilterOpen(!isMobileFilterOpen);
                }}
                className="flex justify-center items-center gap-1 text-base !border border-solid border-[#000066] text-white bg-[#000066] xs:p-2 md:p-2 rounded-full"
              >
                <FilterListIcon />
              </button>
            </div>
          </div>
          <div className="md:hidden">
            <MobileFiltersMenu
              isMobileFilterOpen={isMobileFilterOpen}
              setIsMobileFilterOpen={setIsMobileFilterOpen}
              filterNames={filterNames}
            />
          </div>
        </div>
        {/* RESULTS */}
        {posts.length > 0 ? (
          /* posts */
          <div className=" md:h-[90%] flex flex-wrap justify-center gap-4 md:overflow-y-scroll xs:py-4 xs:px-8 ">
            {posts.slice(0, endIndex).map((post, index) => {
              return (
                post.media_url && (
                  <Post
                    key={index}
                    index={index}
                    post={post}
                    setIsOpen={setIsOpen}
                    setFavPost={setFavPost}
                    favPost={favPost}
                    isSidebarOpen={isSidebarOpen}
                  />
                )
              );
            })}
            {isLoading && <p>Cargando...</p>}
          </div>
        ) : (
          <Feedback searchedTag={searchedTag} />
        )}
      </div>
      {/* Add Favorite Post Modal */}
      <FavsModal
        title="Favoritos"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        favPost={favPost}
      />
    </div>
  );
};

export default Results;
