import React from "react";
/* React Bootstrap */
import { Container, Col, Row } from "react-bootstrap";

const VideoMedia = ({ url, setUrlHasError }) => {
  
  const handleUrlError = () => {
    setUrlHasError(true);
  };
  
  return (
    <div className="flex flex-col p-0">
      <video
        className="md:w-full md:!h-[414px] xs:w-full xs:!h-[233px] border-none bg-[#F8F8F8]"
        controls
        autoPlay
        muted
        onError={handleUrlError}
      >
        <source src={url} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoMedia;
