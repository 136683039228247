import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/* Obtener cuentas de instagram de mi base de datos */
export const getDbIgAccounts = createAsyncThunk(
  "postgres/getDbIgAccounts",
  async (arg, thunkAPI) => {
    const response = await axios.get(`${SERVER_URL}/instagram-accounts`);
    const instagram_accounts = response.data;
    return instagram_accounts;
  }
);

/* Guardar json con posts de una cuenta de instagram en la base de datos */
export const saveJsonPosts = createAsyncThunk(
  "postgres/saveJsonPosts",
  async ({ username, posts_json }, thunkAPI) => {
    try {
      const response = await axios.put(
        `${SERVER_URL}/instagram-accounts/${username}`,
        { posts_json: posts_json }
      );
    } catch (error) {
      console.log(error);
    }
  }
);

/* Obtener categorias de cuentas almacenadas en la base de datos */

/* Crear una categoria */

export const postgresSlice = createSlice({
  name: "postgres",
  initialState: {
    instagram_accounts: [],
    igs_by_category: [],
    categories: null,
    isLoading: false,
    hasError: false,
  },
  reducers: {
    GetIgAccountsByCategory: (state, action) => {
      const { category } = action.payload;
      const filteredAccounts = current(state)
        .instagram_accounts.filter((account) => account.category === category)
        .map((account) => {
          return account;
        });
      state.igs_by_category = filteredAccounts;
      console.log(
        "instagram accounts filtered by category fulfilled: ",
        current(state).igs_by_category
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDbIgAccounts.pending, (state) => {
      state.hasError = false;
      state.isLoading = true;
      //console.log("Retrieve instagram accounts from database pending ");
    });
    builder.addCase(getDbIgAccounts.fulfilled, (state, action) => {
      state.hasError = false;
      state.isLoading = false;
      const instagram_accounts = action.payload;
      state.instagram_accounts = instagram_accounts;

      /*  console.log(
        "Retrieve instagram accounts from database fulfilled:",
        state.instagram_accounts
      ); */
    });
    builder.addCase(getDbIgAccounts.rejected, (state, action) => {
      state.hasError = true;
      state.isLoading = false;
      //console.log("Retrieve instagram accounts from database failed");
    });
    builder.addCase(saveJsonPosts.pending, (state) => {
      state.hasError = false;
      state.isLoading = true;
    });
    builder.addCase(saveJsonPosts.fulfilled, (state, action) => {
      state.hasError = false;
      state.isLoading = false;
    });
    builder.addCase(saveJsonPosts.rejected, (state, action) => {
      state.hasError = true;
      state.isLoading = false;
    });
  },
});

export const select_igs_by_category = (state) => state.postgres.igs_by_category;
export const select_db_instagram_accounts = (state) =>
  state.postgres.instagram_accounts;

export const { GetIgAccountsByCategory } = postgresSlice.actions;
export default postgresSlice.reducer;
