import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

//Obtener posts de una cuenta por su id
export const getPostsByAccountId = createAsyncThunk(
  "posts/getPostsByAccountId",
  async ({ accountId, keywords }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/api/instagram-posts/ig-account/${accountId}`,
        {
          params: { keywords: keywords },
        }
      );
      const posts = response.data;
      return posts;
    } catch (error) {
      console.log("error", error);
      return [];
    }
  }
);

//Obtener posts según su id
export const getFeaturedDeals = createAsyncThunk("posts/getFeaturedDeals", async ({ postsIds }, thunkAPI) => {

 try {
   const response = await axios.get(`${SERVER_URL}/api/instagram-posts/`, {
     params: {
       postsIds: postsIds.join(",")
     },
   });
   const featuredDeals = response.data; 
   return featuredDeals; 
 } catch (error) {
   console.log("error", error);
   return [];
 } 
})

//Obtener posts de las cuentas pertenecientes a las subcategorias de una categoria padre
export const getPostsByParentCatId = createAsyncThunk(
  "posts/getPostsByParentCatId",
  async ({ parentId, keywords }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/api/instagram-posts/categories/${parentId}/posts`,
        {
          params: { keywords: keywords },
        }
      );
      const posts = response.data;
      return posts;
    } catch (error) {
      console.log("error", error);
      return [];
    }
  }
);


export const postsSlice = createSlice({
  name: "posts",
  initialState: {
    posts: [],
    featuredDeals: [],
    hasError: false,
    isLoading: false,
  },
  reducers: {
    setPosts: (state, action) => {
      state.posts = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPostsByAccountId.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        state.posts = [];
      })
      .addCase(getPostsByAccountId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const posts = current(state).posts;
        state.posts = action.payload;
      })
      .addCase(getPostsByAccountId.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(getFeaturedDeals.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getFeaturedDeals.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.featuredDeals = action.payload;
      })
      .addCase(getFeaturedDeals.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(getPostsByParentCatId.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getPostsByParentCatId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.posts = action.payload;
      })
      .addCase(getPostsByParentCatId.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      });
  },
});

export const {
  setPosts
} = postsSlice.actions;
export default postsSlice.reducer;
