import React, { useState} from "react";
import {useDispatch } from "react-redux";
import { bulkIgAccount } from "../slices/instagamAccountsSlice";
import Papa from "papaparse";

const CSVUploader = () => {
  /* Local states */
  const [fileUploaded, setFileUploaded] = useState(null);
  const [csvHeader, setCsvHeader] = useState([]);
  const [isCsv, setIsCsv] = useState(false);
  const [rows, setRows] = useState([]);

  /* redux dispatch */
  const dispatch = useDispatch(); 

  //función para revisar si la extensión del archivo cargado es .csv
  const checkCsvExtension = (file) => {
    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
    if (fileExtension === "csv") {
      //organizar los datos del csv
      parseCsv(file);
      setFileUploaded(file);
      setIsCsv(true);
    } else {
      alert("Por favor, selecciona un archivo CSV.");
      setIsCsv(false);
    }
  };

  //función para analizar y procesar los datos dentro de un csv
  const parseCsv = (file) => {
    //usamos la libreria papaparse para extraer y organizar los datos del csv
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: (result) => {
        //guardamos los headers de la tabla contenida en el csv
        setCsvHeader(result.meta.fields);
        //guardamos todas las filas en pares clave:valor donde la clave es el header y el valor es el valor del campo.
        setRows(result.data);
      },
    });
  };

  // Verificar que estén los encabezados correspondientes a las columnas.
  const checkHeader = () => {
    //Columnas requeridas en el csv
    console.log("csvHeader", csvHeader);
    const reqHeader = ["username", "category", "city"];
    //comparar cantidad de de encabezados
    if (reqHeader.length !== csvHeader.length) {
      alert("El archivo csv no cumple con la cantidad de columnas requeridas");
      return false;
    }
    //Verificar que el encabezado sea igual. Retorna un boolean
    const isSameHeader = csvHeader.every((field) => reqHeader.includes(field));
    return isSameHeader;
  };

  const checkRows = () => {
    if (rows.length > 0) {
      return true;
    } else {
      alert("El archivo insertado no contiene cuentas que agregar");
      return false;
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    //comprobar extensión = .csv
    checkCsvExtension(file);
  };


  //función ejecutada al hacer click en enviar
  const handleUpload = async () => {
    if (!fileUploaded) {
      alert(
        "Ingresa un archivo .csv con las cuentas de instagram que deseas agregar"
      );
      return;
    } else {
      if (isCsv) {
        //Revisar columnas del header en el .csv ingresado
        const sameHeader = checkHeader();
        //Revisar que existan filas que agregar
        const hasRows = checkRows();
        //si se cumplen todas las condiciones, entonces hacer un post request al servidor
        if (sameHeader && hasRows) {
          const csvFile = new FormData();
          csvFile.append("file", fileUploaded);
          //Trigger action for adding multiple accounts in bulk
          dispatch(bulkIgAccount({ csvFile }));
        }
      }
    }
  };

  return (
    <div className="flex flex-col gap-2.5">
      <div className="mt-2.5">
        <h3>{`Cargar archivo csv`}</h3>
      </div>
      <div className="flex gap-2 text-base  text-[#333] cursor-pointer outline-none  focus:text-[#555] hover:border-[#aaa]">
        <div className="relative cursor-pointer">
          <input
            type="file"
            className="absolute inset-0 w-full h-full opacity-0 !cursor-pointer"
            onChange={handleFileChange}
          />
          <div className="py-1 px-4 border border-solid border-[#ccc] bg-white rounded-md !cursor-pointer outline-none text-base focus:text-[#555] hover:border-[#aaa]">
            Choose File
          </div>
        </div>
        <button
          className="py-1 px-4 cursor-pointer bg-[#FF9933] border border-solid !border-transparent rounded-md font-bold text-[#000066] text-sm"
          onClick={handleUpload}
        >
          Enviar
        </button>
      </div>
      <div className="text-sm text-[#66CC33]">{fileUploaded?.name}</div>
    </div>
  );
};

export default CSVUploader;

/* p-2.5 border border-solid border-[#ccc] rounded-md bg-white  */