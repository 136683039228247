import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { getCategoryAccounts } from "../slices/igAccountsCategoriesSlice";
import { saveJsonPosts } from "../slices/postgresSlice";
import { stopWords } from "./stopWords";
import axios from "axios";
import { gptPostsFilter } from "../../utils/gptSearch";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/* AI algo */
export const searchWithGPT = createAsyncThunk(
  "igFeed/searchWithGPT",
  async (
    {
      userPrompt,
      defaultPrompt,
      kwdsMatchCondition,
      dateCondition,
      random,
      limit,
      orderByKwdCount,
    },
    thunkAPI
  ) => {
    /*Condition 
    every: All keywords from the user prompt must match the caption.
    some: One or morre keywrods from the user prompt must match the caption. 
    */
    /* const { matchedAccounts, matchedCategories, matchedIgPosts } = */
    /*   await gptPostsFilter(userPrompt, kwdsMatchCondition, dateCondition); */
    const response = await axios.get(`${SERVER_URL}/gpt/`, {
      params: {
        userPrompt,
        defaultPrompt,
        kwdsMatchCondition,
        dateCondition,
        random,
        limit,
        orderByKwdCount,
      },
    });
    const { matchedCategories, matchedAccounts, matchedIgPosts } =
      response.data;
    return {
      matchedCategories,
      matchedAccounts,
      matchedIgPosts,
    };
  }
);



//Revisar que filtros se encuentran activos y aplicarlos
export const applyActiveFilters = createAsyncThunk(
  "igFeed/applyActiveFilters",
  async (activeFilters, thunkAPI) => {
    console.log("activeFilters", activeFilters);
    //Reseteo de filteredMedia antes de volver a aplicar los filtros.
    await thunkAPI.dispatch(setFilteredMedia());

    //Inicializo variable array con ids de categorias y cuentas filtradas. Si no recibo nuevos ids, entonces su valor será igual al del state previamente almacenado.
    const filteredCategoryIds =
      activeFilters.filteredCategoryIds ||
      thunkAPI.getState().igFeed.filteredCategoryIds;

    const filteredAccountIds =
      activeFilters.filteredAccountIds ||
      thunkAPI.getState().igFeed.filteredAccountIds;

    //Inicializo variables tipo string con fecha inicial y fecha final para filtrar.
    const filteredStartDate =
      activeFilters.filteredStartDate ||
      thunkAPI.getState().igFeed.filteredStartDate;

    const filteredEndDate =
      activeFilters.filteredEndDate ||
      thunkAPI.getState().igFeed.filteredEndDate;

    console.log("filteredStartDate", filteredStartDate);
    console.log("filteredEndDate", filteredEndDate);

    //Aplicar filtrado por cuenta
    if (filteredAccountIds.length > 0) {
      await thunkAPI.dispatch(filterByAccount(filteredAccountIds));
    } else {
      await thunkAPI.dispatch(setFilteredAccountIds([]));
    }

    //Aplicar filtrado por categoria
    if (filteredCategoryIds.length > 0) {
      await thunkAPI.dispatch(filterByCategory(filteredCategoryIds));
    } else {
      await thunkAPI.dispatch(setFilteredCategoryIds([]));
    }

    //Aplicar filtrado por fecha en caso de que  fecha inicial o fecha final existan
    if (filteredStartDate || filteredEndDate) {
      await thunkAPI.dispatch(
        filterByDate({ filteredStartDate, filteredEndDate })
      );
    } else {
      await thunkAPI.dispatch(setFilteredDates({ startDate: "", endDate: "" }));
    }

    //Si no existen filtros activos, entonces reiniciar state.filteredMedia
    if (
      !filteredCategoryIds.length > 0 &&
      !filteredAccountIds.length > 0 &&
      !filteredStartDate &&
      !filteredEndDate
    ) {
      await thunkAPI.dispatch(resetFilters());
    }
  }
);

const mergeFilteredMedia = (activeFilters, property) => {
  let media = [];
  if (activeFilters.hasOwnProperty(property)) {
    delete activeFilters[property];
  }
  media = Object.keys(activeFilters).reduce((accumulator, filterName) => {
    return accumulator.concat(activeFilters[filterName]);
  }, []);
  return media;
};

const igFeedSlice = createSlice({
  name: "igFeed",
  initialState: {
    //cuentas y categorias encontradas en la búsqueda
    matchedAccounts: [],
    matchedCategories: [],
    //ids de cuentas y categorias filtrados
    filteredAccountIds: [],
    filteredCategoryIds: [],
    //fecha inicial y final filtradas.
    filteredStartDate: "",
    filteredEndDate: "",
    accounts_index: null,
    resetFilters: null,
    searchedTag: null,
    filteredMedia: [],
    isLoading: false,
    hasError: false,
    media: [],
    /* Infinite Scrolling */
    currentPage: 1,
  },
  reducers: {
    //Vaciar el array de resultados
    setMediaEmpty: (state, action) => {
      state.media = [];
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    //Configurar el estado filteredAccountIds que consiste en un array de ids de cuentas seleccionados en los filtros
    setFilteredAccountIds: (state, action) => {
      state.filteredAccountIds = action.payload;
    },
    //Configurar el estado filteredCategoryIds que consiste en un array de ids de categorias seleccionados en los filtros
    setFilteredCategoryIds: (state, action) => {
      state.filteredCategoryIds = action.payload;
    },
    setFilteredDates: (state, action) => {
      state.filteredStartDate = action.payload.startDate;
      state.filteredEndDate = action.payload.endDate;
    },
    //Volver a hacer que filteredMedia sea igual al resultado original de busqueda sin filtros
    setFilteredMedia: (state, action) => {
      state.filteredMedia = current(state).media;
    },
    resetFilters: (state, action) => {
      //Reiniciamos array con ids de cuentas filtradas
      state.filteredAccountIds = [];
      //Reiniciamos array con ids de categorias filtradas
      state.filteredCategoryIds = [];
      //Reiniciamos fechas filtradas
      state.filteredStartDate = "";
      state.filteredEndDate = "";
      //Reiniciamos array de publicaciones filtradas para que sea igual al resultado original de la busqueda
      state.filteredMedia = current(state).media;
    },
    filterIgPosts: (state, action) => {
      const { keyword } = action.payload;
      const media = current(state).media;
      const foundMedia = media.filter((currentMedia) => {
        if (
          currentMedia.caption &&
          currentMedia.caption.toLowerCase().includes(keyword.toLowerCase())
        ) {
          return currentMedia;
        }
      });
      state.tag_filtered_media[keyword] = foundMedia;
    },
    filterByCategory: (state, action) => {
      const filteredMedia = current(state).filteredMedia;
      const igCategoriesIds = action.payload;
      let newFilteredMedia = [];
      newFilteredMedia = filteredMedia.filter(
        (post) =>
          post.category_ids?.some((categoryId) =>
            igCategoriesIds.includes(categoryId)
          )
        //igCategoriesIds.includes(post.ig_account_id)
      );
      //id's de las casillas marcadas (checkbox) al filtra por categoria
      state.filteredCategoryIds = action.payload;
      //publicaciones filtradas por cuenta
      state.filteredMedia = newFilteredMedia;
    },
    filterByAccount: (state, action) => {
      const filteredMedia = current(state).filteredMedia;
      const igAccountIds = action.payload;
      let newFilteredMedia = [];
      //FIltrar los posts que tengan un id de la lista de cuentas marcadas
      newFilteredMedia = filteredMedia.filter((post) =>
        igAccountIds.includes(post.instagram_account_id)
      );
      //id's de las casillas marcadas (checkbox) al filtra por cuenta
      state.filteredAccountIds = action.payload;
      //publicaciones filtradas por cuenta
      state.filteredMedia = newFilteredMedia;
    },
    filterByDate: (state, action) => {
      //Recibo la fecha inicial y final de action payload
      const { filteredStartDate, filteredEndDate } = action.payload;
      //publicaciones que vamos a  filtrar por fecha
      const filteredMedia = current(state).filteredMedia;

      /* Convertir formato de fecha */
      const startDate = filteredStartDate
        ? new Date(filteredStartDate.replace(/-/g, "/"))
        : null;
      const endDate = filteredEndDate
        ? new Date(filteredEndDate.replace(/-/g, "/"))
        : null;

      //Filtrado por fechas
      console.log("old", filteredMedia);
      const newFilteredMedia = filteredMedia.filter((currentMedia) => {
        const mediaDate = new Date(currentMedia.timestamp);
        console.log("mediaDate", mediaDate);
        if (startDate && endDate) {
          console.log("if(1)", startDate);
          console.log("if(1)", endDate);
          if (mediaDate >= startDate && mediaDate < endDate) {
            console.log("true");
          } else {
            console.log("false");
          }
          return mediaDate >= startDate && mediaDate < endDate;
        } else if (!startDate && !endDate) {
          console.log("if(2)", startDate);
          console.log("if(2)", endDate);
          if (!startDate && !endDate) return true;
        } else if (startDate && !endDate) {
          console.log("if(3)", startDate);
          console.log("if(3)", endDate);
          return mediaDate >= startDate;
        } else if (!startDate && endDate) {
          console.log("if(4)", startDate);
          console.log("if(4)", endDate);
          return mediaDate < endDate;
        }
      });
      console.log("new", newFilteredMedia);
      //fecha inicial por la que se filtro
      state.filteredStartDate = filteredStartDate;
      //fecha final por la que se filtro
      state.filteredEndDate = filteredEndDate;
      //nuevas publicaciones filtradas
      state.filteredMedia = newFilteredMedia;
      console.log("state.filteredMedia", state.filteredMedia);
    },
    filterByLocation: (state, action) => {
      const currentState = current(state);
      const filteredMedia = currentState.filteredMedia;
      const activeFilters = { ...currentState.activeFilters };
      let media = [];
      if (filteredMedia.length > 0) {
        media = mergeFilteredMedia(activeFilters, "filterByLocation");

        if (!media.length > 0) {
          media = currentState.media;
        }
      } else {
        media = currentState.media;
      }

      const igAccountIds = action.payload;
      let filteredMediaByLocation = [];
      if (igAccountIds.length > 0) {
        filteredMediaByLocation = media.filter((post) =>
          igAccountIds.includes(post.ig_account_id)
        );
        activeFilters["filterByLocation"] = filteredMediaByLocation;
      } else if (Object.keys(activeFilters).length > 0) {
        filteredMediaByLocation = mergeFilteredMedia(
          activeFilters,
          "filterByLocation"
        );

        if (!filteredMediaByLocation.length > 0) {
          filteredMediaByLocation = currentState.media;
        }
        activeFilters["filterByLocation"] = [];
      } else {
        filteredMediaByLocation = currentState.media;
        activeFilters["filterByLocation"] = [];
      }

      state.activeFilters = activeFilters;
      state.filteredMedia = filteredMediaByLocation;
    },
    setSearchedTag: (state, action) => {
      const { searched_tag } = action.payload;
      state.searchedTag = searched_tag;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchWithGPT.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(searchWithGPT.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const {
          matchedCategories,
          matchedAccounts,
          matchedIgPosts
        } = action.payload;
        state.matchedAccounts = matchedAccounts;
        state.matchedCategories = matchedCategories;
        state.media = matchedIgPosts;
        console.log(matchedIgPosts.slice(0,5));
        /* .sort(
          //ordenar por fecha
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        ); */
        state.filteredMedia = current(state).media;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(searchWithGPT.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(applyActiveFilters.pending, (state) => {
        //
      })
      .addCase(applyActiveFilters.fulfilled, (state, action) => {
        //
      })
      .addCase(applyActiveFilters.rejected, (state) => {
        //
      });
  },
});

export const select_matched_accounts = (state) => state.igFeed.matchedAccounts;
export const select_accounts_index = (state) => state.igFeed.accounts_index;
export const select_filtered_media = (state) => state.igFeed.filteredMedia;
export const select_active_filters = (state) => state.igFeed.activeFilters;
export const select_reset_filters = (state) => state.igFeed.resetFilters;
export const select_searched_tag = (state) => state.igFeed.searchedTag;
export const select_igFeed_loading = (state) => state.igFeed.isLoading;
export const select_connected_ig = (state) => state.igFeed.connectedIg;
export const select_media = (state) => state.igFeed.media;
export const select_date_filtered_media = (state) =>
  state.igFeed.date_filtered_media;
export const select_tag_filtered_media = (state) =>
  state.igFeed.tag_filtered_media;
export const select_searched_keywords = (state) =>
  state.igFeed.searched_keywords;

export const {
  setFilteredCategoryIds,
  setFilteredAccountIds,
  setFilteredDates,
  setFilteredMedia,
  filterByLocation,
  filterByCategory,
  filterByAccount,
  setSearchedTag,
  indexKeywords,
  filterIgPosts,
  resetFilters,
  filterByDate,
  setPage,
  setMediaEmpty
} = igFeedSlice.actions;
export default igFeedSlice.reducer;
