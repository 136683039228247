import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getAccountsByCategoryName } from "../../features/slices/igAccountsCategoriesSlice";
import SellIcon from "@mui/icons-material/Sell";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { keywords } from "../../dummy";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { getPostsByParentCatId } from "../../features/slices/postsSlice";
import Post from "../../features/media/feedPost/Post";
import { getCategoryByName } from "../../features/slices/categoriesSlice";

const CategoryPage = () => {
  const { categoryName } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { categoryAccounts } = useSelector(
    (state) => state.igAccountsCategories
  );

  const { single_category } = useSelector((state) => state.categories);

  const { posts, isLoading } = useSelector((state) => state.posts);

  useEffect(() => {
    dispatch(getCategoryByName({ categoryName: categoryName }));
    dispatch(
      getAccountsByCategoryName({
        categoryName: categoryName,
        keywords: keywords,
      })
    );
  }, [categoryName]);


 /*  useEffect(() => {
    if (single_category) {
      console.log(single_category.id);
       dispatch(
      getPostsByParentCatId({
        parentId: single_category.id,
        keywords: keywords,
      })
    );
    }    
  }, [single_category]); */

  const handleClick = (account) => {
    navigate(`/business/${account.ig_account_id}`);
  };

  const handleLeftArrowClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    let acum = 0;
    categoryAccounts.length > 0 &&
      categoryAccounts.slice(0, 1).map((account) => {
        acum += parseInt(account.total_posts);
      });
  }, [categoryAccounts]);

  return categoryAccounts.length > 0 ? (
    <div className="w-full flex-center xs:px-0 md:px-20 py-4 bg-[#F8F8F8]">
      <div className="xs:w-full md:w-[80%] flex-center flex-col gap-2">
        {/* Category Name Header */}
        <div className="bg-[#FF9933] w-full relative flex-center flex-col items-baseline py-4 ">
          <span className="absolute left-3" onClick={handleLeftArrowClick}>
            <KeyboardArrowLeftIcon
              style={{ color: "white", fontSize: "30px", cursor: "pointer" }}
            />
          </span>
          <h3 className="text-[15px] text-white font-bold">{categoryName}</h3>
        </div>

        {/* Posts List */}
       {/*  <div className="xs:w-full md:h-[90%] xs:px-1 flex-center flex-col gap-4 md:overflow-y-scroll md:px-64 md:pb-12">
          {posts?.slice(0, 5).map((post, index) => {
            if (post.status !== "deleted") {
              return <Post key={index} index={index} post={post} />;
            }
          })}
          {isLoading && <p>Cargando...</p>}
        </div> */}
        {/* Accounts List */}
        <div className="w-full flex-center flex-wrap gap-4">
          {categoryAccounts?.map((account, index) => (
            <div
              key={index}
              className="xs:w-[40%] md:w-[33%] flex-center flex-col border-b bg-white gap-2 p-2 rounded shadow-sm cursor-pointer"
              onClick={(e) => {
                handleClick(account);
              }}
            >
              <div className="flex items-center gap-2">
                <img
                  className="w-[50px] rounded-full"
                  src={account.profile_picture_url}
                  alt={account.name}
                />
              </div>
              <div className="titulo">
                <span className="font-bold">
                  {account.name
                    ? account.name.length < 30
                      ? account.name
                      : account.name.slice(0, 20)
                    : " "}
                </span>
              </div>
              <div className="location">
                <span>
                  <LocationOnIcon
                    style={{ fontSize: "15px", color: "#B3B3B3" }}
                  />
                </span>
                <span className="text-[#B3B3B3] xs:text-[13px]">
                  {account.location}
                </span>
              </div>
              <div className="promos flex gap-2 text-[13px]">
                <span>
                  {account.total_posts}{" "}
                  {account.total_posts > 1 ? `promos` : `promo`}
                </span>
                <span>
                  <SellIcon style={{ color: "#FF9933" }} />
                </span>
              </div>
              <div className="ubicación"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <div className="flex-center p-4">
      <h3>No hay negocios con promociones activas</h3>
    </div>
  );
};

export default CategoryPage;

/*   */
