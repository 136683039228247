import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// GET FAVORITES BY COLLECTION
export const getFavorites = createAsyncThunk(
  "favorites/getFavorites",
  async (collectionId, thunkAPI) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/api/favorites/find/${collectionId}`
      );
      return response.data;
    } catch (error) {
      console.log(`error en getCollections thunk`, error);
    }
  }
);

// ADD FAVORITE ITEM
export const addFavorite = createAsyncThunk(
  "favorites/addFavorite",
  async (collectionId, thunkAPI) => {
    const favoriteItems = thunkAPI.getState().favoriteItems.favoriteItems;
    try {
      const response = await axios.post(`${SERVER_URL}/api/favorites`, {
        favoriteItemId: favoriteItems[favoriteItems.length - 1].id,
        collectionId: collectionId,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    } 
  }
);

// PUT FAVORITE ITEM
export const updateFavorite = createAsyncThunk(
  "favorites/updateFavorite",
  async ({ itemId, collectionId }, thunkAPI) => {
    try {
      const response = await axios.put(
        `${SERVER_URL}/api/favorites/move/favorite_item/${itemId}/collection/${collectionId}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

//DELETE FAVORITE ITEM
export const deleteFavorite = createAsyncThunk(
  "favorites/deleteFavorite",
  async ({ itemId, collectionId }, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/api/favorites/delete/favorite_item/${itemId}/collection/${collectionId}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const favoritesSlice = createSlice({
  name: "favorites",
  initialState: {
    favorites: [],
    isLoading: false,
    hasError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //GET
      .addCase(getFavorites.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getFavorites.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.favorites = action.payload;
      })
      .addCase(getFavorites.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      //ADD
      .addCase(addFavorite.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(addFavorite.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.favorites.push(action.payload);
      })
      .addCase(addFavorite.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      //UPDATE
      .addCase(updateFavorite.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(updateFavorite.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const index = current(state).favorites.findIndex(
          (fav) => fav.id === action.payload.id
        );
        if (index !== -1) {
          state.favorites[index] = action.payload;
        }
      })
      .addCase(updateFavorite.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      //DELETE
      .addCase(deleteFavorite.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(deleteFavorite.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        console.log('action.payload', action.payload);
        console.log('state favorites', current(state).favorites);
        state.favorites = current(state).favorites.filter(
          (favorite) => favorite.item_id !== action.payload.favorite_item_id
        );
      })
      .addCase(deleteFavorite.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      });
  },
});

export default favoritesSlice.reducer;
