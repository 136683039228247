import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Favorites from "../../features/favorites/Favorites";
import {
  deleteCollection,
  getCollections,
} from "../../features/slices/collectionsSlice";
import { AuthContext } from "../../App";
import styled from "styled-components";

const Container = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: 1px solid #e5e5e5;
`;

const Top = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  border-bottom: 1px solid #ff9933;
`;

const Bottom = styled.div`
  width: 100%;
  height: 100vh;
`;

const PageTitle = styled.h1`
  font-size: 30px;
`;

const CollectionSelect = styled.select`
  margin-left: 20px;
  padding: 5px 10px;
  margin-right: 20px;
  cursor: pointer;
`;

const Option = styled.option`
  padding: 10px;
  border-bottom: 1px solid #66cc33;
`;

const DeleteButton = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #ff9933;
  color: #000066;
  font-weight: bold;

  &:hover {
    background-color: #0066cc;
    color: white;
  }
`;

const NoFavoritesMessage = styled.h1`
  font-size: 30px;
`

const FavoritePage = () => {
  const { user } = React.useContext(AuthContext);
  console.log('favorite page user', user);
  const dispatch = useDispatch();
  const favCollections = useSelector((state) => state.collections.collections);
  const [collectionId, setCollectionId] = useState();

  useEffect(() => {
    dispatch(getCollections(user.id));
  }, []);

  useEffect(() => {
    setCollectionId(favCollections[0]?.id);
  }, [favCollections]);

  const handleSelect = (e) => {
    const collectionId = e.target.value;
    setCollectionId(collectionId);
  };

  const handleDelete = () => {
    dispatch(deleteCollection(collectionId));
  };

  return (
    <Container>
      <Wrapper>
        <Top>
          <PageTitle>Favoritos</PageTitle>
          <CollectionSelect name="favorites" onChange={handleSelect}>
            <Option disabled>Lista de Favoritos</Option>
            {favCollections?.map((collection) => (
              <Option key={collection.id} value={collection.id}>
                {collection.collection_name}
              </Option>
            ))}
          </CollectionSelect>
          <DeleteButton onClick={handleDelete}>Eliminar</DeleteButton>
        </Top>
        <Bottom>
          {favCollections.length > 0 ? (
            <Favorites collectionId={collectionId} />
          ) : (
            <Wrapper>
              <NoFavoritesMessage>
                No existe ninguna lista de favoritos
              </NoFavoritesMessage>
            </Wrapper>
          )}
        </Bottom>
      </Wrapper>
    </Container>
  );
};

export default FavoritePage;
