import { useNavigate } from "react-router-dom";
import {
  parentCategories,
  restaurantDeals,
  supermarketDeals,
  techDeals,
} from "../../dummy";
import FeaturedDeals from "../../features/featuredDeals/FeaturedDeals";
import ShortDealsCarrousel from "../../features/shortDealsCarrousel/ShortDealsCarrousel";
/* Slider */
import Slider from "react-slick";
import CategoriesPage from "../CategoriesPage/CategoriesPage";

const HomePage = (props) => {
  const navigate = useNavigate();

  const handleCategoryClick = (parentCategory) => {
    if (
      parentCategory.name === "Restaurantes" ||
      parentCategory.name === "Moda" ||
      parentCategory.name === "Supermercados" ||
      parentCategory.name === "Vehículos"
    ) {
      ////navegar hacia el listado de sub categorias
      navigate(`/categories/id/${parentCategory.id}`);
    } else {
      //navegar directamente a las cuentas de la categoria
      navigate(`/categories/${parentCategory.name}`);
    }
  };

  const handlePromoClick = (businessId) => {
    navigate(`/business/${businessId}`);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full h-[100%] flex flex-col gap-5 bg-[#F8F8F8] xs:px-0 md:px-20 py-4">
      {/* Título */}
      <div className="text-center py-4 px-3">
        <h1 className="text-2xl font-bold text-[#333]">
          Todas las ofertas y descuentos, ahora en un solo lugar
        </h1>
        <p className="text-lg text-[#666] mt-2">
          Ahorra tiempo y dinero sin dejar pasar ninguna oferta, Kontevo las
          encuentra todas para ti
        </p>

        {/* categorias */}
      </div>
      {/*Promos Destacadas General*/}
      {/* <Slider {...settings} className="!flex-center bg-[#000066] p-4">
        {promoPosts?.map((promo, index) => (
          <div
            key={index}
            className="flex-center flex-col max-w-[80%] gap-2 cursor-pointer"
            onClick={(e) => {
              handlePromoClick(promo.business_id);
            }}
          >
            <div className="mb-2">
              <img
                className="object-cover rounded-md"
                src={promo.media_url}
                alt={promo.title}
              />
            </div>
            <div className="">
              <span className="text-white text-[13px] font-bold">
                {promo.business}
              </span>
            </div>
            <div className="">
              <span className="text-white text-[14px] leading-tight">
                {promo.title}
              </span>
            </div>
          </div>
        ))}
      </Slider> */}
      {/* <ShortDealsCarrousel postsIds={supermarketDeals} dealsTitle="Alimentos" />
      <ShortDealsCarrousel postsIds={techDeals} dealsTitle="Tecnología" />
      <ShortDealsCarrousel
        postsIds={restaurantDeals}
        dealsTitle="Restaurantes"
      /> */}
      <CategoriesPage />
    </div>
  );
};

export default HomePage;
