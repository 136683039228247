import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate, useParams } from "react-router-dom";
import { getCategoriesByParentId } from "../../features/slices/categoriesSlice";
import { parentCategories, keywords } from "../../dummy";

const SubCategoriesPage = ({ title, subtitle }) => {
  const { parentId } = useParams();
  const { categories } = useSelector((state) => state.categories);
  const [category, setCategory] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    parentCategories.forEach((parentCat) => {
      if (parentCat.id === parseInt(parentId)) {
        setCategory(parentCat);
      }
    });
    dispatch(
      getCategoriesByParentId({ parentId: parentId, keywords: keywords })
    );
  }, [parentId]);

  const handleClick = (category) => {
    navigate(`/categories/${category.name}`);
  };

  const handleLeftArrowClick = () => {
    navigate(-1);
  };

  return (
    <div className="w-full h-screen bg-[#F8F8F8] xs:px-0 md:px-20 py-4 overflow-hidden">
      <div className="bg-[#FF9933] relative flex-center flex-col items-baseline py-4 ">
        <span className="absolute left-3" onClick={handleLeftArrowClick}>
          <KeyboardArrowLeftIcon
            style={{ color: "white", fontSize: "30px", cursor: "pointer" }}
          />
        </span>
        <h3 className="text-[15px] text-white font-bold">{category.name}</h3>
        {subtitle && (
          <p className="text-white text-[13px]">{category.subtitle}</p>
        )}
      </div>
      <div className="w-full h-screen overflow-y-auto">
        {categories?.map((cat, index) => (
          <li
            className="custom-li flex-between"
            key={index}
            onClick={(e) => {
              handleClick(cat);
            }}
          >
            <div className="flex items-center gap-2">
              <span>
                <img width={30} src={cat.image} alt="" />
              </span>
              <span className="hover:underline">{cat.name}</span>
            </div>
            <div className="">
              <KeyboardArrowRightIcon
                style={{ color: "B3B3B3", cursor: "pointer" }}
              />
            </div>
          </li>
        ))}
      </div>
    </div>
  );
};

export default SubCategoriesPage;
