/* React */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
/* Redux Slice */
import {
  applyActiveFilters
} from "../../postTracker/igFeedSlice";

const CategoryFilter = ({
  showFilter,
  setShowFilter,
  activeFilters,
  setActiveFilters,
}) => {
  const dispatch = useDispatch();
  //cuentas encontradas con la búsqueda
  //Id's de categorias previamente marcados
  const { matchedCategories, filteredCategoryIds } = useSelector(
    (state) => state.igFeed
  );

  // Valores maracados
  const [checked, setChecked] = useState([]);

  //Inicializar valores marcados
  useEffect(() => {
    setChecked(filteredCategoryIds);
  }, [filteredCategoryIds]);

  //Cada vez que se actualizen las casillas marcadas, se actualizan los filtros activos.

  useEffect(() => {
    const igCategoriesIds = checked.map((id) => parseInt(id));
    setActiveFilters((prevState) => {
      // Combina el estado anterior con los nuevos filtros
      return { ...prevState, ...{ filteredCategoryIds: igCategoriesIds } };
    });
  }, [checked]);

  //Gestionar las casillas marcadas para actualizar el state
  const handleCheckBoxClick = (e) => {
    const checkedValue = e.target.value;
    const isChecked = e.target.checked;
    setChecked((prevValues) => {
      if (isChecked) {
        return [...prevValues, parseInt(checkedValue)];
      } else {
        return [...prevValues].filter(
          (option) => option !== parseInt(checkedValue)
        );
      }
    });
  };

  //Actualizar State para filtrar las publicaciones por las categorias seleccionadas
  const filterMedia = async () => {
    //convertir Ids en números y no en strings
    const igCategoriesIds = checked.map((id) => parseInt(id));
    await dispatch(
      applyActiveFilters({ filteredCategoryIds: igCategoriesIds })
    );
    //dispatch(filterByCategory(igCategoriesIds));
  };

  return (
    <div className="flex flex-col px-2 md:gap-2 xs:gap-4 overflow-auto md:pb-4 xs:pb-32">
      {matchedCategories?.map((category, index) => {
        return (
          <div key={index} className="flex items-center gap-2">
            <input
              className="w-auto cursor-pointer"
              type="checkbox"
              value={category.id}
              checked={checked?.some((checkedValue) => {
                //console.log('inside input',checkedValue);
                if (parseInt(checkedValue) === category.id) {
                  return true;
                } else {
                  return false;
                }
              })}
              onChange={(e) => handleCheckBoxClick(e)}
            />
            <label>
              {category.name.charAt(0).toUpperCase() +
                category.name.slice(1).toLowerCase()}
            </label>
          </div>
        );
      })}
      {/* BOTÓN PARA APLICAR FILTRO */}
      <div
        className={`xs:z-4 xs:w-full xs:flex xs:justify-center xs:items-center xs:border-x-0 xs:border-t-[1px] xs:font-bold xs:transition-height xs:duration-300 xs:ease-in-out xs:p-6 bg-opacity-95 bg-white ${
          checked !== filteredCategoryIds ? `xs:fixed bottom-0` : `xs:hidden`
        } md:hidden`}
      >
        <button
          className="bg-[#FF9933] hover:bg-[#FFB366] hover:text-white
 py-2.5 px-11 rounded-md"
          onClick={() => {
            setShowFilter(!showFilter);
            filterMedia();
          }}
        >
          Aplicar
        </button>
      </div>
    </div>
  );
};

export default CategoryFilter;
