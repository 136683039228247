import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Logo from "../../assets/kontevo-logo-nuevo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { setMediaEmpty } from "../postTracker/igFeedSlice";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { parentCategories } from "../../dummy";
import { emptyCategoryAccounts } from "../slices/igAccountsCategoriesSlice";

/* Componente Padre del header */
const MainMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(null); // Estado para la categoría seleccionada

  const handleLogoClick = () => {
    dispatch(setMediaEmpty());
    setSelectedCategory(null);
    navigate("/");
  };

  const handleCategoryClick = async (parentCategory) => {
    setSelectedCategory(parentCategory.name); // Actualiza la categoría seleccionada
    dispatch(emptyCategoryAccounts());
    if (
      parentCategory.name === "Restaurantes" ||
      parentCategory.name === "Moda" ||
      parentCategory.name === "Supermercados" ||
      parentCategory.name === "Vehículos"
    ) {
      navigate(`/categories/id/${parentCategory.id}`);
    } else {
      navigate(`/categories/${parentCategory.name}`);
    }
  };

  return (
    <div className="relative w-full flex-center  items-baseline xs:gap-4 xs:flex-col xs:pt-3 md:py-4 xs:px-2 md:px-8 bg-white shadow">
      {/* Upper Section */}
      <div className="">
        {/* Logo Container */}
        <div className="w-full flex-center">
          <img
            src={Logo}
            alt="Logo de kontevo"
            className="w-[150px] h-auto cursor-pointer"
            onClick={handleLogoClick}
          />
        </div>
        {/* Account Icon Container*/}
        <div
          className="absolute left-4 top-5 flex justify-center cursor-pointer"
          onClick={() => {
            navigate("/account");
          }}
        >
          <span className="xs:hidden md:flex">
            <AccountCircleIcon style={{ fontSize: "32px", color: "#000066" }} />
          </span>
        </div>
      </div>
      {/* Bottom Section */}
      <div className="w-full overflow-x-auto whitespace-nowrap scrollbar-hide">
        <nav className="xs:flex md:flex md:justify-center gap-4">
          {parentCategories?.map((parentCategory, index) => (
            <div
              key={index}
              className={`cursor-pointer ${
                selectedCategory === parentCategory.name
                  ? "border-b-4 border-[#66CC33]"
                  : ""
              }`} // Agrega la clase condicionalmente
              onClick={() => {
                handleCategoryClick(parentCategory);
              }}
            >
              <span className="xs:text-[13px] font-bold">
                {parentCategory.name}
              </span>
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};


export default MainMenu;