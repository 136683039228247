import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";

export const getInstagramChats = createAsyncThunk(
  "chats/getInstagramChats",
  async ({ facebook_page }, thunkAPI) => {
    try {
      const response = await axios.get(
        `https://graph.facebook.com/v15.0/${facebook_page.id}/conversations?fields=participants,messages.limit(1){message,from, created_time}&platform=instagram&limit=2&access_token=${facebook_page.page_token}`
      );
      const chats = response.data.data;
      return { chats, facebook_page };
    } catch (error) {
      return error;
    }
  }
);

export const getFacebookChats = createAsyncThunk(
  "chats/getFacebookChats",
  async ({ facebook_page }, thunkAPI) => {
    try {
      const response = await axios.get(
        `https://graph.facebook.com/v15.0/${facebook_page.id}/conversations?fields=participants,messages.limit(1){message,from, created_time}&platform=messenger&access_token=${facebook_page.page_token}`
      );
      const chats = response.data.data;
      return { chats, facebook_page };
    } catch (error) {
      return error;
    }
  }
);


export const getAllIgMessages = createAsyncThunk(
  "chats/getAllIgMessages",
  async ({ facebook_page }, thunkAPI) => {
    try {
      const response = await axios.get(
        `https://graph.facebook.com/v15.0/${facebook_page.id}/conversations?fields=messages{message,from,to, created_time}&platform=instagram&limit=2&access_token=${facebook_page.page_token}`
      );
      const chats = response.data.data;
      return chats;
    } catch (error) {
      return error;
    }
  }
);

 
export const getAllFbMessages = createAsyncThunk(
  "chats/getAllFbMessages",
  async ({ facebook_page }, thunkAPI) => {
    try {
      const response = await axios.get(
        `https://graph.facebook.com/v15.0/${facebook_page.id}/conversations?fields=messages{message,from,to, created_time}&platform=messenger&access_token=${facebook_page.page_token}`
      );
      const chats = response.data.data;
      return chats;
    } catch (error) {
      return error;
    }
  }
);

export const getChatMessages = createAsyncThunk(
  "chats/getChatMessages",
  async ({ chat }, thunkAPI) => {
    try {
      const response = await axios.get(
        `https://graph.facebook.com/v15.0/${chat.id}?fields=messages{message,from,to,created_time}&access_token=${chat.page_token}`
      );
      const messages = response.data.messages.data;
      return { messages, chat };
    } catch (error) {
      return error;
    }
  }
);

export const getProfilePic = createAsyncThunk(
  "chats/getProfilePic",
  async ({ client_id, page_token }, thunkAPI) => {
    try {
      const response = await axios.get(
        `https://graph.facebook.com/v15.0/${client_id}? fields=profile_pic&access_token=${page_token}`
      );
      const profile_picture = response.data.profile_pic;
      return profile_picture;
    } catch (error) {
      console.log("Error al obtener la foto de perfil del usuario", error);
    }
  }
);

const chatsSlice = createSlice({
  name: "chats",
  initialState: {
    chats: [],
    single_chat_messages: [],
    all_ig_messages: [],
    filtered_messages: {},
    activeAccount: "",
    business_account: {},
    page_token: "",
    platform: "",
    profile_picture_url: "",
    isLoading: false,
    hasError: false,
  },
  reducers: {
    resetFilteredMessages: (state, action) => {
      state.filtered_messages = {}
    },
    setActiveAccount: (state, action) => {
      const facebook_page = action.payload;
      state.activeAccount = facebook_page;
      console.log("Active Facebook Page: ", state.activeAccount);
    },
    filterIgMessages: (state, action) => {
      const { keywords, business_account } = action.payload;
      const messages = current(state).all_ig_messages;
      console.log('business_account messages', messages);
      keywords.forEach((currentKeyword) => {
        let foundMessages = messages.filter((currentMessage) => {
          if (currentMessage.from.username === business_account.username) {
            if (
              currentMessage.content
                .toLowerCase()
                .includes(currentKeyword.toLowerCase())
            ) {
              return currentMessage;
            }
          }
        });

        state.filtered_messages[currentKeyword] = foundMessages; 
        console.log('state.filtered_messages', current(state).filtered_messages);  
      });  
    },
    setPlatform: (state, action) => {
      const platform = action.payload;
      state.platform = platform;
      console.log('Set Platform Succesfully:', platform);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstagramChats.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        console.log("get chats pending");
      })
      .addCase(getInstagramChats.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.chats = [];
        const { chats, facebook_page } = action.payload;
        state.page_token = facebook_page.page_token;
        state.activeAccount = facebook_page;
        state.business_account = {
          username: chats[0].participants.data[0].username,
          id: chats[0].participants.data[0].id,
        };
        chats.forEach((chat) => {
          state.chats.push({
            id: chat.id,
            page_token: facebook_page.page_token,
            participants: {
              business_account: {
                id: chat.participants.data[0].id,
                username: chat.participants.data[0].username,
              },
              client_account: {
                id: chat.participants.data[1].id,
                username: chat.participants.data[1].username,
              },
            },
            last_message: {
              message: chat.messages.data[0].message,
              from: chat.messages.data[0].from,
              created_time: chat.messages.data[0].created_time,
            },
          });
        });
        console.log("get chats fulfilled:", state.chats);
      })
      .addCase(getInstagramChats.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        console.log("Failed to get chats");
      })
      .addCase(getChatMessages.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        console.log("get messages pending");
      })
      .addCase(getChatMessages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.single_chat_messages = [];
        const { messages, chat } = action.payload;
        messages.forEach((message) => {
          state.single_chat_messages.push({
            id: message.id,
            content: message.message,
            created_time: message.created_time,
            participants: {
              business_account: chat.participants.business_account,
              client_account: chat.participants.client_account,
            },
            from: {
              username:
                current(state).platform === "instagram"
                  ? message.from.username
                  : message.from.name,
              id: message.from.id,
            },
            to: {
              username:
                current(state).platform === "instagram"
                  ? message.to.data[0].username
                  : message.to.data[0].name,
              id: message.to.data[0].id,
            },
          });
        });
        state.single_chat_messages = state.single_chat_messages.reverse();
        console.log("get messages fulfilled", state.single_chat_messages);
      })
      .addCase(getChatMessages.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        console.log("get messages rejected");
      })
      .addCase(getProfilePic.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        console.log("get profile pic pending");
      })
      .addCase(getProfilePic.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.profile_picture_url = action.payload;
        console.log("get profile pic fulfilled", state.profile_picture_url);
      })
      .addCase(getProfilePic.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        console.log("get profile pic rejected");
      })
      .addCase(getAllIgMessages.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        console.log("get all instagram messages pending");
      })
      .addCase(getAllIgMessages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.all_ig_messages = [];
        const chats = action.payload;
        chats.forEach((chat) => {
          chat.messages.data.forEach((message) => {
            state.all_ig_messages.push({
              conversation_id: chat.id,
              created_time: message.created_time,
              content: message.message,
              from: {
                username: message.from.username,
                id: message.from.id,
              },
              to: {
                username: message.to.data[0].username,
                id: message.to.data[0].id,
              },
            });
          });
        });

        console.log(
          "get all instagram messages fulfilled:",
          current(state).all_ig_messages
        );
      })
      .addCase(getAllIgMessages.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        console.log("get all instagram messages rejected");
      })
      .addCase(getAllFbMessages.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        console.log("get all facebook messages pending");
      })
      .addCase(getAllFbMessages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.all_ig_messages = [];
        const chats = action.payload;
        chats.forEach((chat) => {
          chat.messages.data.forEach((message) => {
            state.all_ig_messages.push({
              conversation_id: chat.id,
              created_time: message.created_time,
              content: message.message,
              from: {
                username: message.from.name,
                id: message.from.id,
              },
              to: {
                username: message.to.data[0].name,
                id: message.to.data[0].id,
              },
            });
          });
        });

        console.log(
          "get all facebook messages fulfilled:",
          current(state).all_ig_messages
        );
      })
      .addCase(getAllFbMessages.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        console.log("get all facebook messages rejected");
      })
      .addCase(getFacebookChats.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        console.log("get facebook chats pending");
      })
      .addCase(getFacebookChats.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.chats = [];
        const { chats, facebook_page } = action.payload;
        state.page_token = facebook_page.page_token;
        state.activeAccount = facebook_page;
        state.business_account = {
          username: chats[0].participants.data[1].name,
          email: chats[0].participants.data[1].email,
          id: chats[0].participants.data[1].id,
        };
        chats.forEach((chat) => {
          state.chats.push({
            id: chat.id,
            page_token: facebook_page.page_token,
            participants: {
              business_account: {
                id: chat.participants.data[1].id,
                username: chat.participants.data[1].name,
              },
              client_account: {
                id: chat.participants.data[0].id,
                username: chat.participants.data[0].name,
              },
            },
            last_message: {
              message: chat.messages.data[0].message,
              from: chat.messages.data[0].from,
              created_time: chat.messages.data[0].created_time,
            },
          });
        });
        console.log("get facebook chats fulfilled:", state.chats);
      })
      .addCase(getFacebookChats.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        console.log("Failed to get facebook chats");
      });
  },
});


export const select_all_ig_messages = (state) => state.chats.all_ig_messages;
export const select_filtered_messages = (state) => state.chats.filtered_messages;
export const select_business_account = (state) => state.chats.business_account;
export const select_active_account = (state) => state.chats.activeAccount;
export const select_profile_picture_url = (state) =>
  state.chats.profile_picture_url;
export const select_page_token = (state) => state.chats.page_token;
export const select_chats = (state) => state.chats.chats;
export const select_single_chat_messages = (state) =>
  state.chats.single_chat_messages;

export const { setPlatform, setActiveAccount, filterIgMessages, resetFilteredMessages } = chatsSlice.actions;
export default chatsSlice.reducer;
