import React from "react";

const PrivacyPolicyPage = () => {
  return (
    <div>
      <h1>Política de privacidad</h1>
      <p>
        En Kontevo, nos tomamos muy en serio la privacidad y
        seguridad de tus datos personales. Esta Política de privacidad describe
        cómo recopilamos, utilizamos, compartimos y protegemos la información
        que nos proporcionas cuando utilizas nuestra aplicación.
      </p>
      <h2>Recopilación de información:</h2>
      <p>
        - Cuando creas una cuenta en nuestra aplicación, solicitamos ciertos
        datos personales, como tu nombre, dirección de correo electrónico, etc.
      </p>
      <p>
        - Además, podemos recopilar información de forma automática, como tu
        dirección IP, tipo de dispositivo, sistema operativo, etc., para mejorar
        tu experiencia de usuario.
      </p>
      <h2>Uso de la información:</h2>
      <p>
        - Utilizamos la información que recopilamos para proporcionarte los
        servicios y funcionalidades de nuestra aplicación.
      </p>
      <p>
        - También podemos utilizar tus datos para personalizar tu experiencia,
        enviarte notificaciones relevantes y mejorar nuestros servicios.
      </p>
      <h2>Compartir de información:</h2>
      <p>
        - No compartiremos tus datos personales con terceros sin tu
        consentimiento, excepto en los casos requeridos por ley o cuando sea
        necesario para brindarte nuestros servicios.
      </p>
      <h2>Protección de la información:</h2>
      <p>
        - Tomamos medidas de seguridad adecuadas para proteger tus datos
        personales contra accesos no autorizados, pérdidas o alteraciones.
      </p>
      <p>
        - Mantenemos tus datos personales almacenados de forma segura y solo los
        conservamos durante el tiempo necesario para cumplir con los fines
        mencionados en esta Política de privacidad.
      </p>
      <p>
        Para obtener más información sobre cómo manejamos tus datos personales y
        ejercer tus derechos de privacidad, consulta nuestra Política de
        privacidad completa en [enlace a tu Política de privacidad].
      </p>
      <p>
        Al utilizar nuestra aplicación, aceptas los términos descritos en esta
        Política de privacidad. Si tienes alguna pregunta o inquietud, no dudes
        en contactarnos a través de [información de contacto].
      </p>
      <p>
        Última actualización: [fecha de la última actualización de la Política
        de privacidad]
      </p>
    </div>
  );
};

export default PrivacyPolicyPage;
