import React, { useEffect, useState } from "react";
import "./single.scss";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

const Single = ({
  items,
  bigItem,
  slug,
  updateItem,
  isLoading,
  refreshItem,
}) => {
  const [inputValues, setInputValues] = useState({});
  const [textAreaValues, setTextAreaValues] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [checkedValues, setCheckedValues] = useState([]);
  const [textValue, setTextValue] = useState("");

  /* En este useEffect inicializo mis estados selectedValues y checkedValues */
  useEffect(() => {
    const initialCheckedValues = items.find((item) => {
      if (item.type === "checkbox") {
        return item.selected;
      }
    });

    if (initialCheckedValues) {
      setCheckedValues(
        initialCheckedValues.selected.map((current) => current.value.toString())
      );
    }

    const initialSelectedValues = items.find((item) => {
      if (item.type === "select") {
        return item.selected;
      }
    });

    if (initialSelectedValues) {
      setSelectedValues(
        initialSelectedValues.selected.map((current) => {
          return current.value.toString();
        })
      );
    }

    const initialTextValue = items.find((item) => {
      if (item.type === "text") {
        return item;
      }
    });

    if (Object.keys(initialTextValue).length > 0) {
      setTextValue(initialTextValue.value);
    }

    items.forEach((item) => {
      if (item.type !== "text") {
        setInputValues((prevValues) => {
          return {
            ...prevValues,
            [item.field]: item.selected.map((current) =>
              current.value.toString()
            ),
          };
        });
      } else {
        setInputValues((prevValues) => {
          return {
            ...prevValues,
            [item.field]: item.value,
          };
        });
      }
    });
  }, [items]);

  useEffect(() => {
    if (bigItem) {
      setTextAreaValues(bigItem);
    }
  }, [bigItem]);

  const handleSelectChange = (e, field) => {
    const selectedOption = e.target.value;
    setInputValues((prevValues) => {
      if (prevValues.hasOwnProperty(field)) {
        if (selectedValues.includes(selectedOption)) {
          return {
            ...prevValues,
            [field]: prevValues[field].filter(
              (option) => option !== selectedOption
            ),
          };
        } else {
          return {
            ...prevValues,
            [field]: [...prevValues[field], selectedOption],
          };
        }
      } else {
        return {
          ...prevValues,
          [field]: [selectedOption],
        };
      }
    });

    setSelectedValues((prevValues) => {
      if (selectedValues.includes(selectedOption)) {
        return [...prevValues].filter((option) => option !== selectedOption);
      } else {
        return [...prevValues, selectedOption];
      }
    });
  };

  const handleCheckboxChange = (e, field) => {
    const checkedValue = e.target.value;
    const isChecked = e.target.checked;
    console.log("isChecked", isChecked);
    setInputValues((prevValues) => {
      if (isChecked) {
        if (prevValues.hasOwnProperty(field)) {
          return {
            ...prevValues,
            [field]: [...prevValues[field], checkedValue],
          };
        } else {
          return {
            ...prevValues,
            [field]: [checkedValue],
          };
        }
      } else {
        if (prevValues.hasOwnProperty(field)) {
          return {
            ...prevValues,
            [field]: prevValues[field].filter((id) => id !== checkedValue),
          };
        } else {
          return {
            ...prevValues,
            [field]: [],
          };
        }
      }
    });

    setCheckedValues((prevValues) => {
      if (isChecked) {
        return [...prevValues, checkedValue];
      } else {
        return [...prevValues].filter((option) => option !== checkedValue);
      }
    });
  };

  const handleTextChange = (e, key) => {
    const newValue = e.target.value;
    setInputValues((prevValues) => ({
      ...prevValues,
      [key]: newValue,
    }));
    setTextValue(newValue);
  };

  const handleUpdate = () => {
    updateItem(inputValues);
  };

  const handleRefresh = (key) => {
    refreshItem(key);
  };

  return (
    <div className="w-full flex justify-center">
      <div className="w-full px-10 flex flex-col items-center">
        <div className="flex items-center justify-center gap-3">
          <h1 className="text-[#66CC33] font-[500]">Actualizar {slug}</h1>
          <button
            className="p-[5px] cursor-pointer bg-[#FF9933] border border-solid border-transparent rounded-md font-bold text-[#000066] text-sm"
            onClick={handleUpdate}
          >
            {isLoading ? <Spinner animation="border" size="sm" /> : "Update"}
          </button>
        </div>
        <div className="w-full flex justify-between flex-wrap text-lg ">
          {!items.length > 0 ? (
            <Spinner animation="border" size="sm" />
          ) : (
            items.map((item, key) => {
              return (
                <div
                  className="flex flex-col gap-[10px] my-[30px] mx-[0]"
                  key={key}
                >
                  <label className="font-bold mr-2.5 capitalize">
                    {item.label}
                  </label>
                  {item.type === "text" ? (
                    <input
                      className="p-2.5 bg-white text-[#000066] outline-none border border-solid border-[#66CC33] rounded-md"
                      type={item.type}
                      placeholder={item.field}
                      value={textValue}
                      onChange={(e) => handleTextChange(e, item.field)}
                    />
                  ) : item.type === "select" ? (
                    <select
                      className="w-[300px] h-[300px] pt-2.5 bg-white text-[#000066] outline-none border border-solid border-[#66CC33] rounded-md"
                      name={item.selectName}
                      id={item.selectName}
                      onChange={(e) => handleSelectChange(e, item.field)}
                      multiple
                      value={selectedValues}
                    >
                      {item.options.map((option, key) => {
                        return (
                          <option
                            className="minx-h-[300px] p-2.5 border-b border-solid border-[#66CC33]"
                            key={key}
                            value={option.value}
                          >
                            {option.content}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    /*CHECKBOX  */
                    <div className="w-[300px] h-[300px] p-2.5 bg-white overflow-auto text-[#000066] outline-none border border-solid border-[#66CC33] rounded-md">
                      {item.options.map((option, key) => {
                        return (
                          <div
                            key={key}
                            className="flex gap-2.5 items-baseline"
                          >
                            <input
                              className="w-auto"
                              type="checkbox"
                              id={option.value}
                              value={option.value}
                              checked={
                                checkedValues.length > 0
                                  ? checkedValues.some((checkedValue) => {
                                      if (
                                        parseInt(checkedValue) === option.value
                                      ) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    })
                                  : false
                              }
                              onChange={(e) =>
                                handleCheckboxChange(e, item.field)
                              }
                            />
                            <label className="label" key={key}>
                              {option.content}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })
          )}
        </div>
        {Object.keys(textAreaValues).length > 0 && (
          <div className="w-full flex justify-between flex-wrap gap-5">
            {Object.keys(bigItem).map((key, index) => {
              return (
                <div className="flex flex-col items-center" key={index}>
                  <div className="flex gap-2.5">
                    <span className="font-bold mb-2.5 capitalize">{key}</span>
                    <button
                      className="bg-transparent text-[#FF9933] hover:text-[#FF9933]"
                      onClick={() => {
                        handleRefresh(key);
                      }}
                    >
                      <FontAwesomeIcon icon={faArrowsRotate} />
                    </button>
                  </div>
                  {isLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <textarea
                      disabled
                      className="bigitem-value"
                      name="bigItem"
                      id="bigItem"
                      cols="50"
                      rows="10"
                      value={bigItem[key]}
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Single;
