import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { resetUserPassword } from "../../features/users/usersSlice";

const ResetPasswordPage = () => {
  const [isPassword, setIsPassword] = useState(true);
  const [isConfirmPassword, setIsConfirmPassword] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [validateConfirmMssg, setValidateConfirmMssg] = useState("");
  const { resetPasswordMssg, isLoading } = useSelector(state => state.users);

  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    if (resetPasswordMssg === "contraseña reestablecida exitosamente") {
      navigate('/');
    }
  }, [resetPasswordMssg]);

  //validar que el campo contraseña no este vacío
  const validatePassword = () => {
    if (password) {
      setIsPassword(true);
    } else {
      setIsPassword(false);
    }
  };

  //validar que el campo confirmar contraseña no este vacío
  const validateConfirmPassword = () => {
    if (confirmPassword) {
      if (confirmPassword !== password) {
        setIsConfirmPassword(false);
        setValidateConfirmMssg("Las contraseñas no coinciden");
        setPasswordsMatch(false);
      } else {
        setIsConfirmPassword(true);
        setValidateConfirmMssg("");
        setPasswordsMatch(true);
      }
    } else {
      setIsConfirmPassword(false);
      setPasswordsMatch(false);
      setValidateConfirmMssg("Escribe la misma contraseña que pusiste arriba");
    }
  };

  const saveNewPassword = (e) => {
    e.preventDefault();
    dispatch(resetUserPassword({ token, password }));
  };

  return (
    <div className="w-full min-h-screen flex justify-center items-center">
      <div className="w-full md:w-5/12 p-8 flex flex-col">
        <form className="w-full flex flex-col gap-4">
          {/* Header - Resetear contraseña */}
          <div className="flex-center flex-col gap-2">
            <h1 className="text-[24px] font-bold">Crear Contraseña</h1>
            <p className="text-center text-[14px]">
              Crea una nueva contraseña que tenga mínimo seis caracteres, con
              una combinación de letras y números.
            </p>
          </div>
          {/* Container - ingresar nueva contraseña */}
          <div className="w-full flex flex-col">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Nueva contraseña
            </label>
            <input
              id="password"
              className="w-full form-input mb-0 p-2 border rounded"
              type="password"
              placeholder="Contraseña"
              onChange={(e) => setPassword(e.target.value)}
              onBlur={validatePassword}
            />
            {!isPassword && (
              <span className="text-red-500 text-sm mt-1">
                Utiliza como mínimo 6 caracteres, combinando letras (Al menos
                una mayúscula y una minúscula) y números.
              </span>
            )}
          </div>
          {/* Container - confirmar contraseña */}
          <div className="flex flex-col">
            <label
              htmlFor="confirm-password"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Confirmar contraseña
            </label>
            <input
              id="confirm-password"
              className="w-full  form-input mb-0 p-2 border rounded"
              type="password"
              placeholder="Confirme su contraseña"
              onChange={(e) => setConfirmPassword(e.target.value)}
              onBlur={validateConfirmPassword}
            />
            {!isConfirmPassword && (
              <span className="text-red-500 text-sm mt-1">
                {validateConfirmMssg}
              </span>
            )}
          </div>
          {resetPasswordMssg && (
            <span className="text-[14px] text-[#66CC33] font-bold -mb-2">
              {resetPasswordMssg}
            </span>
          )}
          <button
            className="py-3 bg-[#66CC33] text-white rounded hover:bg-[#FF9933]"
            type="button"
            disabled={!passwordsMatch}
            onClick={(e) => saveNewPassword(e)}
          >
            {isLoading ? <Spinner animation="border" size="sm" /> : "Guardar"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
