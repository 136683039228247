import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { refreshMultipleAccounts } from "../slices/instagamAccountsSlice";
import "./updates.scss";

const Updates = ({ options, updateItem, slug, subSlug }) => {
  const [selectedValue, setSelectedValue] = useState(options[0] || {});
  const dispatch = useDispatch();

  const handleSelectChange = (event) => {
    const selectedOption = options.find(
      (option) => option.category_id === parseInt(event.target.value)
    );
    setSelectedValue(selectedOption);
  };

  const handleUpdate = async (e) => {
    updateItem(selectedValue);
  };

  return (
    <div className="flex flex-col gap-2.5">
      <div className="mt-2.5">
        <h3>{`Actualizar todos los ${subSlug}`}</h3>
      </div>
      <div className="flex gap-2">
        <select
          name="updates"
          id="updates"
          className="py-1.5 px-4 border border-solid border-[#ccc] rounded-md !cursor-pointer outline-none text-base focus:text-[#555] hover:border-[#aaa] !text-[#333]"
          onChange={handleSelectChange}
          defaultChecked=""
        >
          <option
            className="!text-[#333]"
            value=""
          >{`Selecciona una ${slug}`}</option>
          {options.map((option, key) => {
            return (
              <option value={option.category_id} key={key}>
                {option.category_name}
              </option>
            );
          })}
        </select>
        <button
          className="py-1 px-4 cursor-pointer bg-[#FF9933] border border-solid !border-transparent rounded-md font-bold text-[#000066] text-sm"
          onClick={handleUpdate}
        >
          Actualizar
        </button>
      </div>
    </div>
  );
};

export default Updates;
