import React from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";

const BottomMenu = () => {
  const navigate = useNavigate();
  return (
    <div className="xs:fixed md:hidden bottom-0 flex-between w-full p-2 bg-white bordert-t-1 border-[#000000] shadow ">
      <div className=" w-[33.3%] flex-center flex-col cursor-pointer">
        <HomeIcon
          style={{ fontSize: "25px", color: "#000066" }}
          onClick={() => {
            navigate("/");
          }}
        />
        <span className="text-[12px] text-kontevo-darkblue">Inicio</span>
      </div>
      <div
        className="w-[33.3%] flex-center flex-col  cursor-pointer"
        onClick={() => {
          navigate("/categories");
        }}
      >
        <GridViewIcon style={{ fontSize: "25px", color: "#000066" }} />
        <span className="text-[12px] text-kontevo-darkblue">Categorías</span>
      </div>
      <div className="w-[33.3%] flex-center flex-col cursor-pointer">
        <AccountCircleIcon
          style={{ fontSize: "25px", color: "#000066" }}
          onClick={() => {
            navigate("/account");
          }}
        />
        <span className="text-[12px] text-kontevo-darkblue">Cuenta</span>
      </div>
    </div>
  );
};

export default BottomMenu;
