import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTextInput from "../customTextInput/CustomTextInput";
import CustomFormButton from "../customFormButton/CustomFormButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  sendResetPasswordToken,
  setResetTokenStatus,
} from "../users/usersSlice";

const ResetPasswordModal = ({ setOpenModal }) => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setValidEmail] = useState();
  const { isLoading, resetTokenStatus } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  /* const validateEmail = () => {
    if (email) {
      //Patrón deseado
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = regexEmail.test(email);
      setValidEmail(isValid);
      setIsEmail(true);
    } else {
      setIsEmail(false);
      setValidEmail(true);
    }
  }; */

  const handleCloseClick = () => {
    setOpenModal(false);
    dispatch(setResetTokenStatus());
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();
    dispatch(sendResetPasswordToken({ email }));
  };
  return (
    <div className="modal-overlay">
      <div className="relative xs:w-[80%] md:w-[30%] bg-white p-5 rounded-[2rem] flex-center flex-col gap-4 !z-[2]">
        <div className="modal-header">
          <h3 className="text-[20px]">Reestablecer Contraseña</h3>
        </div>
        <div className="modal-inputs-container">
          <CustomTextInput
            label="Correo electrónico *"
            type="email"
            width="w-full"
            value={email}
            setValue={setEmail}
          />
        </div>
        <div className="w-full flex-center">
          <CustomFormButton
            handleClickFunction={handleSubmitClick}
            isLoading={isLoading}
            callToAction="Enviar Mensaje"
          />
        </div>
        <span className="text-[12px] font-bold">
          {resetTokenStatus?.message}
        </span>
        <button
          className="absolute modal-close-button "
          onClick={handleCloseClick}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
