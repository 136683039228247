import React from "react"
import "./adminFooter.scss";

const AdminFooter = (props) => {
  return (
    <div className="admin-footer">
      <span>Kontevo</span>
      <span>Kontevo All Rights Reserved 2023</span>
    </div>
  )
};

export default AdminFooter;

