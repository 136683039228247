import React from "react";
import Logo from "../../assets/kontevo-logo-blanco.png"
import "./adminHeader.scss";

const AdminHeader = (props) => {
  return (
    <div className="admin-header">
      <div>
        <img src={Logo} alt="Logo de kontevo" id="header-logo" />
      </div>
    </div>
  );
};

export default AdminHeader;
