import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addFeedback } from "../slices/feedbacksSlice";
import { AuthContext } from "../../App";
import { Spinner } from "react-bootstrap";

const Feedback = ({ searchedTag }) => {
    const [isSent, SetIsSent] = useState(false);
    const [isDisabled, SetIsDisabled] = useState("");
  const { user } = React.useContext(AuthContext);
  const { isLoading } = useSelector((state) => state.feedbacks);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(
      addFeedback({
        feedbackText: `Busqueda Fallida de: ${searchedTag}`,
        userId: user.id,
      })
    );
      SetIsSent(true);
      SetIsDisabled("pointer-events-none");
  };

  return (
    <div className="flex flex-column items-center gap-4 py-3">
      <h5 className="text-base">
        Sin resultados. ¿Quieres recibir una alerta cuando estén disponibles?
      </h5>
      <div className="flex gap-4">
        <button
          className={`py-1 px-3 font-bold border border-solid rounded-md hover:bg-[#FF9933] ${isDisabled}`}
          onClick={handleClick}
        >
          {!isLoading ? (
            !isSent ? (
              `Avisame`
            ) : (
              `Listo!`
            )
          ) : (
            <Spinner animation="border" size="sm" />
          )}
        </button>
      </div>
    </div>
  );
};

export default Feedback;
