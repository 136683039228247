import React, { useEffect, useState } from "react";
import PostDetails from "./PostDetails";
import PostMedia from "./PostMedia";
import axios from "axios";
/* Icons */
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { summarizeCopyMssg } from "../../../assets/constants";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DiscountIcon from "@mui/icons-material/Discount";
import CampaignIcon from "@mui/icons-material/Campaign";
/* Bootstrap */
import { useNavigate, Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import InstagramIcon from "@mui/icons-material/Instagram";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/* COMPONENT */
const Post = (props) => {
  const { setIsOpen, setFavPost, favPost, isSidebarOpen, post } = props;
  const [showMoreCopy, setShowMoreCopy] = useState(false);
  const [urlHasError, setUrlHasError] = useState(false);
  const [currentPost, setCurrentPost] = useState();
  const [shortCopy, setShortCopy] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [toggleIcon, setToggleIcon] = useState(false);
  const navigate = useNavigate();
  //copy preview max words
  const maxWords = 25;


  useEffect(() => {
    const summarizeCopy = async () => {
      setIsLoading(true);
      try {
        console.log();
        const response = await axios.get(
          `${SERVER_URL}/api/gpt/summarize-caption`,
          {
            params: {
              caption: post.caption,
            },
          }
        );
        setShortCopy(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log("error on gpt request", error);
      }
    };
    summarizeCopy();
  }, []);


  /* Función que convierte el timestamp en una fecha legible DD/MM/YY */
  const timestampToDate = (timestamp) => {
    let date = new Date(timestamp);
    let formattedDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    return formattedDate;
  };

  const toggleShowMoreCopy = () => {
    setShowMoreCopy(!showMoreCopy);
  };

  /* Función para mostrar el copy. */
  const renderCopy = () => {
    if (shortCopy) {
      /*  return <p>{shortCopy}</p>; */
    }

    if (showMoreCopy) {
      return (
        <div className="">
          <p>{post.caption}</p>
          <span
            className="text-[#0066CC] font-bold cursor-pointer"
            onClick={toggleShowMoreCopy}
          >
            {" "}
            Menos
          </span>
        </div>
      );
    }

    const words = post.caption.split(" ");
    const truncatedText = words.slice(0, maxWords).join(" ");
    const remainingText = words.slice(maxWords).join(" ");

    return (
      <div>
        <p>
          {truncatedText}
          {remainingText && "..."}
          <span
            className="text-[#0066CC] font-bold cursor-pointer"
            onClick={toggleShowMoreCopy}
          >
            {" "}
            Más
          </span>
        </p>
      </div>
    );
  };

  const summarizeCopy = async (caption) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${SERVER_URL}/gpt/summarize-caption`, {
        params: {
          caption,
        },
      });
      setShortCopy(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log("error on gpt request", error);
    }
  };

  /* COMPONENT RETURN STATEMENT */
  return (
    !urlHasError && (
      <div
        id="postCard"
        className={`xs:w-full ${
          isSidebarOpen ? "md:w-[45%]" : "md:w-[30%]"
        } flex flex-col items-center gap-2 relative  pb-4  xs:border-solid xs:border-b-[1px] xs:border-[#e9eaed] rounded-md bg-white md:shadow`}
      >
        {/* post Header */}
        <div id="postHeader" className="w-full p-2 flex items-baseline gap-1">
          {/* post username */}
          <div className="w-ful">
            <Link
              to={post.permalink}
              target="_blank"
              className=" cursor-pointer"
            >
              <span className="text-[#000066]">
                <InstagramIcon />
              </span>
            </Link>
          </div>
          {/* post date */}
          <div className="w-full flex justify-end">
            <span className="xs:text-xs font-bold text-[#0066cc]">
              {timestampToDate(post.timestamp)}
            </span>
          </div>
        </div>
        {/*Post Body*/}
        <div className="md:w-full xs:w-full relative">
          {/* Post Media (video or image) */}
          {
            <PostMedia
              thumbnailUrl={post.thumbnail_url}
              setUrlHasError={setUrlHasError}
              carrouselSlides={post.children}
              mediaUrl={post.media_url}
              post={post}
            />
          }
          {/*Post Details (buttons)*/}
          {/* <PostDetails
            post={post}
            name={props.name}
            setIsOpen={setIsOpen}
            setFavPost={setFavPost}
            favPost={favPost}
          /> */}
        </div>
        <div className="flex flex-col gap-4 px-2">
          {/* Copy */}
          <div
            id="postCopy"
            className="flex flex-col gap-2 md:text-[14px] xs:text-xs"
          >
            <span>{post.id}</span>
            <span>{post.status}</span>
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <div className="post-copy flex flex-col gap-2">
                <div className="title flex-center mb-2">
                  <span className="font-bold text-[16px]">
                    {post.deal_title || shortCopy?.title}
                  </span>
                </div>
                <div className="deal flex gap-1">
                  <span>
                    <DiscountIcon
                      style={{ fontSize: "16px", color: "#0066CC" }}
                    />
                  </span>
                  <span className="text-[13px] text-[#212529]">
                    {post.deal_description || shortCopy?.deal}
                  </span>
                </div>
                {(shortCopy?.price || post.deal_price) && (
                  <div className="price flex gap-1">
                    <span className="text-[16px] text-[#FF9933] font-bold ml-[20px]">
                      {post.deal_price || shortCopy?.price}
                    </span>
                  </div>
                )}
                <div className="flex gap-1">
                  <span>
                    <CalendarMonthIcon
                      style={{ fontSize: "16px", color: "#0066CC" }}
                    />
                  </span>
                  <span className="text-[13px] text-[#212529]">
                    {post.deal_availability || shortCopy?.availability}
                  </span>
                </div>
                <div className="flex gap-1">
                  <span>
                    <CampaignIcon
                      style={{ fontSize: "18px", color: "#0066CC" }}
                    />
                  </span>
                  <span className="text-[13px] text-[#212529] ">
                    {post.deal_conditions || shortCopy?.conditions}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default Post;
