import React from "react";
import { Carousel, Figure } from "react-bootstrap";
import ImageMedia from "./ImageMedia";
import VideoMedia from "./VideoMedia";

const CarouselMedia = ({ carrouselSlides, setUrlHasError }) => {

const renderCarrousel = () => {
  return carrouselSlides?.map((slide, index) => {
    slide = JSON.parse(slide);
    return slide.media_type === "VIDEO" ? (
      <Carousel.Item key={index}>
        <VideoMedia url={slide.media_url} setUrlHasError={setUrlHasError} />
      </Carousel.Item>
    ) : (
      <Carousel.Item key={index}>
        <ImageMedia url={slide.media_url} setUrlHasError={setUrlHasError} />
      </Carousel.Item>
    );
  });
};

  return (
    <Carousel slide={false} interval={null}>
      {renderCarrousel()}
    </Carousel>
  );
};

export default CarouselMedia;
