import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { getAccountsByCategoryId } from "./igAccountsCategoriesSlice";
import { categoryKeywordsFreq } from "../../utils/keywordsFrequency";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/* OBTENER CATEGORIAS*/
export const getDbIgCategories = createAsyncThunk(
  "categories/getDbIgCategories",
  async ({ columns }, thunkAPI) => {
    const response = await axios.get(`${SERVER_URL}/api/categories`, {
      params: {
        columns: `${columns}`,
      },
    });
    const categories = response.data;
    return categories;
  }
);

/* OBTENER CATEGORIA X SU NOMBRE */
export const getCategoryByName = createAsyncThunk(
  "categories/getCategoryByName",
  async ({ categoryName }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/api/categories/name/${categoryName}`
      );
      const category = response.data;
      return { category };
    } catch (error) {
      console.log("Ocurrio un error al obtener una categoria por su id", error);
    }
  }
);

/* OBTENER CATEGORIA X SU ID */
export const getCategoryById = createAsyncThunk(
  "categories/getCategoryById",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/categories/${id}`);
      const category = response.data;
      return { category };
    } catch (error) {
      console.log("Ocurrio un error al obtener una categoria por su id", error);
    }
  }
);

/* OBTENER CATEGORIAS DE UNA CATEGORIA PADRE */
export const getCategoriesByParentId = createAsyncThunk(
  "categories/getCategoriesByParentId",
  async ({ parentId, keywords }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/api/categories/parent_category/${parentId}`,
        {
          params: {
            keywords: keywords,
          },
        }
      );
      const categories = response.data;
      return categories;
    } catch (error) {
      console.log(
        "error al obtener las Categorias pertenecientes a una categoria padre",
        error
      );
      return [];
    }
  }
);

/* GET PANAMA CATEGORIES */
export const getPanamaIgCats = createAsyncThunk(
  "categories/getPanamaIgCats",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/categories/${id}`);
      const category = response.data;
      return category;
    } catch (error) {
      console.log("error retrieving Panama Categories", error);
      return [];
    }
  }
);

/* CREAR CATEGORIA */
export const addCategory = createAsyncThunk(
  "categories/addCategory",
  async (
    { name = null, keywords = null, parent_category_id = null },
    thunkAPI
  ) => {
    const response = await axios.post(
      `${SERVER_URL}/api/categories/add-category`,
      {
        name,
        keywords,
        parent_category_id,
      }
    );
    const new_category = response.data;
    return new_category;
  }
);

/* ELIMINAR CATEGORIA */
export const deleteCategory = createAsyncThunk(
  "categories/deleteCategory",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.delete(`${SERVER_URL}/api/categories/${id}`);
      const deleted_category = response.data;
      return deleted_category;
    } catch (error) {
      console.log("failed to deleted category", error);
    }
  }
);

/* ACTUALIZAR CATEGORIA */
export const updateCategory = createAsyncThunk(
  "categories/updateCategory",
  async (
    { id, name, keywords, parent_category_id, keywordsFreq },
    thunkAPI
  ) => {
    try {
      const response = await axios.put(`${SERVER_URL}/api/categories/${id}`, {
        name,
        keywords,
        parent_category_id,
        keywordsFreq,
      });
      const updatedCategory = response.data;
      return updatedCategory;
    } catch (error) {
      console.log(error);
    }
  }
);

export const refreshCategories = createAsyncThunk(
  "categories/refreshCategories",
  async ({ parentCategory }, thunkAPI) => {
    const keywords = await updateCategoryKeywords(parentCategory, thunkAPI);
    return keywords;
  }
);

export const refreshCategoryKeywords = createAsyncThunk(
  "categories/refreshCategoryKeywords",
  async ({ id, name, parent_category_id }, thunkAPI) => {
    try {
      const categories = thunkAPI.getState().categories.categories;
      const childCategories = categories.filter(
        (category) => category.parent_category_id === parseInt(id)
      );
      if (childCategories.length > 0) {
        //Concatenar keywords de categorias hijo en la categoria Padre.
        const { uniquekwds, repeatedKwds } = mergeKeywords(childCategories);
        //Calcular frecuencia a partir del array con los keywords duplicados
        const keywordsFreq = categoryKeywordsFreq(repeatedKwds);
        thunkAPI.dispatch(
          updateCategory({
            id,
            name,
            keywords: uniquekwds,
            parent_category_id,
            keywordsFreq: keywordsFreq,
          })
        );
        return { uniquekwds, repeatedKwds };
      } else {
        //Retrieve Instagram accounts belonging to the category
        const response = await axios.get(
          `${SERVER_URL}/instagram-accounts-categories/api/categories/${id}`
        );
        const igAccounts = response.data;
        //Unify all keywords associated with category`s Instagram accounts
        const { uniquekwds, repeatedKwds } = mergeKeywords(igAccounts);
        //Calculate the frequency of each keyword repetition
        const keywordsFreq = categoryKeywordsFreq(repeatedKwds);
        //update category on db
        thunkAPI.dispatch(
          updateCategory({
            id,
            name,
            keywords: uniquekwds,
            parent_category_id,
            keywordsFreq: keywordsFreq,
          })
        );
        return { uniquekwds, repeatedKwds };
      }
    } catch (error) {
      console.log(
        `Error while trying to update category ${name} keywords`,
        error
      );
    }
  }
);

export const updateCategoryKeywords = async (parentCategory, thunkAPI) => {
  const categories = await thunkAPI.getState().categories.categories;
  try {
    const childCategories = categories.filter(
      (category) => category.parent_category_id === parseInt(parentCategory.id)
    );
    if (childCategories.length > 0) {
      const keywords = await childCategories.reduce(
        async (prevPromise, childCategory) => {
          const prevKeywords = await prevPromise;
          const categoryKeywords = await updateCategoryKeywords(
            childCategory,
            thunkAPI
          );
          return prevKeywords.concat(categoryKeywords);
        },
        []
      );
      const uniqueKeywords = removeDuplicateKeywords(keywords);
      await thunkAPI.dispatch(
        updateCategory({
          id: parentCategory.id,
          name: parentCategory.name,
          keywords: keywords,
          parent_category_id: parentCategory.parent_category_id,
        })
      );
      return keywords;
    } else {
      /* Obtener cuentas pertenecientes a la categoria */
      await thunkAPI.dispatch(
        getAccountsByCategoryId({ categoryId: parentCategory.id })
      );
      const categoryAccounts =
        thunkAPI.getState().igAccountsCategories.categoryAccounts;

      /* Concatenar keywords de las publicaciones de las cuentas pertencientes a la categoria */
      const keywords = categoryAccounts.reduce((keywords, account) => {
        if (account.keywords == null) {
          return keywords.concat([]);
        } else {
          return keywords.concat(account.keywords);
        }
      }, []);

      const uniqueKeywords = removeDuplicateKeywords(keywords);
      await thunkAPI.dispatch(
        updateCategory({
          id: parentCategory.id,
          name: parentCategory.name,
          keywords: keywords,
          parent_category_id: parentCategory.parent_category_id,
        })
      );
      return keywords;
    }
  } catch (error) {
    console.log("error on updateCategoryKeywords ", error);
  }
};

export const uniqueCatkeywords = (array, keywords) => {
  return array.keywords.filter((keyword) => !keywords.includes(keyword));
};

export const mergeKeywords = (array) => {
  let uniquekwds = [];
  let repeatedKwds = [];
  array.forEach((current) => {
    if (current.keywords) {
      //Si la keyword no está previamente en el array, entonces agregar.
      const uniqueKeywords = uniqueCatkeywords(current, uniquekwds);
      uniquekwds = uniquekwds.concat(uniqueKeywords);
      repeatedKwds = repeatedKwds.concat(current.keywords);
    }
  });
  return { uniquekwds, repeatedKwds };
};

const removeDuplicateKeywords = (keywords) => {
  const uniqueKeywords = [...new Set(keywords)];
  return uniqueKeywords;
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    categories: [],
    single_category: null,
    parentCategories: [],
    isLoading: false,
    hasError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(refreshCategories.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(refreshCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const keywords = action.payload;
      })
      .addCase(refreshCategories.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(refreshCategoryKeywords.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(refreshCategoryKeywords.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        console.log("action payload", action.payload);
        //const { uniqueKwds, repeatedKwds } = action.payload;
        //console.log("repeatedKwds", repeatedKwds);
      })
      .addCase(refreshCategoryKeywords.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(updateCategory.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const updatedCategory = action.payload;
        state.single_category = updatedCategory;
        const index = current(state).categories.findIndex(
          (category) => category.id === updatedCategory.id
        );
        if (index !== -1) {
          state.categories[index] = updatedCategory;
        } else {
          console.log("Categoria no encontrada");
        }
      })
      .addCase(updateCategory.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(getCategoryById.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getCategoryById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        console.log("action", action.payload);
        const { category } = action.payload;
        const singleCategory = category;
        state.single_category = singleCategory;
      })
      .addCase(getCategoryById.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(getCategoryByName.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getCategoryByName.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const { category } = action.payload;
        const singleCategory = category;
        state.single_category = singleCategory;
      })
      .addCase(getCategoryByName.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
        .addCase(addCategory.pending, (state) => {
          state.isLoading = true;
          state.hasError = false;
        })
        .addCase(addCategory.fulfilled, (state, action) => {
          state.isLoading = false;
          state.hasError = false;
          const new_category = action.payload;
          state.categories.push(new_category);
        })
        .addCase(addCategory.rejected, (state) => {
          state.isLoading = false;
          state.hasError = true;
        })
        .addCase(getDbIgCategories.pending, (state) => {
          state.isLoading = true;
          state.hasError = false;
        })
        .addCase(getDbIgCategories.fulfilled, (state, action) => {
          state.isLoading = false;
          state.hasError = false;
          let categories = action.payload;
          state.categories = categories;
          const parentCategories = categories
            .filter(
              (category) => category.id !== 109
              /* category.id === 68 ||
              category.id === 75 ||
              category.id === 89 ||
              category.id === 84 ||
              category.id === 65 ||
              category.id === 76 ||
              category.id === 88 ||
              category.id === 66 ||
              category.id === 90 */
            )
            .map((category) => {
              return category;
            });
          state.parentCategories = parentCategories;
        })
        .addCase(getDbIgCategories.rejected, (state) => {
          state.isLoading = false;
          state.hasError = true;
        })
        /* PANAMA CATEGORIES */
        .addCase(getPanamaIgCats.pending, (state) => {
          state.isLoading = true;
          state.hasError = false;
        })
        .addCase(getPanamaIgCats.fulfilled, (state, action) => {
          state.isLoading = false;
          state.hasError = false;
          let category = action.payload;
          console.log("category", category);
          state.parentCategories.push(category);
          state.categories.push(category);
        })
        .addCase(getPanamaIgCats.rejected, (state) => {
          state.isLoading = false;
          state.hasError = true;
        })
        /* DELETE CATEGORY */
        .addCase(deleteCategory.pending, (state) => {
          state.isLoading = true;
          state.hasError = false;
        })
        .addCase(deleteCategory.fulfilled, (state, action) => {
          state.isLoading = false;
          state.hasError = false;
          const deleted_category = action.payload;
          state.categories = state.categories.filter(
            (category) => category.id !== deleted_category.id
          );
        })
        .addCase(deleteCategory.rejected, (state) => {
          state.isLoading = false;
          state.hasError = true;
        })
        .addCase(getCategoriesByParentId.pending, (state) => {
          state.isLoading = true;
          state.hasError = false;
        })
        .addCase(getCategoriesByParentId.fulfilled, (state, action) => {
          state.isLoading = false;
          state.hasError = false;
          state.categories = action.payload;
        })
        .addCase(getCategoriesByParentId.rejected, (state) => {
          state.isLoading = false;
          state.hasError = true;
        });
  },
});

export const select_is_loading = (state) => state.categories.isLoading;
export const select_single_category = (state) =>
  state.categories.single_category;
export const select_parent_categories = (state) =>
  state.categories.parentCategories;
export const select_categories = (state) => state.categories.categories;
export default categoriesSlice.reducer;
