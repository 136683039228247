import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/* Obtener ciudades y cuentas de instagram*/
export const getCitiesIgAccounts = createAsyncThunk(
  "citiesIgAccounts/getCitiesIgAccounts",
  async (arg, thunkAPI) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/cities-instagram-accounts`
      );
      const citiesAccounts = response.data;
      return citiesAccounts;
    } catch (error) {
      console.log(error);
    }
  }
);

/* Obtener ig accounts de una sola ciudad */
export const getSingleCityAccounts = createAsyncThunk(
  "citiesIgAccounts/getSingleCityAccounts",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/cities-instagram-accounts/cities/${id}`
      );
      const singleCityAccounts = response.data;
      return singleCityAccounts;
    } catch (error) {
      console.log(error);
    }
  }
);

/* (Add) Asignar una cuenta de instagram a una ciudad */
export const addCityIgAccount = createAsyncThunk(
  "citiesIgAccounts/addCityIgAccount",
  async ({ instagram_account_id, city_id }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/cities-instagram-accounts/add-city-instagram-account`,
        {
          instagram_account_id,
          city_id,
        }
      );
      const newCityIgAccount = response.data;
      /* Llamo a getCitiesIgAccounts para actualizar el state y que incluya la  nueva relación*/
      await thunkAPI.dispatch(getCitiesIgAccounts());
      await thunkAPI.dispatch(
        getSingleIgAccountCities({ id: instagram_account_id })
      );
      return newCityIgAccount;
    } catch (error) {
      console.log("error", error);
    }
  }
);

/* (Get /instagram-account/:id) Obtener todas las ciudades a las que pertenece una cuenta */
export const getSingleIgAccountCities = createAsyncThunk(
  "citiesIgAccounts/getSingleIgAccountCities",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/cities-instagram-accounts/instagram-account/${id}`
      );
      const singleIgAccountCities = response.data;
      return singleIgAccountCities;
    } catch (error) {
      console.log(error);
    }
  }
);

export const searchCitiesForIgAccounts = createAsyncThunk(
  "citiesIgAccounts/searchCitiesForIgAccounts",
  async ({ igAccounts }, thunkAPI) => {
    try {
      const citiesPromises = igAccounts.map(async (account) => {
        await thunkAPI.dispatch(
          getSingleIgAccountCities({ id: account.ig_account_id })
        );
        const SingleIgAccountCities = await thunkAPI.getState().citiesIgAccounts
          .singleIgAccountCities;
        return SingleIgAccountCities;
      });

      const resultPromises = await Promise.all(citiesPromises);

      const matchedCities = {};
      resultPromises.forEach((account) => {
        account.forEach((city) => {
          if (!matchedCities.hasOwnProperty(city.city_name)) {
            matchedCities[city.city_name] = [
              { id: city.ig_account_id, username: city.username },
            ];
          } else {
            matchedCities[city.city_name] = matchedCities[
              city.city_name
            ].concat({ id: city.ig_account_id, username: city.username });
          }
        });
      });
      return matchedCities;
    } catch (error) {
      console.log("error in searchCitiesForIgAccounts", error);
    }
  }
);

export const deleteCityIgAccount = createAsyncThunk(
  "citiesIgAccounts/deleteCityIgAccount",
  async ({ instagramAccountId, cityId }, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/cities-instagram-accounts/instagram-account/${instagramAccountId}/city/${cityId}`
      );
      const deletedCityAccount = response.data;
      await thunkAPI.dispatch(
        getSingleIgAccountCities({ id: instagramAccountId })
      );
      return deletedCityAccount;
    } catch (error) {
      console.log("error in (deleteIgAccountCategory)", error);
    }
  }
);

export const citiesIgAccountsSlice = createSlice({
  name: "citiesIgAccounts",
  initialState: {
    isLoading: false,
    hasError: false,
    citiesIgAccounts: [],
    singleCityIgAccounts: [],
    singleIgAccountCities: [],
    matchedCities: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteCityIgAccount.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(deleteCityIgAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(deleteCityIgAccount.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(searchCitiesForIgAccounts.pending, (state) => {
        state.isLoadin = true;
        state.hasError = false;
      })
      .addCase(searchCitiesForIgAccounts.fulfilled, (state, action) => {
        state.isLoadin = false;
        state.hasError = false;
        const matchedCities = action.payload;
        state.matchedCities = matchedCities;
      })
      .addCase(searchCitiesForIgAccounts.rejected, (state) => {
        state.isLoadin = false;
        state.hasError = true;
      })
      .addCase(getSingleIgAccountCities.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getSingleIgAccountCities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const singleIgAccountCities = action.payload;
        state.singleIgAccountCities = singleIgAccountCities;
      })
      .addCase(getSingleIgAccountCities.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(addCityIgAccount.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(addCityIgAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const newCityIgAccount = action.payload;
      })
      .addCase(addCityIgAccount.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(getCitiesIgAccounts.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getCitiesIgAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const citiesIgAccounts = action.payload;
        state.citiesIgAccounts = citiesIgAccounts;
      })
      .addCase(getCitiesIgAccounts.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(getSingleCityAccounts.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getSingleCityAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const singleCityAccounts = action.payload;
        state.singleCityIgAccounts = singleCityAccounts;
      })
      .addCase(getSingleCityAccounts.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      });
  },
});

/* selects */
export const select_matched_cities = (state) =>
  state.citiesIgAccounts.matchedCities;
export const select_sigle_ig_account_cities = (state) =>
  state.citiesIgAccounts.singleIgAccountCities;
export const select_cities_ig_accounts = (state) =>
  state.citiesIgAccounts.citiesIgAccounts;
export const select_single_city_ig_accounts = (state) =>
  state.citiesIgAccounts.singleCityIgAccounts;

export default citiesIgAccountsSlice.reducer;
