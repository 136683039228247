import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Single from "../../../features/single/Single";
import {
  select_single_category,
  getCategoryById,
  updateCategory,
} from "../../../features/slices/categoriesSlice";
import { refreshCategoryKeywords } from "../../../features/slices/categoriesSlice";
import { useParams } from "react-router-dom";
import "./singleLocationPage.scss";
import {
    getSingleCity,
  select_single_city,
  updateCity,
} from "../../../features/slices/citiesSlice";

const SingleLocationPage = (props) => {
  const city = useSelector(select_single_city);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getSingleCity({ id }));
  }, []);

  /*  useEffect(() => {
    if (reduxSingleCategory) {
      setSingleCategory({
        name: reduxSingleCategory.name,
        parent_category_id: reduxSingleCategory.parent_category_id,
      });
    }
  }, [reduxSingleCategory]); */

  const updateItem = (item) => {
    dispatch(
      updateCity({
        id: id,
        full_name: item["full_name"],
      })
    );
  };

  return (
    <div className="single-category">
      <Single item={city} slug="Ciudad" updateItem={updateItem} />
    </div>
  );
};

export default SingleLocationPage;
