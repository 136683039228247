import React, { useEffect } from "react";
import "./newsletter.scss";


const HubspotContactForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
    portalId: "44915176",
    formId: "112466da-f783-40d0-b0dc-356e37a4cb9b",
          target: "#hubspotForm",
        });
      }
    });
  }, []);
    
  return (
    <div className="flex flex-col">
      <div id="hubspotForm"></div>
    </div>
  );
};
 
export default HubspotContactForm;


