import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { select_categories } from "../../../features/slices/categoriesSlice";
import { select_cities } from "../../../features/slices/citiesSlice";
import DataTable from "../../../features/dataTable/DataTable";
import {
  select_categories_with_accounts,
  getCategoriesWithAccounts,
} from "../../../features/slices/igAccountsCategoriesSlice";
import {
  select_ig_accounts,
  retrieveAllIgAccounts,
  addIgAccount,
  deleteIgAccount,
  select_is_loading,
  refreshMultipleAccounts,
} from "../../../features/slices/instagamAccountsSlice";
import AddModal from "../../../features/addModal/AddModal";
import Updates from "../../../features/updates/Updates";
import CSVUploader from "../../../features/csv/CSVUploader";
import { Spinner } from "react-bootstrap";
import "./igAccountsPage.scss";

const columns = [
  { field: "id", headerName: "ID", width: 90, type: "number" },
  {
    field: "username",
    headerName: "Username",
    width: 150,
    type: "string",
  },
  {
    field: "category",
    headerName: "Category",
    width: 200,
    type: "string",
  },
  {
    field: "posts_json",
    headerName: "Posts json",
    width: 200,
    type: "string",
  },
  {
    field: "keywords",
    headerName: "Keywords",
    width: 200,
    type: "string",
  },
];

const IgAccountsPage = (props) => {
  const igAccounts = useSelector(select_ig_accounts);
  const isLoading = useSelector(select_is_loading);
  const categories = useSelector(select_categories);
  const cities = useSelector(select_cities);
  const categoriesWithAccounts = useSelector(select_categories_with_accounts);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const formInputs = [
    {
      label: "Username",
      field: "username",
      type: "text",
    },
    {
      label: "Categoría",
      field: "category_id",
      type: "select",
      selectName: "categorySelect",
      options:
        categories.length > 0
          ? categories.map((category) => {
              return {
                value: category.id,
                content: category.name,
              };
            })
          : [],
    },
    {
      label: "Ciudades",
      field: "cities_id",
      type: "checkbox",
      options:
        cities.length > 0
          ? cities.map((city) => {
              return {
                value: city.id,
                content: city.full_name,
              };
            })
          : [],
    },
  ];

  useEffect(() => {
    dispatch(getCategoriesWithAccounts());
    dispatch(retrieveAllIgAccounts());
  }, []);

  const createItem = (item) => {
    dispatch(addIgAccount(item));
  };

  const deleteItem = (id) => {
    dispatch(deleteIgAccount({ id }));
  };

  const refreshAllAccounts = async (selectedValue) => {
    await dispatch(
      refreshMultipleAccounts({ categoryId: selectedValue.category_id })
    );
  };

  return (
    <div className="admin-ig-accounts">
      <div className="info">
        <h1>Instagram Account</h1>
        <button
          onClick={() => {
            setOpen(true);
          }}
        >
          Add new Instagram Account
        </button>
      </div>
      {isLoading ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <DataTable
          slug="instagram-accounts"
          columns={columns}
          rows={igAccounts}
          deleteItem={deleteItem}
        />
      )}
      {igAccounts.length > 0 && (
        <div className="flex gap-8 border-top border-white">
          {/* update accounts by category */}
          <Updates
            options={categoriesWithAccounts}
            updateItem={refreshAllAccounts}
            slug="Categoría"
            subSlug="Posts y Keywords"
          />
          {/* csv file upload  */}
          <CSVUploader />
        </div>
      )}
      {open && (
        <AddModal
          slug="instagram_account"
          formInputs={formInputs}
          setOpen={setOpen}
          createItem={createItem}
        />
      )}
    </div>
  );
};

export default IgAccountsPage;
