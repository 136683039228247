import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTextInput from "../customTextInput/CustomTextInput";
import CustomFormButton from "../customFormButton/CustomFormButton";
import CloseIcon from "@mui/icons-material/Close";
import { addUser } from "../users/usersSlice";

const SignUpModal = ({ setShowPopup }) => {
  const { isLoading, addUserStatus } = useSelector((state) => state.users);
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (addUserStatus === 201) {
      setShowPopup(false);
    }
  }, [addUserStatus]);

  const handleSubmitClick = () => {
    dispatch(addUser({ firstname, lastname, phone, email, password }));
  };

  const handleCloseClick = () => {
    setShowPopup(false);
  };

  return (
    <div className="modal-overlay !z-[20]">
      <div className="relative xs:w-[80%] md:w-[30%] bg-white p-5 rounded-[2rem] flex-center flex-col gap-4 ">
        {/* header */}
        <div className="flex-center flex-col">
          <h3 className="xs:text-[24px] md:text-[30px] font-bold text-kontevo-darkblue">
            Regístrate!
          </h3>
          <p className="xs:text-[13px] md:text-[16px] text-center">
            Entérate de primero sobre las mejores ofertas directamente en tu
            <span className="text-kontevo-lightblue font-bold">
              {" "}
              correo o whatsApp
            </span>
          </p>
        </div>
        {/* Modal Inputs */}
        <div className="modal-inputs-container">
          <CustomTextInput
            label="Nombre *"
            type="text"
            width="w-full"
            value={firstname}
            setValue={setFirstname}
          />
          <CustomTextInput
            label="Apellido *"
            type="text"
            width="w-full"
            value={lastname}
            setValue={setLastname}
          />
          <CustomTextInput
            label="Correo Electrónico *"
            type="email"
            width="w-full"
            value={email}
            setValue={setEmail}
          />
          <CustomTextInput
            label="Teléfono"
            type="text"
            width="w-full"
            value={phone}
            setValue={setPhone}
          />
          <CustomTextInput
            label="Contraseña *"
            type="password"
            width="w-full"
            value={password}
            setValue={setPassword}
          />
        </div>
        {/* Submit Button */}
        <div className="w-full flex-center">
          <CustomFormButton
            handleClickFunction={handleSubmitClick}
            isLoading={isLoading}
            callToAction="Registrarme"
          />
        </div>
        <button
          className="absolute modal-close-button "
          onClick={handleCloseClick}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

export default SignUpModal;
