import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Spinner } from "react-bootstrap";

const CustomFormButton = ({ handleClickFunction, isLoading, callToAction }) => {
  return (
    <button
      className="w-full border rounded-[2rem] bg-kontevo-lightblue text-white xs:text-[12px] xs:p-[6px]"
      onClick={handleClickFunction}
    >
      {isLoading ? <Spinner animation="border" size="sm" /> : callToAction}
    </button>
  );
};

export default CustomFormButton;
