import React from "react";
import { parentCategories } from "../../dummy";
import { useNavigate } from "react-router-dom";

const CategoriesPage = () => {
  const navigate = useNavigate();
  const handleCategoryClick = (parentCategory) => {
    if (
      parentCategory.name === "Restaurantes" ||
      parentCategory.name === "Moda" ||
      parentCategory.name === "Supermercados" ||
      parentCategory.name === "Vehículos"
    ) {
      ////navegar hacia el listado de sub categorias
      navigate(`/categories/id/${parentCategory.id}`);
    } else {
      //navegar directamente a las cuentas de la categoria
      navigate(`/categories/${parentCategory.name}`);
    }
  };

  return (
    <div className="w-full h-[100%] flex flex-col gap-5 bg-[#F8F8F8] xs:px-0 md:px-20 py-4">
      <div className="-mb-12">
        <h2 className="xs:text-[20px] md:text-[24px] font-bold xs:ml-2">
          Categorías
        </h2>
      </div>
      <div className="flex-around  items-baseline gap-[10px] flex-wrap py-4">
        {parentCategories?.map((parentCategory, index) => (
          <div
            key={index}
            className="md:w-[360px] md:h-[172px] xs:w-[45%] h-[172px] flex-center flex-col gap-2 bg-white cursor-pointer shadow-sm"
            onClick={(e) => {
              handleCategoryClick(parentCategory);
            }}
          >
            <img src={parentCategory.media_url} alt={parentCategory.name} />
            <h3 className="font-bold text-[13px]">{parentCategory.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoriesPage;
