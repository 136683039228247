import React from "react"
import "./adminSidebar.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faUser, faFolder } from "@fortawesome/free-regular-svg-icons";
import { faTag, faCity } from "@fortawesome/free-solid-svg-icons";




const AdminSidebar = (props) => {
  return (
    <div className="admin-sidebar">
      <div className="item">
        <span className="title">General</span>
        <Link to="/admin/users" className="list-item">
          <span className="icon">
            <FontAwesomeIcon icon={faUser} />
          </span>
          <span className="admin-list-item-title">Users</span>
        </Link>
        <Link to="/admin/instagram-accounts" className="list-item">
          <span className="icon">
            <FontAwesomeIcon icon={faInstagram} />
          </span>
          <span className="admin-list-item-title">Ig Accounts</span>
        </Link>
        <Link to="/admin/categories" className="list-item">
          <span className="icon">
            <FontAwesomeIcon icon={faFolder} />
          </span>
          <span className="admin-list-item-title">Categories</span>
        </Link>
        <Link to="/admin/keywords" className="list-item">
          <span className="icon">
            <FontAwesomeIcon icon={faTag} />
          </span>
          <span className="admin-list-item-title">Keywords</span>
        </Link>
        <Link to="/admin/locations" className="list-item">
          <span className="icon">
            <FontAwesomeIcon icon={faCity} />
          </span>
          <span className="admin-list-item-title">Locations</span>
        </Link>
      </div>
    </div>
  );
};

export default AdminSidebar;
