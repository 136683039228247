import AccountFilter from "../accountsFilter/AccountsFilter";
import CloseIcon from "@mui/icons-material/Close";
import CategoryFilter from "../categoryFilter/CategoryFilter";
import DateFilter from "../dateFilter/DateFilter";

const FilterView = ({
  setShowFilter,
  showFilter,
  chosenFilter,
  activeFilters,
  setActiveFilters,
}) => {
  const renderFilter = () => {
    if (chosenFilter === "Cuentas") {
      return (
        <AccountFilter
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
        />
      );
    } else if (chosenFilter === "Categoria") {
      return (
        <CategoryFilter
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
        />
      );
    } else if (chosenFilter === "Fecha") {
      return (
        <DateFilter
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
        />
      );
    } else if (chosenFilter === "Ubicacion") {
      //
    }
  };

  return (
    <div
      className={`flex flex-col gap-4 bg-white md:static md:w-full md:h-full xs:fixed xs:bottom-0 xs:left-0 xs:w-full xs:h-0 xs:overflow xs:transition-height xs:duration-300 xs:ease-in-out ${
        showFilter && `xs:h-full`
      }`}
    >
      {/* HEADER */}
      <header className="xs:flex xs:justify-between xs:items-center xs:pt-12 xs:px-2 xs:border-b-[1px] xs:border-black">
        <h2 className="text-2xl font-bold">
          <span className="after:!w-full after:!bg-black after:!h-4">
            {chosenFilter}
          </span>
        </h2>
        {/* CLOSE BUTTON */}
        <button
          className="absolute right-0 top-0 p-3 active:text-[#727272]"
          onClick={() => {
            setShowFilter(!showFilter);
          }}
        >
          <CloseIcon />
        </button>
      </header>
      {/* RENDERIZAR FILTRO ESCOGIDO */}
      {showFilter && renderFilter()}
    </div>
  );
};

export default FilterView;
