import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../../App";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { favsList } from "../../dummy";
import { mobile } from "../../responsive";
import { addCollection, getCollections } from "../slices/collectionsSlice";
import { addFavoriteItem } from "../slices/favoriteItemsSlice";
import { addFavorite } from "../slices/favoritesSlice";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  z-index: 100; /* Asegura que esté por encima de otros elementos */
  display: ${(props) => (props.open ? "fixed" : "none")};
  align-items: center;
  justify-content: center;
`;

const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 600px;
  max-width: 100%;
  height: 400px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 5px;
  background-color: #000066;
  transform: translate(-50%, -50%);

  ${mobile({ width: "90%" })}
`;

const Header = styled.div`
  width: 100%;
  height: 10%;
  padding: 30px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  color: #ff9933;
`;

const Body = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubTitle = styled.h3`
  font-size: 18px;
  color: white;
`;

const List = styled.ul`
  width: 60%;
  height: auto;
  max-height: 180px;
  background-color: rgba(255, 255, 255, 1);
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
`;

const ListItem = styled.li`
  width: 100%;
  padding: 10px 20px;
  border-bottom: 1px solid #66cc33;
  color: #000066;
  cursor: pointer;
  &:hover {
    background-color: #0066cc;
    color: white;
  }
`;

const AddContainer = styled.div`
  position: absolute;
  width: 60%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 10px;
  bottom: 0;
`;

const Input = styled.input`
  flex: 2;
  padding: 10px 20px;
  border-radius: 5px;
  border-color: #66cc33;
  font-size: 14px;
`;

const AddButton = styled.button`
  background-color: #ff9933;
  color: #000066;
  font-weight: bold;
  flex: 1;
  border: 1px solid #000066;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 14px;

  &:hover {
    background-color: #66cc33;
  }
`;

const FavsModal = ({ title, setModalOpen, modalOpen, favPost }) => {
  const [collectionName, setCollectionName] = useState(null);
  const { user } = React.useContext(AuthContext);
  const collections = useSelector((state) => state.collections.collections);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCollections(user.id));
  }, []);

  const handleChange = (e) => {
    setCollectionName(e.target.value);
  };

  const handleAddFav = () => {
    dispatch(
      addCollection({ userId: user.id, collectionName: collectionName })
    );
    setCollectionName("");
  };

  const handleFavClick = async (collectionId) => {
    await dispatch(addFavoriteItem(favPost));
    dispatch(addFavorite(collectionId));
    setModalOpen(false);
  };

  return (
    <Overlay open={modalOpen}>
      <Modal>
        <Header>
          <Title>{title}</Title>
          <CloseIcon
            style={{
              width: "25px",
              height: "25px",
              color: "#FF9933",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={(e) => {
              setModalOpen(false);
            }}
          />
        </Header>
        <Body>
          {collections.length > 0 && <SubTitle>Selecciona una Lista:</SubTitle>}
          <List>
            {collections?.map((collection, key) => (
              <ListItem
                key={key}
                onClick={(e) => handleFavClick(collection.id)}
              >
                {collection.collection_name}
              </ListItem>
            ))}
          </List>
          <AddContainer>
            <Input
              onChange={handleChange}
              placeholder="Nueva Lista de favoritos.."
            />
            <AddButton onClick={handleAddFav}>Agregar</AddButton>
          </AddContainer>
        </Body>
      </Modal>
    </Overlay>
  );
};

export default FavsModal;
