import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const addSearch = createAsyncThunk(
  "searches/addSearch",
    async ({ searchTerm, userId }, thunkAPI) => {
    try {
        const response = await axios.post(`${SERVER_URL}/searches/`, {
            userId: userId,
            searchTerm: searchTerm,
        });
        const newSearch = response.data; 
        return newSearch; 
    } catch (error) {
        console.log(error);
    }
  }
);

export const searchesSlice = createSlice({
  name: "searches",
  initialState: {
    isLoading: false,
    hasError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
      builder.addCase(addSearch.pending, (state) => {
          state.isLoading = true;
          state.hasError = false;
      });
      builder.addCase(addSearch.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
      });
      builder.addCase(addSearch.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      });
  },
});

export default searchesSlice.reducer;
