import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

/* Obtener las categorias que tienen cuentas */
export const getCategoriesWithAccounts = createAsyncThunk(
  "igAccountsCategories/getCategoriesWithAccounts",
  async (arg, thunkAPI) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/api/instagram-accounts-categories`
      );
      const categoriesWithAccounts = response.data;
      return categoriesWithAccounts;
    } catch (error) {
      console.log(error);
    }
  }
);

/* Obtener cuentas de IG por id de categoría*/
export const getAccountsByCategoryId = createAsyncThunk(
  "igAccountsCategories/getAccountsByCategoryId",
  async ({ categoryId, keywords }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/api/instagram-accounts-categories/categories/id/${categoryId}?keywords=${keywords}`
      );
      const categoryAccounts = response.data;
      return categoryAccounts;
    } catch (error) {
      console.log(error);
    }
  }
);

/* Obtener cuentas de IG por nombre de categoria */
export const getAccountsByCategoryName = createAsyncThunk(
  "igAccountsCategories/getAccountsByCategoryName",
  async ({ categoryName, keywords }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/api/instagram-accounts-categories/categories/name/${categoryName}?keywords=${keywords}`
      );
      
      const categoryAccounts = response.data;
      return categoryAccounts;
    } catch (error) {
      console.log(error);
    }
  }
);

// Obtener categorías de una cuenta de IG
export const getIgAccountCategories = createAsyncThunk(
  "igAccountsCategories/getIgAccountCategories",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/api/instagram-accounts-categories/instagram-account/${id}`
      );
      const IgAccountCategories = response.data;
      return IgAccountCategories;
    } catch (error) {
      console.log(error);
    }
  }
);

/* Agregar una cuenta a una categoria */
export const addIgAccountCategory = createAsyncThunk(
  "igAccountsCategories/addIgAccountCategory",
  async ({ instagram_account_id, category_id }, thunkAPI) => {
    console.log("instagram_account_id", instagram_account_id);
    console.log("category_id", category_id);
    try {
      const response = await axios.post(
        `${SERVER_URL}/api/instagram-accounts-categories/add-instagram-account-category`,
        {
          instagram_account_id,
          category_id,
        }
      );
      const newIgAccountCategory = response.data;
      await thunkAPI.dispatch(
        getIgAccountCategories({ id: instagram_account_id })
      );
      return newIgAccountCategory;
    } catch (error) {
      console.log(`error on addCategoryIgAccount`, error);
    }
  }
);

/* Eliminar una cuenta de una categoria */
export const deleteIgAccountCategory = createAsyncThunk(
  "igAccountsCategories/deleteIgAccountCategory",
  async ({ instagramAccountId, categoryId }, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/api/instagram-accounts-categories/instagram-account/${instagramAccountId}/category/${categoryId}`
      );
      const deletedAccountCategory = response.data;
      await thunkAPI.dispatch(
        getIgAccountCategories({ id: instagramAccountId })
      );
      return deletedAccountCategory;
    } catch (error) {
      console.log("error in (deleteIgAccountCategory)", error);
    }
  }
);

export const igAccountsCategoriesSlice = createSlice({
  name: "igAccountsCategories",
  initialState: {
    isLoading: false,
    hasError: false,
    categoryAccounts: [],
    categoriesWithAccounts: [],
    igAccountCategories: [],
    newIgAccountCategory: null,
  },
  reducers: {
    emptyCategoryAccounts: (state, action) => {
      state.categoryAccounts = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteIgAccountCategory.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(deleteIgAccountCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const deletedAccountCategory = action.payload;
      })
      .addCase(deleteIgAccountCategory.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(getIgAccountCategories.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getIgAccountCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const igAccountCategories = action.payload;
        state.igAccountCategories = igAccountCategories;
      })
      .addCase(getIgAccountCategories.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(addIgAccountCategory.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(addIgAccountCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const newIgAccountCategory = action.payload;
      })
      .addCase(addIgAccountCategory.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(getCategoriesWithAccounts.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getCategoriesWithAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const categoriesWithAccounts = action.payload;
        state.categoriesWithAccounts = categoriesWithAccounts;
      })
      .addCase(getCategoriesWithAccounts.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(getAccountsByCategoryId.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getAccountsByCategoryId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const categoryAccounts = action.payload;
        state.categoryAccounts = categoryAccounts;
      })
      .addCase(getAccountsByCategoryId.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(getAccountsByCategoryName.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getAccountsByCategoryName.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const categoryAccounts = action.payload;
        state.categoryAccounts = categoryAccounts;
      })
      .addCase(getAccountsByCategoryName.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      });
  },
});

export const { emptyCategoryAccounts } = igAccountsCategoriesSlice.actions;

/* selects */
export const select_ig_account_categories = (state) =>
  state.igAccountsCategories.igAccountCategories;
export const select_categories_with_accounts = (state) =>
  state.igAccountsCategories.categoriesWithAccounts;
export const select_category_accounts = (state) =>
  state.igAccountsCategories.categoryAccounts;

export default igAccountsCategoriesSlice.reducer;
