/* React Redux */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Isologo } from "../../assets/isologo.svg";
/* React Bootstrap */
import { Container, Row, Col, Form, Button } from "react-bootstrap";

import "./Login.css";
/* state */
import { localAuthenticateUser } from "../../features/users/usersSlice";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../App";
import { Spinner } from "react-bootstrap";
import ResetPasswordModal from "../../features/modals/ResetPasswordModal";

const LoginPage = () => {
  const isValidUser = useSelector((state) => state.users.isValidUser);
  const [emailValidFormat, setEmailValidFormat] = useState(true);
  const [isEmail, setIsEmail] = useState(true);
  const [isPassword, setIsPassword] = useState(true);
  //Mientras se consulta en la base de datos por el usuario. IsLoading = tue para que se muestre el Spinner
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [openModal, setOpenModal] = useState(false);
  //const user = JSON.parse(localStorage.getItem('user'))

  /*  Obtenemos al usuario a partir del contexto */
  const { user } = React.useContext(AuthContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Al recibir usuario navegar a home
  useEffect(() => {
    if (user) {
      setIsLoading(false);
      navigate("/");
    }
  }, [user]);

  //Si no se valido correctamente el usuario, entonces isLoading=false
  useEffect(() => {
    if (!isValidUser) {
      setIsLoading(false);
    }
  }, [isValidUser]);

  //Validar formato de email ingresado y que no esté vacío.
  const validateEmail = () => {
    if (email) {
      //Patrón deseado
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = regexEmail.test(email);
      setEmailValidFormat(isValid);
      setIsEmail(true);
    } else {
      setIsEmail(false);
      setEmailValidFormat(true);
    }
  };

  //validar que el campo contraseña no este vacío
  const validatePassword = () => {
    if (password) {
      setIsPassword(true);
    } else {
      setIsPassword(false);
    }
  };

  /* Click en iniciar sesión para autenticar al usuario */
  const handleOnClick = async (e) => {
    e.preventDefault();
    try {
      if (email && password && emailValidFormat) {
        setIsLoading(true);
        await dispatch(localAuthenticateUser({ email, password }));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Container fluid >
      <Row>
        <Col md={5} xs={12} className="full-height login-form-col">
          <Row className="isologo-row">
            <Col className="isologo-col">
              <Isologo className="isologo" />
            </Col>
          </Row>
          <Row>
            <Col className="flex flex-col p-12">
              <Form className="flex flex-col gap-2">
                <h1 className="title">Iniciar sesión</h1>
                <Form.Group controlId="formEmail">
                  <Form.Control
                    className="form-input mb-0"
                    type="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={validateEmail}
                  />
                </Form.Group>
                {!emailValidFormat && (
                  <span className="form-alert">
                    El formato de email no es válido.
                  </span>
                )}
                {!isEmail && (
                  <span className="form-alert">Ingresa tú email.</span>
                )}
                <Form.Group controlId="formPassword">
                  <Form.Control
                    className="form-input mb-0"
                    type="password"
                    placeholder="Contraseña"
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={validatePassword}
                  />
                </Form.Group>
                {!isPassword && (
                  <span className="form-alert">Ingresa tu contraseña</span>
                )}
                <Button
                  className="py-3 bg-[#66CC33] border-none hover:bg-[#FF9933]"
                  variant="primary"
                  type="submit"
                  onClick={handleOnClick}
                >
                  {isLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Iniciar Sesión"
                  )}
                </Button>
                {!isValidUser && (
                  <span className="text-xs text-[#ee2f35] font-bold">
                    Revisa tu email o contraseña.
                  </span>
                )}
                <span
                  className="mt-1 text-center text-[13px] text-[#0066CC] font-bold cursor-pointer"
                  onClick={() => setOpenModal(true)}
                >
                  ¿Olvidaste tu contraseña?
                </span>
                <div className="separator"></div>
                <p>
                  ¿Aún no te has registrado?{" "}
                  <span>
                    <a className="register-link" href="/signup">
                      Registrarte
                    </a>
                  </span>
                </p>
              </Form>
            </Col>
          </Row>
        </Col>
        <Col md={7} xs={12} className="login-col login-image">
          <div className="image-container"></div>
        </Col>
      </Row>
      {openModal && <ResetPasswordModal setOpenModal={setOpenModal} />}
    </Container>
  );
};

export default LoginPage;
