import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// GET FAVORITE ITEMS
export const getFavoriteItems = createAsyncThunk(
  "favoriteItems/getFavoriteItems",
  async (arg, thunkAPI) => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/favorites/items`);
      return response.data;
    } catch (error) {
      console.log(`error en getCollections thunk`, error);
    }
  }
);

// ADD FAVORITE ITEM
export const addFavoriteItem = createAsyncThunk(
  "favoriteItems/addFavoriteItem",
  async (favPost, thunk) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/api/favorites/items`,
        favPost
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

// PUT FAVORITE ITEM
export const updateFavoriteItem = createAsyncThunk(
  "favoriteItems/updateFavoriteItem",
  async ({ itemId }, thunkAPI) => {
    try {
      const response = await axios.put(
        `${SERVER_URL}/api/favorites/items/${itemId}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

//DELETE FAVORITE ITEM
export const deleteFavoriteItem = createAsyncThunk(
  "favoriteItems/deleteFavoriteItem",
  async ({ itemId }, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/api/favorites/items/${itemId}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);


//SLICE
export const favoriteItemsSlice = createSlice({
  name: "favoriteItems",
  initialState: {
    favoriteItems: [],
    lastAddedItem: {},
    isLoading: false,
    hasError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //GET
      .addCase(getFavoriteItems.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getFavoriteItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.favoriteItems = action.payload;
      })
      .addCase(getFavoriteItems.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      //ADD
      .addCase(addFavoriteItem.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        console.log("addFavoriteItem pending");
      })
      .addCase(addFavoriteItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.favoriteItems.push(action.payload);
        console.log("addFavoriteItem fulfilled");
      })
      .addCase(addFavoriteItem.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
        console.log("addFavoriteItem rejected");
      })
      //UPDATE
      .addCase(updateFavoriteItem.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(updateFavoriteItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const index = current(state).favoriteItems.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.favoriteItems[index] = action.payload;
        }
      })
      .addCase(updateFavoriteItem.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      //DELETE
      .addCase(deleteFavoriteItem.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(deleteFavoriteItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.favoriteItems = state.favoriteItems.filter(
          (item) => item.id !== action.payload.id
        );
      })
      .addCase(deleteFavoriteItem.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      });
  },
});

export default favoriteItemsSlice.reducer;
