import React from "react";
import MobileFilters from "../mobileFilters/MobileFilters";


const MobileFiltersMenu = ({isMobileFilterOpen, setIsMobileFilterOpen, filterNames}) => {
  
  return (
    <div className="w-full flex xs:justify-center md:justify-normal pt-3 px-3 text-base">
      <MobileFilters
        isMobileFilterOpen={isMobileFilterOpen}
        setIsMobileFilterOpen={setIsMobileFilterOpen}
        filterNames={filterNames}
      />
    </div>
  );
};

export default MobileFiltersMenu;
