import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../App";
/* components */
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import AdminHeader from "../AdminHeader/AdminHeader";
import AdminFooter from "../AdminFooter/AdminFooter";
/* state */
import { retrieveAllUsers } from "../users/usersSlice";
import { retrieveAllIgAccounts } from "../slices/instagamAccountsSlice";
/* css */
import './adminLayout.scss';
import { getCitiesIgAccounts } from "../slices/citiesIgAccoutsSlice";
import { getCities } from "../slices/citiesSlice";

const AdminLayout = () => {
  const { user } = React.useContext(AuthContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveAllUsers());
    dispatch(getCitiesIgAccounts());
    dispatch(getCities());
  })


  return user && user.role === "admin" ? (
    <div className="admin-main">
      <AdminHeader />
      <div className="admin-container">
        <div className="admin-sidebar-container">
          <AdminSidebar />
        </div>
        <div className="admin-content-container">
          <Outlet />
        </div>
      </div>
      <AdminFooter />
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default AdminLayout;
