export const stopWords = [
  "con",
  "cómo",
  "nuestras",
  "para",
  "y",
  "el",
  "en",
  "a",
  "e",
  "i",
  "o",
  "u",
  "yo",
  "tú",
  "él",
  "ella",
  "nosotros",
  "nosotras",
  "vosotros",
  "vosotras",
  "ellos",
  "ellas",
  "un",
  "una",
  "unos",
  "unas",
  "la",
  "las",
  "lo",
  "los",
  "de",
  "del",
  "al",
  "por",
  "como",
  "sobre",
  "tras",
  "entre",
  "sin",
  "pero",
  "ni",
  "que",
  "si",
  "cuando",
  "porque",
  "pues",
  "ya",
  "aunque",
  "mientras",
  "muy",
  "más",
  "menos",
  "bien",
  "mal",
  "antes",
  "después",
  "nunca",
  "siempre",
  "ahora",
  "quizás",
  "cero",
  "uno",
  "dos",
  "tres",
  "cuatro",
  "cinco",
  "seis",
  "siete",
  "ocho",
  "nueve",
  "diez",
  "bueno",
  "mismo",
  "cosa",
  "solo",
  "todo",
  "nada",
  "mucho",
  "poco",
  "varios",
  "cualquier",
  "contigo",
  "estas",
  "podras",
  "ganar",
  "ti",
  "ese",
  "tienes",
  "cada",
  "su",
  "estar",
  "nuestra",
  "registrado",
  "tu",
  "no",
  "esta",
  "vas",
  "algo",
  "tus",
  "este",
  "ser",
  "donde",
  "esten",
  "debes",
  "seran",
  "mas",
  "tener",
  "te",
  "ayudara",
  "cuidar",
  "crear",
  "animate",
  "aprovecha",
  "nuestro",
  "participar",
  "sabes",
  "conocer",
  "enviale",
  "sabias",
  "tiene",
  "hacer",
  "gusta",
  "gran",
  "se",
  "nos",
  "asi",
  "hasta",
  "hace",
  "usar",
  "tuyo",
  "hazlo",
  "ven",
  "es",
  "lleva",
  "son",
  "aqui",
  "via",
  "tomale",
  "desde",
  "partir",
  "encuentralo",
  "prepara",
  "sku",
  "etc",
  "suerte",
  "facilitamos",
  "mejor",
  "incluido",
  "eso",
  "hacen",
  "llevarte",
  "merece",
  "mejores",
  "quieres",
  "super",
  "hi",
  "cual",
  "es",
  "incluye",
  "conoce",
  "sus",
  "anota",
  "gol",
  "vivir",
  "todas",
  "perder",
  "comenta",
  "toma",
  "siente",
  "visitanos",
  "parar",
  "dale",
  "llevala",
  "vive",
  "ves",
  "recorrio",
  "toda",
  "queremos",
  "conocerlos",
  "lleguen",
  "viaja",
  "elige",
  "preparate",
  "nivel",
  "nacional",
  "permitira",
  "cantidad",
  "almacenar",
  "estado",
  "mayor",
  "conserva",
  "comience",
  "somos",
  "vayas",
  "toque",
  "junto",
  "haran",
  "encuentralos",
  "trae",
  "traves",
  "digo",
  "hara",
  "must",
  "have",
  "consigue",
  "tiendas",
  "spot",
  "repleto",
  "cuéntanos",
  "sabemos",
  "elegir",
  "visite",
  "tienda",
  "ubicada",
  "tienen",
  "todos",
  "pude",
  "irme",
  "evitar",
  "probarme",
  "algunos",
  "supuesto",
  "lleve",
  "deslumbraron",
  "podíamos",
  "dejarlo",
  "compartimos",
  "ustedes",
  "saber",
  "gustado",
  "conoces",
  "puedes",
  "conseguir",
  "tan",
  "requieres",
  "dudes",
  "escribirnos",
  "mostramos",
  "les",
  "nuestros",
  "añadirlas",
  "estara",
  "gustaría",
  "salen",
  "primeros",
  "sera",
  "estarán",
  "terminar",
  "darles",
  "encuentran",
  "especialmente",
  "esperamos",
  "estamos",
  "tambien",
  "tenemos",
  "disponibles",
  "corre",
  "manten",
  "agoteeee",
  "adquiere",
  "realiza",
  "pedidos",
  "dura",
  "ideal",
  "ingresa",
  "refrescante",
  "ofrecer",
  "llenate",
  "llenar",
  "preferirnos",
  "apoyarnos",
  "crecer",
  "trabajar",
  "regala",
  "quedes",
  "elegancia",
  "realzar",
  "cambia",
  "sumergirte",
  "sumergiendo",
  "deleitaran",
  "enviarles",
  "sentirte",
  "obtener",
  "descansa",
  "manten",
  "cuidala",
  "protege",
  "manchas",
  "eleva",
  "prioriza",
  "mejora",
  "enviarles",
  "enviamos",
  "agradecidos",
  "brindamos",
  "enviar",
  "enviarles",
  "esperamos",
  "quedes",
  "aprovecha",
  "aprovechar",
  "dar",
  "enseñar",
  "creemos",
  "llenar",
  "realizar",
  "optas",
  "llenalo",
  "depositalo",
  "estaras",
  "participando",
  "realizara",
  "entregara",
  "aprobada",
  "abrazamos",
  "celebra",
  "abrazar",
  "sentir",
  "afecto",
  "consuelo",
  "aumentar",
  "comentanos",
  "encontras",
  "solicitar",
  "escoger",
  "contamos",
  "surte",
  "ofrecemos",
  "ayudarte",
  "mejorar",
  "acerca",
  "escribenos",
  "respondemos",
  "inicia",
  "lleno",
  "mordida",
  "deleitaran",
  "disfrutar",
  "celebrar",
  "sentirnos",
  "enviarles",
  "enviamos",
  "apoyo",
];
