import React, { useEffect, useState } from "react";
/* React Router */
import { BrowserRouter, Routes, Route } from "react-router-dom";
/* state */
import { select_user } from "./features/users/usersSlice";
import {} from "./features/slices/categoriesSlice";
import { useSelector, useDispatch } from "react-redux";
import { select_accounts_index } from "./features/postTracker/igFeedSlice";
/* Components */
import IgAccountsPage from "./pages/AdminPages/IgAccountsPage/IgAccountsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
import SingleUserPage from "./pages/AdminPages/SingleUserPage/SingleUserPage";
import SearchResultsPage from "./pages/SearchResultsPage/SearchResultsPage";
import ProtectedLayout from "./features/protectedLayout/ProtectedLayout";
import KeywordsPage from "./pages/AdminPages/KeywordsPage/KeywordsPage";
import SubCategoriesPage from "./pages/SubCategoriesPage/SubCategoriesPage";
import AdminHome from "./pages/AdminPages/AdminHome/AdminHome";
import UsersPage from "./pages/AdminPages/UsersPage/UsersPage";
import SingleIgAccountPage from "./pages/AdminPages/SingleIgAccountPage/SingleIgAccountPage";
import AdminCategoriesPage from "./pages/AdminPages/AdminCategoriesPage/AdminCategoriesPage";
import SingleCategoryPage from "./pages/AdminPages/SingleCategoryPage/SingleCategoryPage";
import AdminLayout from "./features/AdminLayout/AdminLayout";
import AuthLayout from "./features/AuthLayout/AuthLayout";
import SignupPage from "./pages/SignupPage/SignupPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import HomePage from "./pages/HomePage/HomePage";
import SingleLocationPage from "./pages/AdminPages/SingleLocationPage/SingleLocationPage";
import "./styles/global.scss";
import LocationsPage from "./pages/AdminPages/LocationsPage/LocationsPage";
import FavoritePage from "./pages/favoritesPage/FavoritePage";
import Newsletter from "./pages/NewsletterPage/Newsletter";
import CategoryPage from "./pages/CategoryPage/CategoryPage";
import BusinessProfilePage from "./pages/BusinessProfilePage/BusinessProfilePage";
import "./App.css";
import AccountPage from "./pages/AccountPage/AccountPage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";
import CategoriesPage from "./pages/CategoriesPage/CategoriesPage";

export const AuthContext = React.createContext();

const App = () => {
  const redux_user = useSelector(select_user);

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const contextValues = {
    user,
    setUser,
  };

  useEffect(() => {  
    if (redux_user) {
      localStorage.setItem("user", JSON.stringify(redux_user));
      setUser(JSON.parse(localStorage.getItem("user")));
    }
  }, [redux_user]);

  return (
    <AuthContext.Provider value={contextValues}>
      <BrowserRouter>
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/newsletter" element={<Newsletter />} />
          <Route element={<AuthLayout />}>
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/signin" element={<LoginPage />} />
            <Route
              path="auth/reset-password/:token"
              element={<ResetPasswordPage />}
            />
          </Route>
          <Route element={<ProtectedLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/search" element={<SearchResultsPage />} />
            <Route path="/favorites" element={<FavoritePage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/categories" element={<CategoriesPage />} />
            <Route
              path="categories/id/:parentId"
              element={
                <SubCategoriesPage
                  title="Restaurantes"
                  subtitle="(por tipo de cocina)"
                />
              }
            />
            <Route
              path="business/:accountId"
              element={<BusinessProfilePage />}
            />
            <Route path="categories/:categoryName" element={<CategoryPage />} />
          </Route>
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="admin" element={<AdminHome />} />
            <Route path="instagram-accounts" element={<IgAccountsPage />} />
            <Route path="locations" element={<LocationsPage />} />
            <Route path="locations/:id" element={<SingleLocationPage />} />
            <Route path="keywords" element={<KeywordsPage />} />
            <Route path="categories" element={<AdminCategoriesPage />} />
            <Route path="users" element={<UsersPage />} />
            <Route path="users/:id" element={<SingleUserPage />} />
            <Route path="categories/:id" element={<SingleCategoryPage />} />
            <Route
              path="instagram-accounts/:id"
              element={<SingleIgAccountPage />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  );
};

export default App;
