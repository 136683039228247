import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const addFeedback = createAsyncThunk(
  "feedbacks/addFeedback",
  async ({ feedbackText, userId }, thunkAPI) => {
    try {
      console.log(feedbackText);
      console.log(userId);
      const response = axios.post(`${SERVER_URL}/feedbacks/`, {
        feedbackText: feedbackText,
        userId: userId,
      });
      const newFeedback = response.data;
      return newFeedback;
    } catch (error) {
      console.log("error al agregar feedback a la bd");
    }
  }
);

export const feedbacksSlice = createSlice({
  name: "feedbacks",
  initialState: {
    isLoading: false,
    hasError: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addFeedback.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(addFeedback.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(addFeedback.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      });
  },
});

export default feedbacksSlice.reducer;
