import React, { useState } from "react";
import { useDispatch} from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import AccountsFilter from "../accountsFilter/AccountsFilter";
import CategoryFilter from "../categoryFilter/CategoryFilter";
import DateFilter from "../dateFilter/DateFilter";
import { applyActiveFilters, resetFilters } from "../../postTracker/igFeedSlice";

const SidebarFilters = ({ isSidebarOpen, setIsSidebarOpen }) => {
  
  /* LOCAL STATES */
  const [activeFilters, setActiveFilters] = useState({});
  
  /* DISPATCH */
  const dispatch = useDispatch();

  /* FUNCTIONS */
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const applyFilters = () => {
    dispatch(applyActiveFilters(activeFilters))
  }

  const resetCurrentFilters = () => {
    dispatch(resetFilters());
  };

  return (
    /* Sidebar Container */
    <div
      className={`h-full relative top-0 left-0 md:flex md:flex-col bg-[#efefef] ${
        isSidebarOpen ? "w-[100%]" : "md:hidden w-0"
      }`}
    >
      {/* HEADER */}
      <div className="md:h-[10%] flex flex-initial justify-between items-center px-4 py-2 ">
        <h2 className="text-lg font-bold">Filtros</h2>
        <button onClick={toggleSidebar} className="text-gray-600">
          <CloseIcon />
        </button>
      </div>

      {/* BODY */}
      <div className="h-[70%] flex flex-col flex-1 gap-4 px-4 ">
        <div className="dateFilter ">
          <div className="filterBody">
            <DateFilter
              setActiveFilters={setActiveFilters}
              activeFilters={activeFilters}
            />
          </div>
        </div>
        <div className="categoryFilter h-[35%] flex flex-col gap-2">
          <div className="filterHeader py-2 border-bottom !border-[#000066]">
            <h2 className="font-bold">Categorias</h2>
          </div>
          <div className="filterBody overflow-y-scroll">
            <CategoryFilter
              setActiveFilters={setActiveFilters}
              activeFilters={activeFilters}
            />
          </div>
        </div>
        <div className="accountsFilter h-[55%] py-4 flex flex-col gap-4">
          <div className="filterHeader py-2 border-bottom !border-[#000066]">
            <h2 className="font-bold">Cuentas</h2>
          </div>
          <div className="filterBody  overflow-y-scroll">
            <AccountsFilter
              setActiveFilters={setActiveFilters}
              activeFilters={activeFilters}
            />
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <div className="h-[10%] flex items-center justify-center gap-2">
        <div className="resetFiltersButton">
          <button
            className="bg-[#66CC33] hover:bg-[#FFB366] hover:text-white
 py-2.5 px-11 rounded-md font-bold"
            onClick={resetCurrentFilters}
          >
            Borrar Todos
          </button>
        </div>
        <div className="applyFiltersButton">
          <button
            className="bg-[#FF9933] hover:bg-[#FFB366] hover:text-white
 py-2.5 px-11 rounded-md font-bold"
            onClick={applyFilters}
          >
            Aplicar Filtros
          </button>
        </div>
      </div>
    </div>
  );
};

export default SidebarFilters;
