import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux"
import { useParams } from "react-router-dom";
import './singleUserPage.scss';
import Single from "../../../features/single/Single";
import { getUserById, select_single_user, updateUser } from "../../../features/users/usersSlice";

const SingleUserPage = (props) => {
  const reduxSingleUser = useSelector(select_single_user);
  const [singleUser, setSingleUser] = useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  
  useEffect(() => {
    dispatch(getUserById({id}))
  }, [])

   useEffect(() => {
     if (reduxSingleUser) {
       setSingleUser({
         firstname: reduxSingleUser.firstname,
         lastname: reduxSingleUser.lastname,
         phone: reduxSingleUser.phone,
         email: reduxSingleUser.email,
         role: reduxSingleUser.role,
       });
     }
   }, [reduxSingleUser]);

  const updateItem = (item) => {
    dispatch(
      updateUser({
        id: id,
        firstname: item['firstname'],
        lastname: item['lastname'],
        phone: item['phone'],
        email: item['email'],
        role: item['role'],
      })
    );
  };

  return (
    <div className="single-user">
      <Single item={singleUser} slug="Usuario" updateItem={updateItem} />
    </div>
  );
};

export default SingleUserPage;
