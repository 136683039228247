import React from "react"

const KeywordsPage = (props) => {
  return (
    <div>
      KeywordsPage
    </div>
  )
};

export default KeywordsPage;
