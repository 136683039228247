import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import styled from "styled-components";
import Post from "../media/feedPost/Post";
import { getFavorites } from "../slices/favoritesSlice";

const Container = styled.div``;

const Wrapper = styled.div`
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
`;

const Card = styled.div`
  width: 80%;
`;

const Favorites = ({ collectionId }) => {
  let favorites = useSelector((state) => state.favorites.favorites);
  const dispatch = useDispatch();


  useEffect(() => {
    collectionId && dispatch(getFavorites(collectionId));
  }, [collectionId]);

  return (
    <Container> 
      <Wrapper>
        {favorites?.map((favPost, key) => (
          <Post
            key={key}
            index={key}
            post={favPost}
            isFavPost={true}
            collectionId={collectionId}
          />
        ))}
      </Wrapper>
    </Container>
  );
};

export default Favorites;
