export const favsList = [
  {
    id: 1,
    listName: "Cargo",
  },
  {
    id: 2,
    listName: "Camisas",
  },
  {
    id: 3,
    listName: "Vestidos",
  },
  {
    id: 4,
    listName: "Vestidos",
  },
  {
    id: 5,
    listName: "Vestidos",
  },
  {
    id: 6,
    listName: "Vestidos",
  },
  {
    id: 7,
    listName: "Vestidos",
  },
  {
    id: 8,
    listName: "Vestidos",
  },
  {
    id: 9,
    listName: "Vestidos",
  },
];

export const favPosts = [
  {
    listId: 1,
    listName: "Cargo",
    contentId: 1,
    media_url:
      "https://scontent.cdninstagram.com/v/t51.29350-15/358509418_808096247699409_2916552630791290683_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=-YoRdIds1BMAX-DlxA8&_nc_ht=scontent.cdninstagram.com&edm=AL-3X8kEAAAA&oh=00_AfD3aM3LizhcxINcDkWYdaUdRzTUuh9-aDjRSlmmKAjFRg&oe=65203BDF",
    caption: "Cargo satinado ✨✨ nicole_pret_a_porter",
    timestamp: "2023-07-07T00:20:50+0000",
    permalink: "https://www.instagram.com/p/CuYDX0LO4xP/",
    mediaProductType: "FEED",
    mediaType: "CAROUSEL_ALBUM",
    igAccountId: 290,
    ig_account: "nicole_pret_a_porter",
  },
  {
    listId: 1,
    listName: "Cargo",
    contentId: 2,
    media_url:
      "https://scontent.cdninstagram.com/o1/v/t16/f1/m82/754688CCA3FAF217F34E2AF9BE20D98C_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLmNsaXBzLnVua25vd24tQzMuNzIwLmRhc2hfYmFzZWxpbmVfMV92MSJ9&_nc_ht=scontent.cdninstagram.com&_nc_cat=100&vs=693558618821432_3783620807&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC83NTQ2ODhDQ0EzRkFGMjE3RjM0RTJBRjlCRTIwRDk4Q192aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0VTZWZSYUQ2SGtmQkVBQ0FBa3NUU2ZGcE9zb2JxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmoo3ohOfy8T8VAigCQzMsF0A1keuFHrhSGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfCnlS8x9XB0WcXqvkDdmiMgJ9yIAOUCjK8k6xICJZCpEg&oe=651CEE6D&_nc_sid=1d576d&_nc_rid=6aa7e33d7e",
    caption:
      "Los Jogger estilo cargo están en tendencia y este de la marca Most Wanted está bellísimo🔝🔝🔝 Ya tienes uno???🙋‍♀️😍 ▪️",
    timestamp: "2023-09-12T02:27:04+0000",
    permalink: "https://www.instagram.com/reel/CxEyvE7N2py/",
    mediaProductType: "REELS",
    mediaType: "VIDEO",
    igAccountId: "11111",
    ig_account: "marvic_style",
  },
  {
    listId: 3,
    listName: "Vestidos",
    contentId: 3,
    media_url:
      "https://scontent-bog1-1.cdninstagram.com/v/t51.29350-15/386402834_674393917988105_453887527315823375_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=Cb29k3KyWUkAX_-999y&_nc_ht=scontent-bog1-1.cdninstagram.com&edm=AL-3X8kEAAAA&oh=00_AfCwHCTNw46qkm8qbB5q2NrZC2qb6xCvxP5WPFU16kuATg&oe=652274FF",
    caption:
      "Con el último trimestre del año llegan las fiestas! Bodas, aniversarios, fiestas de fin de año y mas! Luce hermosa con uno de estos vestidos! Desliza y escoge el tuyo!  Descubre nuestra colección de vestidos de flecos, plumas, tul, cristales y transparencias diseñados para hacerte brillar en cada ocasión especial✨",
    timestamp: "2023-10-03T19:34:25+0000",
    permalink: "https://www.instagram.com/p/Cx8tV7cuRo_/",
    mediaProductType: "",
    mediaType: "FEED",
    igAccountId: 1234,
    ig_account: "glammeans",
  },
  {
    listId: 3,
    listName: "Vestidos",
    contentId: 4,
    media_url:
      "https://scontent-bog1-1.cdninstagram.com/v/t51.29350-15/382793393_332922972450778_1936589565697294312_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=VbTmQq35pR8AX_JfhwO&_nc_ht=scontent-bog1-1.cdninstagram.com&edm=AL-3X8kEAAAA&oh=00_AfCYXSxUzO8KX1ihP1jDrxXI5oBY1iTkhrMCWsNW_2UXjQ&oe=6521F53D",
    caption: "Estos vestidos se distinguen de los demás…. Espectaculares ",
    timestamp: "2023-09-28T19:55:16+0000",
    permalink: "https://www.instagram.com/reel/Cxv3ndEuJPd/",
    mediaProductType: "REELS",
    mediaType: "FEED",
    igAccountId: 1234,
    ig_account: "glammeans",
  },
  {
    listId: 2,
    listName: "Camisas",
    contentId: 567,
    media_url:
      "https://scontent-bog1-1.cdninstagram.com/v/t51.29350-15/385551626_1214857603237845_6288068036135663515_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=IJ1kIX2Fjq8AX8ps_yw&_nc_ht=scontent-bog1-1.cdninstagram.com&edm=AL-3X8kEAAAA&oh=00_AfDRwTanGaezTKxj0O2AguM3xixUTY5Rb3_JbXr1cE6wQw&oe=65225FF9",
    caption: "Comenzamos el Mes Rosa💓 ",
    timestamp: "2023-10-03T16:22:27+0000",
    permalink: "https://www.instagram.com/p/Cx8XX64uqq6/",
    mediaProductType: "FEED",
    mediaType: "FEED",
    igAccountId: 888,
    ig_account: "exotikve",
  },
];

export const keywords = [
  "promo",
  "promos",
  "PROMO",
  "rebaja",
  "rebajas",
  "oferta",
  "ofertas",
  "ofertazo",
  "descuento",
  "descuentos",
  "promocion",
  "promociones",
  "2x1",
  "combo",
  "3x2",
];

/*  
"premio",
  "promo",
  "oferta",
  "ofertazo",
  "descuento",
  "descuentos",
  "promocion",
  "promociones",
  "sorteo",
  "2x1",
  "combo",
  "concurso",
  "special",
  "gratis",
  nuevo",
  "novedad",
  "agenda", 
  show*/

export const featuredDealsIds = [
  7669776, 7669736, 7670370, 7670376, 7670111, 7670122, 7669406, 7669421,
];

export const fashionDeals = [
  7665579, 7665585, 7670904, 7671119, 7667580, 7671092, 7630632, 7671056,
  7663556, 7663566, 7665578, 7652382,
];

export const restaurantDeals = [7672086, 7672137, 7671969, 7671955];

export const techDeals = [
  7671861, 7672059, 7672027, 7672116, 7672097, 7671988, 7671759, 7671761,
];

export const entertaimentDeals = [
  7671179, 7671194, 7671045, 7670985, 7670818, 7671953, 7671926
];

export const supermarketDeals = [
  7672215, 7672246, 7672258, 7672191, 7672204, 7672216, 7672270, 7672247,
  7672166
];

export const healthDeals = [7671334, 7670384, 7671323, 7671389];

export const promoPosts = [
  {
    title: "Combo Mi Villano Favorito + 1 refill de cotufas",
    business: "Cines Unidos",
    media_url:
      "https://firebasestorage.googleapis.com/v0/b/kontevo-88b07.appspot.com/o/combo%20mi%20villano%20favorito%20(1).png?alt=media&token=cda9ce45-7368-46b3-a0f0-96a6d714d476",
    business_id: 2096,
  },
  {
    title: "20% de descuento los martes y jueves en frutas y verduras",
    business: "Que Papaya",
    media_url:
      "https://firebasestorage.googleapis.com/v0/b/kontevo-88b07.appspot.com/o/quepapaya-fruver.png?alt=media&token=37519c49-7e09-4c35-9294-61da88c5c4c0",
    business_id: 2596,
  },
  {
    title: "100 productos con precios de pana",
    business: "Venelectronics",
    media_url:
      "https://firebasestorage.googleapis.com/v0/b/kontevo-88b07.appspot.com/o/455926550_500045132884127_8611832515022512899_n.jpeg?alt=media&token=42ad0d2d-99d7-4001-b3b0-ab196b5d5eae",
    business_id: 89,
  },
  {
    title: "20% de descuento a partir de 2 escaladores",
    business: "Suniland Extreme Park",
    media_url:
      "https://firebasestorage.googleapis.com/v0/b/kontevo-88b07.appspot.com/o/454501480_1109449560536496_4081598419882751958_n.jpeg?alt=media&token=59500afd-9f2a-456d-97d3-acd48540516a",
    business_id: 2720,
  },
];

export const parentCategories = [
  {
    name: "Restaurantes",
    media_url:
      "https://firebasestorage.googleapis.com/v0/b/kontevo-88b07.appspot.com/o/restaurantes.png?alt=media&token=abfcf4e7-f007-4731-b873-b58204ccdcbf",
    id: 68,
    subtitle: "(por tipo de cocina)",
  },
  {
    name: "Entretenimiento",
    media_url:
      "https://firebasestorage.googleapis.com/v0/b/kontevo-88b07.appspot.com/o/entretenimiento.png?alt=media&token=75be37d1-5862-4027-85ce-1913dc83b67a",
    id: 93,
  },
  {
    name: "Salud",
    media_url:
      "https://firebasestorage.googleapis.com/v0/b/kontevo-88b07.appspot.com/o/salud.png?alt=media&token=7c11d2e4-120f-4333-ad48-9a54e3f13e13",
    id: 47,
  },
  {
    name: "Tecnología",
    media_url:
      "https://firebasestorage.googleapis.com/v0/b/kontevo-88b07.appspot.com/o/Electrodomesticos.png?alt=media&token=fc055764-ff7a-4235-99f8-6dceba4789dd",
    id: 76,
  },
  {
    name: "Supermercados y Bodegones",
    media_url:
      "https://firebasestorage.googleapis.com/v0/b/kontevo-88b07.appspot.com/o/Supermercado.png?alt=media&token=5d14ca51-4f1d-4866-b0a1-37dd5d2f4040",
    id: 84,
  },
  {
    name: "Moda",
    media_url:
      "https://firebasestorage.googleapis.com/v0/b/kontevo-88b07.appspot.com/o/ropa.png?alt=media&token=08b9d4ed-4f69-4555-96fe-3e35b7a94867",
    id: 160,
  },
  {
    name: "Turismo",
    media_url:
      "https://firebasestorage.googleapis.com/v0/b/kontevo-88b07.appspot.com/o/Turismo.png?alt=media&token=8aff37a6-4137-49f3-bcf3-c84c809a147b",
    id: 131,
  },
  {
    name: "Vehículos",
    media_url:
      "https://firebasestorage.googleapis.com/v0/b/kontevo-88b07.appspot.com/o/vehiculos.png?alt=media&token=fc0d51b3-3f46-4259-b011-a80dfd1470fd",
    id: 64,
  },
  {
    name: "Regalos",
    media_url:
      "https://firebasestorage.googleapis.com/v0/b/kontevo-88b07.appspot.com/o/Regalos.png?alt=media&token=a90e1d7d-2c79-4f4e-84b5-3c41831d9b86",
    id: 121,
  },
];
