import React from "react";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./connections.scss";

const Connections = ({
  selectorName,
  options,
  addButton,
  getListItems,
  listItems,
}) => {
  console.log("listItems", listItems);

  const handleSelectChange = (e) => {
    getListItems(e.target.value);
  };

  const handleOnClick = (e) => {
    
  }

  return (
    <div className="connections">
      <div className="menu">
        <select
          name={selectorName}
          id={selectorName}
          onChange={handleSelectChange}
        >
          {options.map((option, key) => {
            return (
              <option key={key} value={option.id}>
                {option.full_name}
              </option>
            );
          })}
        </select>
        <button onClick={handleOnClick}>{`Agregar cuenta a ${addButton}`}</button>
      </div>
      <div className="list-container">
        {listItems.length > 0 ? (
          <ul className="list">
            {listItems.map((item, index) => {
              return (
                <li className="item" key={index}>
                  <div>{item.ig_account_username}</div>
                  <div className="itemActions">
                    <FontAwesomeIcon icon={faPenToSquare} className="edit" />
                    <FontAwesomeIcon icon={faTrashCan} className="delete" />
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <h5 className="item" style={{ color: "white" }}>
            No hay cuentas
          </h5>
        )}
      </div>
    </div>
  );
};

export default Connections;
