import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MainMenu from "../header/MainMenu";
import { Outlet} from "react-router-dom";
/* state */
import { AuthContext } from "../../App";
import SignUpModal from "../modals/SignUpModal";
import { useNavigate } from "react-router-dom";
import BottomMenu from "../bottomMenu/BottomMenu";

const ProtectedLayout = () => {
  //const {user} = React.useContext(AuthContext);
  //const user = JSON.parse(localStorage.getItem("user"));
  const { user } = React.useContext(AuthContext);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 30000); // Mostrar pop-up después de 30 segundos

    return () => clearTimeout(timer); // Limpiar el temporizador cuando el componente se desmonte
  }, []);

  return (
    <div className="h-[100%]">
      <MainMenu />
      <div className="!h-[100%] bg-[#F8F8F8] pb-[20%]">
        <Outlet />
      </div>
      <BottomMenu />
      {showPopup && <SignUpModal setShowPopup={setShowPopup} />}
    </div>
  );
};

export default ProtectedLayout;
