import OpenAI from "openai";
import { stopWords } from "../features/postTracker/stopWords";
import { gptUserPromptMessages } from "./gptMessages";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

//initialize openAi API with key
const openai = new OpenAI({
  organization: "org-VU6N6WYUmM0J7gll0mO4sHUK",
  apiKey: process.env.REACT_APP_OPENAI_KEY,
  dangerouslyAllowBrowser: true, //allows the use of the api in the browser
});

// Step 2: This messages are used to train the gpt with examples of the input to expect and the output we desire to get

//step 4: Evaluate the context of each post copy and the relation it has with the user search query using the openai api.

////////////

//STEP 1: GET ALL CATEGORIES
const getCategories = async () => {
  try {
    const response = await axios.get(
      `${SERVER_URL}/categories?columns=id,name,keywords`
    );
    const categories = response.data;
    return categories;
  } catch (error) {
    console.log("error while getting all categories", error);
  }
};

//STEP 2: CLEAN USER PROMPT WITH AI
const cleanUserPromptWithGPT = async (userPrompt) => {
  gptUserPromptMessages.push({ role: "user", content: userPrompt });
  const userPromptKwds = await gptApiRequest(gptUserPromptMessages);
  return userPromptKwds.split(",");
};

//STEP 3: FIND COMMON KEYWORDS BETWEEN CATEGORY AND USER PROMPT
const kwdsCategoryMatch = (userPrompt, categories, kwdsMatchCondition) => {
  let kwdsMatchedCats = [];
  let kwdsUnmatchedCats = [];
  categories.forEach((currentCat) => {
    const coincidences = searchAndMatch(
      userPrompt,
      currentCat.keywords.join(" "),
      kwdsMatchCondition
    );

    //la cuentas que no tienen coincidencias se agregan al array unmatchedCats para ser posteriormente evaluados por chatGpt.
    coincidences
      ? kwdsMatchedCats.push(currentCat)
      : kwdsUnmatchedCats.push(currentCat);
  });
  return { kwdsMatchedCats, kwdsUnmatchedCats };
};

//STEP 3.1
export const searchAndMatch = (userPrompt, postCaption, kwdsMatchCondition) => {
  if (postCaption) {
    // Paso 1: Eliminar hashtags de la cadena de texto
    const cleanedCaption = postCaption.replace(/#\w+/g, "");
    // Paso 2: Retorna true or false dependiendo de si se cumple la condición pasada como parametro.
    const isConditionMet = findKwdsInCaption(
      userPrompt,
      cleanedCaption,
      kwdsMatchCondition
    );
    return isConditionMet;
  } else {
    return false;
  }
};

//STEP 3.1.1 CLEAN USER PROMPT MANUALLY
const cleanUserPromptManually = (userPrompt) => {
  return userPrompt
    .toLowerCase()
    .split(" ")
    .filter((word) => !stopWords.includes(word))
    .join(" ");
};

//STEP 3.2: CHECK IF SPECIFIC KEYWORDS (IN userPromptWords ARRAY) ARE PRESENT IN cleanedCaption. CONDITION DECIDES IF ALL (every) OR AT LEAST ONE (some) SHOULD BE THERE
const findKwdsInCaption = (
  userPromptWords,
  cleanedCaption,
  kwdsMatchCondition
) => {
  //Emplear every para verificar todas las coincidencias de palabras clave
  if (kwdsMatchCondition === "every") {
    return userPromptWords.every((word) => cleanedCaption.includes(word));
  }
  //some para verificar al menos una
  else if (kwdsMatchCondition === "some") {
    return userPromptWords.some((word) => cleanedCaption.includes(word));
  }
};

//STEP 4: FIND CONTEXT RELATION BETWEEN CATEGORY NAME AND USER PROMPT
const gptCategoryMatch = async (userPrompt, kwdsUnmatchedCats) => {
  /*   const current = kwdsUnmatchedCats.filter(
    (category) => category.name === "Bienes Raices"
  );
  current.forEach(async (cat, index) => {
    let messages = [
      {
        role: "system",
        content: `Se te proporcionará la siguiente categoria: 
        nombre: ${cat.name}
        keywords: ${cat.keywords}
          
      Tu tarea es determinar si existe una relación entre la categoria y la busqueda del usuario. Si existe, responde 1, de lo contrario, responde 0.`,
      },
      {
        role: "user",
        content: `Busqueda del usuario: ${userPrompt}`,
      },
    ];
    const response = await openAiRequest(messages);
    console.log("response", response);
  }); */
  //console.log("this are the matched categories", matchedCategories);
};

//STEP 5: FIND COMMON KEYWORDS BETWEEN THE IG ACCOUNTS BELONGING TO A CATEGORY AND THE USER PROMPT
const kwdsAccountsMatch = async (
  userPrompt,
  kwdsMatchedCats,
  kwdsMatchCondition
) => {
  const mergedIgAccounts = await unifyIgAccounts(kwdsMatchedCats);
  // Flatten the array of arrays into a single array
  const kwdsMatchedAccounts = mergedIgAccounts.filter((currentAccount) =>
    searchAndMatch(
      userPrompt,
      currentAccount.keywords?.join(" "),
      kwdsMatchCondition
    )
  );
  return kwdsMatchedAccounts;
};

//STEP 5.1 MERGE ALL IG ACCOUNTS FROM ALL CATEGORIES IN ONE ARRAY
const unifyIgAccounts = async (kwdsMatchedCats) => {
  let mergedIgAccounts = await Promise.all(
    kwdsMatchedCats.map(async (currentCat) => {
      try {
        const catIgAccounts = await getIgAccounts(currentCat);
        return catIgAccounts;
      } catch (error) {
        console.error(
          `Error while retrieving category: ${currentCat} ig accounts`,
          error
        );
        return [];
      }
    })
  );
  mergedIgAccounts = mergedIgAccounts.flat();
  //Delete duplicated Instagram accounts belonging to multiple categories.
  mergedIgAccounts = removeDuplicateIgAccounts(mergedIgAccounts);
  return mergedIgAccounts;
};

//STEP 5.2
const getIgAccounts = async (category) => {
  try {
    const response = await axios.get(
      `${SERVER_URL}/instagram-accounts-categories/categories/${category.id}?columns=igAccountId,username,keywords,name`
    );
    const catIgAccounts = response.data;
    return catIgAccounts;
  } catch (error) {
    console.log(
      `error while retrieving category: ${category} ig accounts`,
      error
    );
  }
};

//STEP 5.3
const removeDuplicateIgAccounts = (igAccounts) => {
  const uniqueIgPosts = [];
  const seenIds = new Set();

  for (const currentAccount of igAccounts) {
    if (!seenIds.has(currentAccount.ig_account_id)) {
      seenIds.add(currentAccount.ig_account_id);
      uniqueIgPosts.push(currentAccount);
    }
  }

  return uniqueIgPosts;
};

//STEP 5.4
/* SearchAndMatch function written in step 2.1 */

//STEP 6: FIND COMMON KEYWORDS BETWEEN THE POSTS BELONGING TO MATCHED IG ACCOUNTS AND THE USER PROMPT
const kwdsPostsMatch = async (
  userPrompt,
  kwdsMatchedAccounts,
  kwdsMatchCondition
) => {
  const mergedIgPosts = await unifyIgPosts(kwdsMatchedAccounts);
  //posts ordered by relevance (more keyword coincidence between the copy and the prompt)
  const kwdsMatchedPosts = sortPostsByMatches(
    userPrompt,
    mergedIgPosts,
    kwdsMatchCondition
  );

  //posts that dont`t match any keyword with the user prompt
  const kwdsUnmatchedPosts = mergedIgPosts.filter(
    (currentPost) =>
      !searchAndMatch(userPrompt, currentPost.caption, kwdsMatchCondition)
  );
  return { kwdsMatchedPosts, kwdsUnmatchedPosts };
};

//STEP 6.1
const unifyIgPosts = async (kwdsMatchedAccounts) => {
  let mergedIgPosts = await Promise.all(
    kwdsMatchedAccounts.map(async (currentAccount) => {
      try {
        const accountIgPosts = await getIgPosts(currentAccount);
        return accountIgPosts;
      } catch (error) {
        console.error(
          `Error while retrieving account: ${currentAccount.username} ig posts`,
          error
        );
        return [];
      }
    })
  );
  mergedIgPosts = mergedIgPosts.flat();
  mergedIgPosts = removeDuplicateIgPosts(mergedIgPosts);
  //mergedIgPosts = mergedIgPosts.slice(1, 2);
  return mergedIgPosts;
};

//STEP 6.2
const sortPostsByMatches = (userPrompt, mergedIgPosts) => {
  //get posts that match at least with 1 kwd
  const matchingPosts = mergedIgPosts.filter((currentPost) => {
    return (
      currentPost.caption && countMatches(currentPost.caption, userPrompt) > 0
    );
  });
  const copy = [...matchingPosts];
  // Sorting the array of matching texts based on match counts
  const sorted = copy.sort(
    (a, b) =>
      countMatches(b.caption, userPrompt) - countMatches(a.caption, userPrompt)
  );

  /* const array = [{ value: 4 }, { value: 6 }, { value: 3 }, { value: 10 }, { value: 5 }, { value: 9 }]
  console.log('before', array);
  const copy = [...array];
  const numSort = copy.sort((a, b) => {
    return b.value - a.value
  })
  console.log("after", numSort);  */
  // Agrega console.log para verificar los objetos después de la ordenación

  return sorted;
};

const countMatches = (caption, userPrompt) => {
  if (!caption || !userPrompt) {
    return 0;
  }

  //clean all hashtags coincidences from caption
  const cleanedCaption = caption.replace(/#\w+/g, "");
  //trasnsform text into lowecase
  const lowercaseCaption = cleanedCaption.toLowerCase().trim();
  //initializa kwd match accumulator
  let matchCount = 0;
  for (let i = 0; i < userPrompt.length; i++) {
    const lowercasePromptKwd = userPrompt[i].toLowerCase().trim();
    if (lowercaseCaption.includes(lowercasePromptKwd)) {
      matchCount++;
    }
  }
  return matchCount;
};

//STEP 6.3
const getIgPosts = async (account) => {
  try {
    //lets retrieve all matched accounts but this time with the posts_json property
    const response = await axios.get(
      `${SERVER_URL}/instagram-accounts/${account.ig_account_id}?columns=id,posts_json`
    );
    //all posts are inside the posts_json object property of the response
    const media = response.data;
    let accountIgPosts = response.data.posts_json.media.data;
    accountIgPosts = accountIgPosts.map((post) => ({
      ig_account_id: account.ig_account_id,
      category_ids: media.category_ids,
      category_names: media.category_names,
      ig_account: media.posts_json.username,
      api_account_id: media.posts_json.id,
      caption: post.caption,
      media_id: post.id,
      media_type: post.media_type,
      media_product_type: post.media_product_type,
      media_url: post.media_url,
      permalink: post.permalink,
      timestamp: post.timestamp,
      ...(post.thumbnail_url && {
        thumbnail_url: post.thumbnail_url,
      }),
      ...(post.children && {
        children: post.children.data,
      }),
    }));
    return accountIgPosts;
  } catch (error) {
    console.log(
      `error while retrieving account: ${account.username} ig posts`,
      error
    );
  }
};

//STEP 6.4
const removeDuplicateIgPosts = (igPosts) => {
  const uniqueIgPosts = [];
  const seenIds = new Set();

  for (const currentPost of igPosts) {
    if (!seenIds.has(currentPost.media_id)) {
      seenIds.add(currentPost.media_id);
      uniqueIgPosts.push(currentPost);
    }
  }

  return uniqueIgPosts;
};

//STEP 6.5
// SearchAndMatch function written in step 2.1

//STEP 7
export const gptPostsMatch = async (userPrompt, kwdsUnmatchedPosts) => {
  //
  kwdsUnmatchedPosts = kwdsUnmatchedPosts.slice(0, 10);
  const gptMatchedPosts = await Promise.all(
    kwdsUnmatchedPosts.map(async (currentPost) => {
      const messages = gptIgPostsMessages(
        currentPost.category_names[0],
        userPrompt,
        currentPost.caption
      );
      const relatedPercent = await gptApiRequest(messages);
      if (relatedPercent > 50) {
        return currentPost;
      } else {
        return null;
      }
    })
  );
  // Filtrar elementos no nulos (los que pasaron la condición)
  const filteredPosts = gptMatchedPosts.filter((post) => post !== null);
  console.log("filteredPosts", filteredPosts);
  return filteredPosts;
};

//STEP 7.1
const gptIgPostsMessages = (accountType, userPrompt, postCaption) => {
  const messages = [
    {
      role: "system",
      content: `Se te proporcionará una descripción de una publicación de instagram y tu tarea consiste en responder con un %  según el caso:

- 100 esta completamente relacionado
- 0 no tiene nada de relación 
`,
    },
    {
      role: "user",
      content: `
        -Tipo de Cuenta: Marca de Ropa
        -Búsqueda del usuario: Gorra
        -Descripción: Protege tu cabello del sol al ir a la playa con nuestra gorra`,
    },
    {
      role: "assistant",
      content: `100: porque la gorra sirve para proteger el cabello asi que puede estar relacionado.`,
    },
    {
      role: "user",
      content: `-Tipo de cuenta: ${accountType}
    - Búsqueda del usuario: ${userPrompt}
    - Descripción: ${postCaption}`,
    },
  ];
  return messages;
};

// STEP 7.2
export const gptApiRequest = async (messages) => {
  try {
    const response = await openai.chat.completions.create({
      messages: messages,
      model: "gpt-3.5-turbo",
    });
    return response.choices[0]["message"]["content"];
    // return response.choices[0]["message"]["content"]
  } catch (error) {
    console.log("error on gpt request", error);
  }
};

//STEP 7: FILTER BY DATE TO ONLY GET POSTS SINCE LAST MONTH
const filterByLastMonth = (posts) => {
  const today = new Date(); // Get the current date

  // Calculate the date of the last month
  const lastMonth = new Date();
  lastMonth.setMonth(today.getMonth() - 1);

  // Filter objects with timestamps within the last month
  const filteredPosts = posts.filter((object) => {
    const timestamp = new Date(object.timestamp);
    return timestamp >= lastMonth && timestamp <= today;
  });

  return filteredPosts;
};

//MAIN FUNCTION
export const gptPostsFilter = async (
  userPrompt,
  kwdsMatchCondition,
  dateCondition
) => {
  let categories = await getCategories();
  /* categories = categories.filter(
    (category) => category.name === "Moda Femenina"
  ); */
  userPrompt = await cleanUserPromptWithGPT(userPrompt);
  console.log("user Prompt", userPrompt);

  const { kwdsMatchedCats, kwdsUnmatchedCats } = kwdsCategoryMatch(
    userPrompt,
    categories,
    kwdsMatchCondition
  );

  //try to find relation between kwds unmatched cats and user prompt with gpt
  //const gptMatchedCats = gptCategoryMatch(userPrompt, kwdsUnmatchedCats);

  //find match between accounts keywords and user prompt
  const kwdsMatchedAccounts = await kwdsAccountsMatch(
    userPrompt,
    kwdsMatchedCats,
    kwdsMatchCondition
  );

  //find match between posts keywords and user prompt
  const { kwdsMatchedPosts, kwdsUnmatchedPosts } = await kwdsPostsMatch(
    userPrompt,
    kwdsMatchedAccounts,
    kwdsMatchCondition
  );

  //find relation between posts copys and user prompts thanks to gpt

  /* const gptMatchedPosts = await gptPostsMatch(userPrompt, kwdsUnmatchedPosts);
  const matchedPosts = kwdsMatchedPosts.concat(gptMatchedPosts);  */

  /* let matchedIgPosts = kwdsMatchedPosts;
  if (dateCondition === "lastMonth") {
    matchedIgPosts = filterByLastMonth(matchedIgPosts);
  } */

  return {
    matchedCategories: kwdsMatchedCats,
    matchedAccounts: kwdsMatchedAccounts,
    matchedIgPosts: kwdsMatchedPosts,
  };
};
