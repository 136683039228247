import CryptoJS from "crypto-js";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const fetchAddUser = async (
  firstname,
  lastname,
  phone,
  access_token,
  role,
  email,
  password
) => {
  try {
     const encryptedPassword = CryptoJS.AES.encrypt(
       password,
       "morrodelamar"
     ).toString();
    const response = await fetch(`${SERVER_URL}/users/signup`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        firstname,
        lastname,
        phone,
        access_token,
        role,
        email,
        encryptedPassword
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const {user, status} = await response.json();
    return {user, status};
  } catch (error) {
    console.log(error);
  }
};

export const fetchAuthLocalUser = async (email, password) => {
  try {
    const encryptedPassword = CryptoJS.AES.encrypt(
      password,
      "morrodelamar"
    ).toString();
    const response = await fetch(`${SERVER_URL}/api/auth/local`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        email,
        password: encryptedPassword,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    });
    const status = await response.json();
    return status;
  } catch (error) {
    console.log(error);
  }
};
