import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Single from "../../../features/single/Single";
import {
  select_single_category,
  getCategoryById,
  updateCategory,
  select_categories,
  getDbIgCategories,
} from "../../../features/slices/categoriesSlice";
import { refreshCategoryKeywords } from "../../../features/slices/categoriesSlice";
import { useParams } from "react-router-dom";

const SingleCategoryPage = (props) => {
  /* ESTADOS REDUX */
  const reduxSingleCategory = useSelector(select_single_category);
  const categories = useSelector(select_categories);
  const dispatch = useDispatch();

  /* ESTADOS LOCALES */
  const [singleCategory, setSingleCategory] = useState({});
  const [jsonPost, setJsonPost] = useState(null);

  /* REACT ROUTER PARAMS */
  const { id } = useParams();

  useEffect(() => {
    dispatch(getCategoryById({ id }));
    dispatch(getDbIgCategories());
  }, []);

  useEffect(() => {
    if (reduxSingleCategory) {
      /* LOCAL SINGLE CATEGORY */
      setSingleCategory(reduxSingleCategory);
      /* BIG ITEM */
      setJsonPost({
        keywords: reduxSingleCategory.keywords || " ",
      });
    }
  }, [reduxSingleCategory]);

  const items = [
    {
      label: "Nombre",
      field: "name",
      type: "text",
      value: reduxSingleCategory ? reduxSingleCategory.name : "",
    },
    {
      label: "Categoria Padre",
      field: "parentCategoryId",
      type: "select",
      selectName: "categorySelect",
      options:
        categories.length > 0
          ? categories
              .filter((category) => category.id.toString() !== id)
              .map((category) => {
                return {
                  value: category.id,
                  content: category.name,
                };
              })
          : [],
      selected: reduxSingleCategory
        ? reduxSingleCategory.parent_category_id
          ? [
              {
                value: reduxSingleCategory.parent_category_id,
              },
            ]
          : []
        : [],
    },
  ];

  const refreshItem = (key) => {
    if (key === "keywords") {
      dispatch(
        refreshCategoryKeywords({
          id: id,
          name: reduxSingleCategory.name,
          parent_category_id: reduxSingleCategory.parent_category_id,
        })
      );
    }
  };

  const updateItem = (item) => {
    dispatch(
      updateCategory({
        id: id,
        name: item.name,
        parent_category_id: parseInt(item.parentCategoryId[0]),
      })
    );
  };

  return (
    <div className="flex flex-col">
      <Single
        items={items}
        bigItem={jsonPost}
        slug="Categoria"
        updateItem={updateItem}
        refreshItem={refreshItem}
      />
      <div className="p-10 flex flex-col justify-center gap-2">
        <span className="text-white font-bold">Keywords Frequency</span>
        <div
          className={`w-60 h-60 flex flex-col bg-[#EFEFEF4D] overflow-scroll px-4`}
        >
          {singleCategory.kwds_freq?.map((pair, index) => (
            <div key={index} className="flex gap-1">
              <span className="text-base">{pair[0]}</span>
              <span className="text-base font-bold">({pair[1]})</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleCategoryPage;
