import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getDbIgCategories, select_categories } from "../../../features/slices/categoriesSlice";
import { select_cities } from "../../../features/slices/citiesSlice";
import {
  getSingleIgAccountCities,
  select_sigle_ig_account_cities,
  addCityIgAccount,
  deleteCityIgAccount,
} from "../../../features/slices/citiesIgAccoutsSlice";
import Single from "../../../features/single/Single";
import {
  select_single_ig_account,
  getIgAccountById,
  updateIgAccount,
  select_is_loading,
  refreshSingleAccount,
} from "../../../features/slices/instagamAccountsSlice";
import {
  select_ig_account_categories,
  getIgAccountCategories,
  addIgAccountCategory,
  deleteIgAccountCategory,
} from "../../../features/slices/igAccountsCategoriesSlice";
import { useParams } from "react-router-dom";

const SingleIgAccountPage = (props) => {
  /* ESTADOS REDUX */
  const selectedCities = useSelector(select_sigle_ig_account_cities);
  const igAccountCategories = useSelector(select_ig_account_categories);
  const reduxSingleIgAccount = useSelector(select_single_ig_account);
  const reduxKwdsFrequency = useSelector(
    (state) => state.igAccounts.accountsKeywordsFreq
  );
  const categories = useSelector(select_categories);
  const isLoading = useSelector(select_is_loading);
  const cities = useSelector(select_cities);
  const dispatch = useDispatch();

  /* ESTADOS LOCALES */
  const [keywordsFreq, setKeywordsFreq] = useState([]);
  const [singleIgAccount, SetSingleIgAccount] = useState(null);
  const [jsonPost, setJsonPost] = useState(null);

  /* REACT ROUTER PARAMS */
  const { id } = useParams();

  /* ARRAY DE OBJETOS A PASAR AL COMPONENTE SINGLE */
  const items = [
    {
      label: "Username",
      field: "username",
      type: "text",
      value: singleIgAccount ? singleIgAccount.username : "",
    },
    {
      label: "Categoría",
      field: "categoriesIds",
      type: "select",
      selectName: "categorySelect",
      options:
        categories.length > 0
          ? categories.map((category) => {
              return {
                value: category.id,
                content: category.name,
              };
            })
          : [],
      selected:
        igAccountCategories.length > 0
          ? igAccountCategories.map((current) => {
              return {
                value: current.category_id,
                content: current.category_name,
              };
            })
          : [],
    },
    {
      label: "Ciudades",
      field: "citiesIds",
      type: "checkbox",
      options:
        cities.length > 0
          ? cities.map((city) => {
              return {
                value: city.id,
                content: city.full_name,
              };
            })
          : [],
      selected:
        selectedCities.length > 0
          ? selectedCities.map((current) => {
              return {
                value: current.city_id,
                content: current.city_name,
              };
            })
          : [],
    },
  ];

  /* USE EFFECT */
  useEffect(() => {
    //Obtener todas las categorias para mostrarlas en la lista
    dispatch(getDbIgCategories({columns:'id,name'}))
    //Obtener cuenta de instagram a partir del id
    dispatch(getIgAccountById({ id }));
    //Obtener categorias a las que pertenece la cuenta de instagram
    dispatch(getIgAccountCategories({ id }));
    //Obtener ciudades a las que pertenece la cuenta de instagram
    dispatch(getSingleIgAccountCities({ id }));
  }, []);

  /* ACTUALIZAR EL ESTADO LOCAL DE SINGLE ACCOUNT CUANDO RECIBA EL ESTADO REDUX */
  useEffect(() => {
    if (reduxSingleIgAccount) {
      console.log("ig account single", reduxSingleIgAccount);
      SetSingleIgAccount({
        username: reduxSingleIgAccount.username,
        category: reduxSingleIgAccount.category,
      });

      //actualizar estado local con los keywords de la cuenta y el json con los posts
      setJsonPost({
        posts_json: JSON.stringify(reduxSingleIgAccount.posts_json),
        keywords: reduxSingleIgAccount.keywords || " ",
      });
    }
  }, [reduxSingleIgAccount]);

  /* OBTENER FRECUENCIA DE KEYWORDS DE LA CUENTA */
  useEffect(() => {
    if (Object.keys(reduxKwdsFrequency).length > 0) {
      setKeywordsFreq(reduxKwdsFrequency[id]);
    }
  }, [reduxKwdsFrequency]);

  /* ACTUALIZAR NOMBRE, CATEGORIAS Y CIUDADES DE LA CUENTA */
  const updateItem = (item) => {
    const currentAccountCategoriesIds = igAccountCategories.map((current) =>
      current.category_id.toString()
    );

    /* Actualizar cuenta de instagram (nombre, posts, keywords)*/
    dispatch(
      updateIgAccount({
        id: id,
        username: item["username"],
        posts_json: reduxSingleIgAccount.posts_json,
        keywords: reduxSingleIgAccount.keywords,
      })
    );

    /* Agregar cuenta a categoria */
    item.categoriesIds
      .filter((categoryId) => !currentAccountCategoriesIds.includes(categoryId))
      .forEach(
        async (categoryId) =>
          await dispatch(
            addIgAccountCategory({
              instagram_account_id: id,
              category_id: categoryId,
            })
          )
      );

    /* eliminar cuenta de categoria */
    currentAccountCategoriesIds
      .filter((categoryId) => !item.categoriesIds.includes(categoryId))
      .forEach(
        async (categoryId) =>
          await dispatch(
            deleteIgAccountCategory({
              instagramAccountId: id,
              categoryId: categoryId,
            })
          )
      );

    const currentAccountCitiesIds = selectedCities.map((city) =>
      city.city_id.toString()
    );

    /* Agregar cuenta a categoria */
    item.citiesIds
      .filter((cityId) => !currentAccountCitiesIds.includes(cityId))
      .forEach(
        async (cityId) =>
          await dispatch(
            addCityIgAccount({
              instagram_account_id: id,
              city_id: cityId,
            })
          )
      );

    /* eliminar cuenta de ciudad */
    currentAccountCitiesIds
      .filter((cityId) => !item.citiesIds.includes(cityId))
      .forEach(
        async (cityId) =>
          await dispatch(
            deleteCityIgAccount({
              instagramAccountId: id,
              cityId: cityId,
            })
          )
      );
  };

  /* ACTUALIZAR KEYWORDS Y JSON POST DE LA CUENTA  */
  const refreshItem = (key) => {
    if (key === "keywords") {
      dispatch(
        refreshSingleAccount({
          id: id,
          category: reduxSingleIgAccount.category,
          username: reduxSingleIgAccount.username,
        })
      );
    } else if (key === "posts_json") {
      dispatch(
        refreshSingleAccount({
          id: id,
          category: reduxSingleIgAccount.category,
          username: reduxSingleIgAccount.username,
          index: 0,
        })
      );
    }
  };

  

  return (
    <div className="flex flex-col gap-8">
      <Single
        items={items}
        bigItem={jsonPost}
        slug="Cuenta de Instagram"
        updateItem={updateItem}
        isLoading={isLoading}
        refreshItem={refreshItem}
      />
      {/* Frequencia de Keywords  */}
      <div className="p-10 flex flex-col justify-center gap-2">
        <span className="text-white font-bold">Keywords Frequency</span>
        <div
          className={`w-60 h-60 flex flex-col bg-[#EFEFEF4D] overflow-scroll px-4`}
        >
          {keywordsFreq?.map((pair, index) => (
            <div key={index} className="flex gap-1">
              <span className="text-base">{pair[0]}</span>
              <span className="text-base font-bold">({pair[1]})</span>
            </div>
          ))}
        </div>
      </div>
      <button className="w-auto bg-red-500 cursor-pointer">Actualizar</button>
    </div>
  );
};

export default SingleIgAccountPage;
