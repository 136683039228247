/* React */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { applyActiveFilters, filterByAccount } from "../../postTracker/igFeedSlice";


/* COMIENZO DEL COMPONENTE */
const AccountsFilter = ({
  showFilter,
  setShowFilter,
  activeFilters,
  setActiveFilters,
}) => {
  const dispatch = useDispatch();
  //cuentas encontradas con la búsqueda
  //Id's de cuentas previamente marcados

  const { matchedAccounts, filteredAccountIds } = useSelector(
    (state) => state.igFeed
  );
  //letras del ABC encontradas como primera letra
  const [firstLetters, setFirstLetters] = useState();
  // Valores maracados
  const [checked, setChecked] = useState([]);

  //Inicializar valores marcados
  useEffect(() => {
    setChecked(filteredAccountIds);
  }, [filteredAccountIds]);

  useEffect(() => {
    const igAccountIds = checked.map((id) => parseInt(id));
     setActiveFilters((prevState) => {
       return { ...prevState, ...{ filteredAccountIds: igAccountIds } };
     });
  }, [checked])

  //abecedario
  const ABC = Array.from({ length: 26 }, (_, index) =>
    String.fromCharCode(65 + index)
  );

  //Filtrar las letras del ABC que se encuentran como primera letra en los nombres de las cuentas filtradas.
  useEffect(() => {
    if (matchedAccounts.length > 0) {
      const letters = ABC.filter((letter) => {
        return matchedAccounts.some((account) => {
          return account.username[0] === letter.toLowerCase();
        });
      });
      setFirstLetters(letters);
    }
  }, [matchedAccounts]);

  const handleCheckBoxClick = (e) => {
    const checkedValue = e.target.value;
    const isChecked = e.target.checked;
    setChecked((prevValues) => {
      if (isChecked) {
        return [...prevValues, parseInt(checkedValue)];
      } else {
        return [...prevValues].filter(
          (option) => option !== parseInt(checkedValue)
        );
      }
    });
  };

  //Actualizar State para filtrar las publicaciones por las cuentas seleccionadas
  const filterMedia = async () => {
    //convertir Ids en números y no en strings
    const igAccountIds = checked.map((id) => parseInt(id));
    //Aplicar los filtros previamente activos en state.filteredMedia

    await dispatch(applyActiveFilters({ filteredAccountIds: igAccountIds }));
    //Despachar Acción para filtrar por cuentas marcadas
    //dispatch(filterByAccount(igAccountIds));
  };

  return (
    //Main Container
    <div className="flex flex-col px-2 md:gap-2 xs:gap-4 overflow-auto md:pb-4 xs:pb-32">
      {firstLetters?.map((letter, index) => (
        <div key={index} className="flex flex-col">
          {/* HEADER */}
          <header>
            <h2 className="text-xl font-bold">{letter}</h2>
          </header>
          {/* ACCOUNTS LIST*/}
          <div className="">
            {matchedAccounts?.map((account, index) => {
              if (account.username[0] === letter.toLowerCase()) {
                return (
                  <div key={index} className="flex items-center gap-2">
                    <input
                      className="w-auto cursor-pointer"
                      type="checkbox"
                      value={account.id}
                      checked={checked?.some((checkedValue) => {
                        //console.log('inside input',checkedValue);
                        if (parseInt(checkedValue) === account.id) {
                          return true;
                        } else {
                          return false;
                        }
                      })}
                      onChange={(e) => handleCheckBoxClick(e)}
                    />
                    <label>
                      @
                      {account.username.charAt(0).toUpperCase() +
                        account.username.slice(1).toLowerCase()}
                      <span className="ml-2 text-xs font-bold">
                        ({account.name})
                      </span>
                    </label>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ))}
      {/* APPLY BUTTON */}
      <div
        className={`xs:z-4 xs:w-full xs:flex xs:justify-center xs:items-center xs:border-x-0 xs:border-t-[1px] xs:font-bold xs:transition-height xs:duration-300 xs:ease-in-out xs:p-6 bg-opacity-95 bg-white md:hidden ${
          checked !== filteredAccountIds ? `xs:fixed bottom-0` : `xs:hidden`
        }`}
      >
        <button
          className="bg-[#FF9933] hover:bg-[#FFB366] hover:text-white
 py-2.5 px-11 rounded-md"
          onClick={() => {
            setShowFilter(!showFilter);
            filterMedia();
          }}
        >
          Aplicar
        </button>
      </div>
    </div>
  );
};

export default AccountsFilter;
