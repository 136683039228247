import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// GET FAVORITE COLLECTIONS
export const getCollections = createAsyncThunk(
  "collections/getCollections",
  async (userId, thunkAPI) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/api/favorites/collections/users/${userId}`
      );
      const favorites = response.data;
      return favorites;
    } catch (error) {
      console.log(`error en getCollections thunk`, error);
    }
  }
);

//ADD FAVORITE COLLECTION
export const addCollection = createAsyncThunk(
  "collections/addCollection",
  async ({ userId, collectionName }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/api/favorites/collections/`,
        {
          userId,
          collectionName,
        }
      );
      const newCollection = response.data;
      return newCollection;
    } catch (error) {
      console.log(`error on addFavorite thunk`, error);
    }
  }
);

//UPDATE COLLECTION
export const updateCollection = createAsyncThunk(
  "collections/updateCollection",
  async ({ collectionId, collectionName }, thunkAPI) => {
    try {
      const response = await axios.put(
        `${SERVER_URL}/api/favorites/collections/${collectionId}`,
        {
          collectionName,
        }
      );
      const updatedCollection = response.data;
      return updatedCollection;
    } catch (error) {
      console.log(error);
    }
  }
);

// DELETE FAVORITE COLLECTION
export const deleteCollection = createAsyncThunk(
  "collections/deleteCollection",
  async (collectionId, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/api/favorites/collections/${collectionId}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const collectionsSlice = createSlice({
  name: "collections",
  initialState: {
    collections: [],
    isLoading: false,
    hasError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //GET
      .addCase(getCollections.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
       
      })
      .addCase(getCollections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.collections = action.payload;
      })
      .addCase(getCollections.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      //DELETE
      .addCase(deleteCollection.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(deleteCollection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.collections = current(state).collections.filter(
          (collection) => collection.id !== action.payload.id
        );
      })
      .addCase(deleteCollection.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      //ADD
      .addCase(addCollection.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(addCollection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.collections.push(action.payload);
      })
      .addCase(addCollection.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      //UPDATE
      .addCase(updateCollection.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(updateCollection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const index = current(state).collections.findIndex(
          (collection) => collection.id === action.payload.id
        );
        if (index !== -1) {
          state.collections[index] = action.payload;
        }
      })
      .addCase(updateCollection.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      });
  },
});

export default collectionsSlice.reducer;
