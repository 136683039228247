import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { addCityIgAccount } from "./citiesIgAccoutsSlice";
import {
  accountKeywordsFreq,
  indexKeywords,
} from "../../utils/keywordsFrequency";
import {
  getAccountsByCategoryId,
  addIgAccountCategory,
} from "./igAccountsCategoriesSlice";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const accessTokens = [
  process.env.REACT_APP_ACCESS_TOKEN_1,
  process.env.REACT_APP_ACCESS_TOKEN_2,
  process.env.REACT_APP_ACCESS_TOKEN_3,
  process.env.REACT_APP_ACCESS_TOKEN_4,
  process.env.REACT_APP_ACCESS_TOKEN_8,
];

/* OBTENER TODAS LAS CUENTAS*/
export const retrieveAllIgAccounts = createAsyncThunk(
  "igAccounts/retrieveAllIgAccounts",
  async (arg, thunkAPI) => {
    const response = await axios.get(`${SERVER_URL}/api/instagram-accounts`, {
      params: {
        columns:'id,username'
      }
    });
    const ig_accounts = response.data;
    return ig_accounts;
  }
);

/* OBTENER CUENTA POR ID*/
export const getIgAccountById = createAsyncThunk(
  "igAccounts/getIgAccountById",
  async ({ id }, thunkAPI) => {
    const response = await axios.get(
      `${SERVER_URL}/api/instagram-accounts/${id}`
    );
    const ig_account = response.data;
    return ig_account;
  }
);

/* AGREGAR CUENTA*/
export const addIgAccount = createAsyncThunk(
  "igAccounts/addIgAccount",
  async (
    {
      username = null,
      posts_json = null,
      keywords = null,
      category_id = null,
      cities_id = null,
    },
    thunkAPI
  ) => {
    const response = await axios.post(
      `${SERVER_URL}/api/instagram-accounts/add-instagram-account`,
      {
        username,
        posts_json,
        keywords,
      }
    );
    const new_ig_account = response.data;
    await thunkAPI.dispatch(
      addIgAccountCategory({
        instagram_account_id: new_ig_account.id,
        category_id: category_id,
      })
    );

    await Promise.all(
      cities_id.forEach((city_id) => {
        thunkAPI.dispatch(
          addCityIgAccount({
            instagram_account_id: new_ig_account.id,
            city_id: city_id,
          })
        );
      })
    );

    await thunkAPI.dispatch(retrieveAllIgAccounts());
    return new_ig_account;
  }
);

/* AGREGAR LOTES DE CUENTAS DESDE UN CSV*/
export const bulkIgAccount = createAsyncThunk(
  "igAccounts/bulkIgAccount",
  async ({ csvFile }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/api/instagram-accounts/add-bulk`,
        csvFile
      );
      const feedback = response.data;
      return feedback;
    } catch (error) {
      console.log(error);
    }
  }
);

/* ELIMINAR CUENTA */
export const deleteIgAccount = createAsyncThunk(
  "igAccounts/deleteIgAccount",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/api/instagram-accounts/${id}`
      );
      const deleted_ig_account = response.data;
      return deleted_ig_account;
    } catch (error) {
      console.log("failed to deleted category", error);
    }
  }
);

/* ACTUALIZAR CUENTA*/
export const updateIgAccount = createAsyncThunk(
  "igAccounts/updateIgAccount",
  async ({ id, username, posts_json, keywords, updated_at }, thunkAPI) => {
    try {
      const response = await axios.put(
        `${SERVER_URL}/api/instagram-accounts/${id}`,
        {
          username,
          posts_json,
          keywords,
          updated_at,
        }
      );
      const updatedIgAccount = response.data;
      return updatedIgAccount;
    } catch (error) {
      console.log(error);
    }
  }
);

/* Actualizar los posts y keywords de las cuentas de instagram pertenecientes a una categoria */
export const refreshMultipleAccounts = createAsyncThunk(
  "igAccounts/refreshMultipleAccounts",
  async ({ categoryId }, thunkAPI) => {
    try {
      /* Obtener todas las cuentas pertenecientes a una categoria */
      await thunkAPI.dispatch(
        getAccountsByCategoryId({ categoryId: categoryId })
      );
      const categoryAccounts =
        thunkAPI.getState().igAccountsCategories.categoryAccounts;

      /* Obtener cuentas que tienen tiempo sin actualizar >=24hrs */
      const currentTime = Date.now();
      const accountsToUpdate = categoryAccounts.filter((account) => {
        const lastUpdatedTime = new Date(account.updated_at).getTime();
        const hoursSinceUpdate =
          (currentTime - lastUpdatedTime) / (1000 * 60 * 60);
        return hoursSinceUpdate >= 24;
      });

      /* Actualizar cuentas con tiempo sin actualizar >= 24hrs  */
      await Promise.all(
        accountsToUpdate.map(async (account, index) => {
          await thunkAPI.dispatch(
            refreshSingleAccount({
              id: account.ig_account_id,
              username: account.username,
              index: index,
            })
          );
        })
      );
    } catch (error) {
      console.log(error);
    }
  }
);

/* Actualizar posts y keywords de una cuenta */
export const refreshSingleAccount = createAsyncThunk(
  "igAccounts/refreshSingleAccount",
  async ({ id, username, index }, thunkAPI) => {
    try {
      /* Alternamos entre los tokens gracias al residuo (%) */
      //const currentAccessToken = accessTokens[index % accessTokens.length];
      const currentAccessToken =
        "EAAKdUbiZApSQBO47ZB5HiGEjDpAArMkSxbLdfgZAxaITQEk6frFVhVFrfFCZA3jHsXPzT3N2fFCSJcOQhBsZCT7w9P4VDZBxxoZB73hugcO1EzeEWURCzJpVYBJ6qrnaSj9N8rSZAyBHiPhOv1HUIElQi9ylTJappbdsNRms5AgJ2w9sYcql6BL3SslrWBHYZBsdQlaQ0NQZDZD";

      const response = await axios.get(
        `https://graph.facebook.com/v17.0/${process.env.REACT_APP_KONTEVO_CONNECTED_IG}?fields=business_discovery.username(${username}){username,name,media.limit(30){caption, timestamp, media_url, media_type, media_product_type, permalink, thumbnail_url, children{media_type, media_url}}}&access_token=${currentAccessToken}`
      );

      const jsonPosts = response.data.business_discovery;

      /* actualizar los keywords */
      const media = jsonPosts.media.data;
      const keywords = indexKeywords(media);
      //Obtener frecuencia de cadda keywords en copys de publicaciones de la cuenta.
      const keywordsFrequency = accountKeywordsFreq(media);
      /* última fecha de actualización de la cuenta */
      const updatedAt = new Date();
      /* retornar nuevos posts y keywords */
      await thunkAPI.dispatch(
        updateIgAccount({
          id: id,
          username: username,
          posts_json: jsonPosts,
          keywords: keywords,
          updated_at: updatedAt,
        })
      );
      return { accountId: id, keywordsFrequency: keywordsFrequency };
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.log("Api limit request reached", error);
      } else {
        console.log(
          "ocurrio un error al actualizar las cuentas de instagram",
          error
        );
      }
    }
  }
);

/*SLICE */
export const instagramAccountsSlice = createSlice({
  name: "igAccounts",
  initialState: {
    ig_accounts: [],
    single_ig_account: null,
    accountsKeywordsFreq: {},
    isLoading: false,
    hasErrorr: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(refreshSingleAccount.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(refreshSingleAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const { accountId, keywordsFrequency } = action.payload;
        state.accountsKeywordsFreq[accountId] = keywordsFrequency;
      })
      .addCase(refreshSingleAccount.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(refreshMultipleAccounts.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(refreshMultipleAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        //const categoryName = action.payload;
      })
      .addCase(refreshMultipleAccounts.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(updateIgAccount.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(updateIgAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const updatedIgAccount = action.payload;
        state.single_ig_account = updatedIgAccount;
        /* state.accountsKeywordsFreq = current(state).accountsKeywordsFreq[updatedIgAccount.username] =  */
        const index = current(state).ig_accounts.findIndex(
          (account) => account.id === updatedIgAccount.id
        );
        if (index !== -1) {
          state.ig_accounts[index] = updatedIgAccount;
        } else {
          console.log("Cuenta no encontrada");
        }
      })
      .addCase(updateIgAccount.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(getIgAccountById.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getIgAccountById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const single_ig_account = action.payload;
        state.single_ig_account = single_ig_account;
      })
      .addCase(getIgAccountById.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(addIgAccount.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(addIgAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const new_ig_account = action.payload;
        state.ig_accounts.push(new_ig_account);
      })
      .addCase(addIgAccount.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(retrieveAllIgAccounts.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(retrieveAllIgAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        let ig_accounts = action.payload;
        state.ig_accounts = ig_accounts;
      })
      .addCase(retrieveAllIgAccounts.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(deleteIgAccount.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(deleteIgAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const deleted_ig_account = action.payload;
        state.ig_accounts = state.ig_accounts.filter(
          (account) => account.id !== deleted_ig_account.id
        );
      })
      .addCase(deleteIgAccount.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(bulkIgAccount.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(bulkIgAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const feedback = action.payload;
        alert(
          `The following accounts already existed: \n ${feedback.prevAdded.join(
            "\n"
          )}`
        );
      })
      .addCase(bulkIgAccount.rejected, (state) => {
        state.isLoading = false;
        state.hasError = true;
      });
  },
});

export const select_single_ig_account = (state) =>
  state.igAccounts.single_ig_account;
export const select_is_loading = (state) => state.igAccounts.isLoading;
export const select_ig_accounts = (state) => state.igAccounts.ig_accounts;
export default instagramAccountsSlice.reducer;
