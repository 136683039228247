import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Connections from "../../../features/connections/Connections";
import DataTable from "../../../features/dataTable/DataTable";
import AddModal from "../../../features/addModal/AddModal";
import "./locationsPage.scss";
import { select_cities_ig_accounts, select_single_city_ig_accounts} from "../../../features/slices/citiesIgAccoutsSlice";
import {
  addCity,
  deleteCity,
  select_cities,
} from "../../../features/slices/citiesSlice";
import { getSingleCityAccounts } from "../../../features/slices/citiesIgAccoutsSlice";


const columns = [
  { field: "id", headerName: "Id", width: 90, type: "number" },
  {
    field: "full_name",
    headerName: "Full Name",
    width: 200,
    type: "string",
  },
];

const secondColumns = [
  { field: "id", headerName: "Id", width: 90, type: "number" },
  {
    field: "full_name",
    headerName: "Full Name",
    width: 200,
    type: "string",
  },
];

const LocationsPage = (props) => {
  const cityIgAccounts = useSelector(select_single_city_ig_accounts);
  console.log("city Accounts", cityIgAccounts);
  const cities = useSelector(select_cities);
  const [open, setOpen] = useState(false);
  const [secondOpen, setSecondOpen] = useState(false);
  const dispatch = useDispatch();

  const createItem = (item) => {
    dispatch(addCity(item));
  };

  const deleteItem = (id) => {
    dispatch(deleteCity({ id }));
  };

  const getItems = (id) => {
    dispatch(getSingleCityAccounts({id}));
  };

  return (
    <div className="admin-categories">
      <div className="info">
        <h1>Ciudades</h1>
        <button
          onClick={() => {
            setOpen(true);
          }}
        >
          agregar nueva ciudad
        </button>
      </div>
      <DataTable
        slug="locations"
        columns={columns}
        rows={cities}
        deleteItem={deleteItem}
      />
      {open && (
        <AddModal
          slug="city"
          columns={columns}
          setOpen={setOpen}
          createItem={createItem}
        />
      )}
      <Connections
        selectName="Cities"
        options={cities}
        addButton="ciudad"
        listItems={cityIgAccounts}
        getListItems={getItems}
      />
      {secondOpen && (
        <AddModal
          slug="city"
          columns={secondColumns}
          setOpen={setOpen}
          createItem={createItem}
        />
      )}
    </div>
  );
};

export default LocationsPage;
