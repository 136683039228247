/* React */
import React, { useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import FilterView from "../filterView/FilterView";

/* state */
import {
  select_matched_accounts,
  select_filtered_media,
} from "../../postTracker/igFeedSlice";

/* Icons */
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";

/* INICIO DEL COMPONENTE */
const MobileFilters = ({
  isMobileFilterOpen,
  setIsMobileFilterOpen,
  filterNames,
}) => {
  /* Local State */
  //Filtro que desea ver el usuario
  const [chosenFilter, setChosenFilter] = useState();
  //Abrir Filtro
  const [showFilter, setShowFilter] = useState();
  //filtros seleccionados
  const [activeFilters, setActiveFilters] = useState({});

  const matchedAccounts = useSelector(select_matched_accounts);
  const filteredMedia = useSelector(select_filtered_media);
  const dispatch = useDispatch();

  return (
    <div
      id="filtersContainer"
      /* Quitar md:hidden para mostrar filtros en pantallas grandes */
      className={`md:hidden md:w-full md:h-full xs:flex xs:flex-col xs:gap-4 xs:fixed xs:bottom-0 xs:left-0 xs:w-full xs:h-0 xs:overflow-hidden xs:transition-height xs:duration-300 xs:ease-in-out xs:z-[3] bg-white ${
        isMobileFilterOpen && `xs:h-full`
      }`}
    >
      {/* FILTERS HEADER */}
      <header className="xs:flex xs:justify-between xs:items-center xs:relative xs:px-2 xs:pt-12 xs:border-b-[1px] xs:border-black">
        <h2 className="text-2xl font-bold">
          <span className="after:!w-full after:!bg-black after:!h-4">
            Filtros
          </span>
        </h2>
        <button
          className="absolute right-0 top-0 m-3 active:text-[#727272]"
          onClick={() => {
             setIsMobileFilterOpen(!isMobileFilterOpen);
          }}
        >
          <CloseIcon />
        </button>
      </header>
      {/* FILTERS LIST */}
      <div className="px-2 flex flex-col gap-3">
        {filterNames.map((name, index) => (
          <div
            className="font-bold cursor-pointer py-3 hover:bg-[#f5f5f5]"
            key={index}
          >
            <span
              onClick={() => {
                setChosenFilter(name);
                setShowFilter(true);
              }}
            >
              {name}
            </span>
          </div>
        ))}
      </div>
      {/* FILTERS FOOTER */}
      <div
        className={`xs:z-4 xs:w-full xs:flex xs:justify-center xs:items-center xs:border-x-0 xs:border-t-[1px] xs:font-bold xs:transition-height xs:duration-300 xs:ease-in-out xs:p-6 bg-opacity-95 bg-white`}
      >
        <button
          className={`bg-[#FF9933] hover:bg-[#FFB366] hover:text-white
 py-2.5 px-11 rounded-md`}
          onClick={() => {
            setIsMobileFilterOpen(!isMobileFilterOpen);
          }}
        >
          Mostrar {filteredMedia.length}
          + resultados <ArrowForwardIosIcon />
        </button>
      </div>
      <FilterView
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        chosenFilter={chosenFilter}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
      />
    </div>
  );
};

export default MobileFilters;
