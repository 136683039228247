import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteCategory,
  select_categories,
  select_parent_categories,
} from "../../../features/slices/categoriesSlice";
import {
  addCategory,
  refreshCategories,
} from "../../../features/slices/categoriesSlice";
import DataTable from "../../../features/dataTable/DataTable";
import AddModal from "../../../features/addModal/AddModal";
import Updates from "../../../features/updates/Updates";
import "./categoriesPage.scss";

const columns = [
  { field: "id", headerName: "ID", width: 90, type: "number" },
  {
    field: "name",
    headerName: "Name",
    width: 150,
    type: "string",
  },
  {
    field: "keywords",
    headerName: "Keywords",
    width: 200,
    type: "string",
  },
  {
    field: "parent_category_id",
    headerName: "Parent Category Id",
    width: 90,
    type: "number",
  },
];

const AdminCategoriesPage = (props) => {
  const categories = useSelector(select_categories);
  const reduxParentCategories = useSelector(select_parent_categories);
  const [parentCategories, setParentCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const formInputs = [
    {
      label: "Nombre",
      field: "name",
      type: "text",
    },
    {
      label: "Categoria Padre",
      field: "parentCategoryId",
      type: "select",
      selectName: "categorySelect",
      options:
        categories.length > 0
          ? categories.map((category) => {
              return {
                value: category.id,
                content: category.name,
              };
            })
          : [],
    },
  ];

  useEffect(() => {
    if (reduxParentCategories.length > 0) {
      setParentCategories((prevParentCategories) => [
        ...prevParentCategories,
        ...reduxParentCategories.map((category) => ({
          category_id: category.id,
          category_name: category.name,
          category_keywords: category.keywords,
          parent_category_id: category.parent_category_id,
        })),
      ]);
    }
  }, [reduxParentCategories]);

  const createItem = (item) => {
    dispatch(
      addCategory({
        name: item.name,
        parent_category_id: item.hasOwnProperty("parentCategoryId")
          ? parseInt(item.parentCategoryId)
          : null,
      })
    );
  };

  const deleteItem = (id) => {
    dispatch(deleteCategory({ id }));
  };

  const refreshCategoriesKeywords = (parentCategory) => {
    dispatch(
      refreshCategories({
        parentCategory: {
          id: parentCategory.category_id,
          name: parentCategory.category_name,
          parent_category_id: parentCategory.parent_category_id,
        },
      })
    );
  };

  return (
    <div className="admin-categories">
      <div className="info">
        <h1>Categories</h1>
        <button
          onClick={() => {
            setOpen(true);
          }}
        >
          Add new category
        </button>
      </div>
      <DataTable
        slug="categories"
        columns={columns}
        rows={categories}
        deleteItem={deleteItem}
      />
      {parentCategories.length > 0 && (
        <div className="updates">
          <Updates
            options={parentCategories}
            updateItem={refreshCategoriesKeywords}
            slug="Categoría"
            subSlug="Keywords"
          />
        </div>
      )}
      {open && (
        <AddModal
          slug="category"
          setOpen={setOpen}
          createItem={createItem}
          formInputs={formInputs}
        />
      )}
    </div>
  );
};

export default AdminCategoriesPage;
