import { configureStore } from "@reduxjs/toolkit";
import citiesIgAccountsReducer from "../features/slices/citiesIgAccoutsSlice";
import instagramContentReducer from "../features/slices/postsSlice";
import favoriteItemsReducer from "../features/slices/favoriteItemsSlice";
import collectionsReducer from "../features/slices/collectionsSlice";
import categoriesReducer from "../features/slices/categoriesSlice";
import favoritesReducer from "../features/slices/favoritesSlice";
import igFeedReducer from "../features/postTracker/igFeedSlice";
import postgresReducer from "../features/slices/postgresSlice";
import searchesReducer from "../features/slices/searchesSlice";
import feedbacksReducer from "../features/slices/feedbacksSlice";
import citiesReducer from "../features/slices/citiesSlice";
import usersReducer from "../features/users/usersSlice";
import chatsReducer from "../features/chats/chatsSlice";
import postsReducer from "../features/slices/postsSlice";
import instagramAccountsReducer from "../features/slices/instagamAccountsSlice";
import igAccountsCategoriesReducer from "../features/slices/igAccountsCategoriesSlice";

export const store = configureStore({
  reducer: {
    posts:postsReducer,
    users: usersReducer,
    chats: chatsReducer,
    cities: citiesReducer,
    igFeed: igFeedReducer,
    searches: searchesReducer,
    postgres: postgresReducer,
    feedbacks: feedbacksReducer,
    favorites: favoritesReducer,
    categories: categoriesReducer,
    collections: collectionsReducer,
    favoriteItems: favoriteItemsReducer,
    igAccounts: instagramAccountsReducer,
    citiesIgAccounts: citiesIgAccountsReducer,
    instagramContent: instagramContentReducer,
    igAccountsCategories: igAccountsCategoriesReducer,
  },
});

