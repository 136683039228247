import React, { useState } from "react";

const ImageMedia = ({ url, post, setUrlHasError }) => {
  const handleUrlError = () => {
    setUrlHasError(true);
  };

  return (
    <div className="w-full">
      <img
        id="imgContainer"
        className="md:w-full xs:w-full md:h-[414px] xs:h-[414px] mb-0"
        alt=""
        src={url}
        onError={handleUrlError}
      />
    </div>
  );
};

export default ImageMedia;
