import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./usersPage.scss";
import DataTable from "../../../features/dataTable/DataTable";
import { select_users, addUser, deleteUser } from "../../../features/users/usersSlice";
import AddModal from "../../../features/addModal/AddModal";

const columns = [
  { field: "id", headerName: "ID", width: 90, type: "number" },
  {
    field: "firstname",
    headerName: "First name",
    width: 150,
    editable: true,
    type: "string",
  },
  {
    field: "lastname",
    headerName: "Last name",
    width: 150,
    editable: true,
    type: "string",
  },
  {
    field: "phone",
    headerName: "Phone",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "role",
    headerName: "Role",
    description: "Rol del usuario en la app",
    sortable: true,
    type: "string",
    width: 100,
  },
  {
    field: "email",
    headerName: "Email",
    description: "email del usuario",
    sortable: false,
    type: "string",
    width: 200,
  },
  {
    field: "password",
    headerName: "Password",
    description: "contraseña del usuario",
    type: "password",
    width: 100,
  },
];

const UsersPage = (props) => {
  //los usuarios son filas de la tabla
  const users = useSelector(select_users);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const createItem = (item) => {
    dispatch(addUser(item));
  };

  const deleteItem = (id) => {
    dispatch(deleteUser({ id }));
  }

  return (
    <div className="admin-users">
      <div className="info">
        <h1>Users</h1>
        <button
          onClick={() => {
            setOpen(true);
          }}
        >
          Add new user
        </button>
      </div>
      <DataTable
        slug="users"
        columns={columns}
        rows={users}
        deleteItem={deleteItem}
      />
      {open && (
        <AddModal
          slug="user"
          columns={columns}
          setOpen={setOpen}
          createItem={createItem}
        />
      )}
    </div>
  );
};

export default UsersPage;
