import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import {
  select_active_filters,
  filterByDate,
  applyActiveFilters,
} from "../../postTracker/igFeedSlice";
import "./dateFilter.scss";

/* COMIENZO DEL COMPONENTE */
const DateFilter = ({
  showFilter,
  setShowFilter,
  activeFilters,
  setActiveFilters,
}) => {
  //Estados de Fecha de inicio y fecha final
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  //Despachador de redux
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveFilters((prevState) => {
      return {
        ...prevState,
        ...{ filteredStartDate: startDate, filteredEndDate: endDate },
      };
    });
  }, [startDate, endDate]);

  const handleStartDateChange = (e) => {
    const date = e.target.value;
    setStartDate(date);
  };

  const handleEndDateChange = (e) => {
    const date = e.target.value;
    setEndDate(date);
  };

  const filterMedia = async () => {
    //Aplicar los filtros previamente activos en state.filteredMedia + el nuevo filtro por fecha
    await dispatch(
      applyActiveFilters({
        filteredStartDate: startDate,
        filteredEndDate: endDate,
      })
    );
  };

  return (
    <div className="flex flex-col md:mt-5 xs:mt-20 gap-8">
      {/* CONTAINER FECHA DE INICIO */}
      <div className="flex justify-center">
        <div className="w-full flex flex-col gap-1 items-center ">
          <label className="text-base font-bold">Fecha Inicio</label>
          <input
            className="border border-solid py-2 px-4 rounded-xl cursor-pointer hover:!border-[#66CC33]"
            value={startDate}
            type="date"
            onChange={handleStartDateChange}
          />
        </div>
        {/* CONTAINER FECHA FINAL */}
        <div className="w-full flex flex-col gap-1 items-center">
          <label className="text-base font-bold">Fecha Final</label>
          <input
            className="border border-solid py-2 px-4 rounded-xl cursor-pointer hover:!border-[#66CC33]"
            value={endDate}
            type="date"
            onChange={handleEndDateChange}
          />
        </div>
      </div>
      <div
        className={`xs:z-4 xs:w-full xs:flex xs:justify-center xs:items-center xs:border-x-0 xs:border-t-[1px] xs:font-bold xs:transition-height xs:duration-300 xs:ease-in-out xs:p-6 bg-opacity-95 bg-white md:hidden`}
      >
        <button
          className="bg-[#FF9933] hover:bg-[#FFB366] hover:text-white
 py-2.5 px-11 rounded-md"
          onClick={() => {
            setShowFilter(!showFilter);
            filterMedia();
          }}
        >
          Aplicar
        </button>
      </div>
    </div>
  );
};

export default DateFilter;
