import React, { useEffect, useState } from "react";
/* redux state */
import { useDispatch } from "react-redux";
/* css */
import "./addModal.scss";
/* state */
import { addUser } from "../users/usersSlice";

const AddModal = ({ setOpen, slug, formInputs, createItem }) => {
  const [formValues, setFormValues] = useState({}); // Estado para almacenar
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    createItem(formValues);
    setOpen(false);
  };

  const handleCheckboxChange = (e, field) => {
    const newValue = e.target.value;
    const isChecked = e.target.checked;

    setFormValues((prevValues) => {
      if (isChecked) {
        if (prevValues.hasOwnProperty(field)) {
          return {
            ...prevValues,
            [field]: [...prevValues[field], newValue],
          };
        } else {
          return {
            ...prevValues,
            [field]: [newValue],
          };
        }
      } else {
        return {
          ...prevValues,
          [field]: prevValues[field].filter((id) => id !== newValue),
        };
      }
    });
  };


  const handleOnChange = (e, field) => {
    const newValue = e.target.value;
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: newValue,
    }));
  };

  return (
    <div className="admin-add">
      <div className="add-modal">
        <span className="close" onClick={() => setOpen(false)}>
          X
        </span>
        <h1>Add new {slug}</h1>
        <form>
          {formInputs.map((formInput, key) => {
            return (
              <div className="item" key={key}>
                <label>{formInput.label}</label>
                {formInput.type === "text" ? (
                  <input
                    className="text-input"
                    type={formInput.type}
                    placeholder={formInput.field}
                    value={formValues[formInput.field] || ""}
                    onChange={(e) => handleOnChange(e, formInput.field)}
                  />
                ) : formInput.type === "select" ? (
                  <select
                    className="select-input"
                    name={formInput.selectName}
                    id={formInput.selectName}
                    onChange={(e) => handleOnChange(e, formInput.field)}
                  >
                    <option key={key} value={null}>
                      Selecciona una 
                    </option>
                    {formInput.options.map((option, key) => {
                      return (
                        <option key={key} value={option.value}>
                          {option.content}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <div className="checkbox-container">
                    {formInput.options.map((option, key) => {
                      return (
                        <div key={key} className="checkbox-option">
                          <input
                            className="checkbox"
                            type="checkbox"
                            id={option.value}
                            value={option.value}
                            onChange={(e) =>
                              handleCheckboxChange(e, formInput.field)
                            }
                          />
                          <label className="label" key={key}>
                            {option.content}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
          <button type="button" onClick={handleSubmit}>
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddModal;
