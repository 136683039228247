import React, { useState } from "react";
import { Figure, Carousel } from "react-bootstrap";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CarouselMedia from "./CarouselMedia";
import ImageMedia from "./ImageMedia";

/* React Bootstrap */
import { Col } from "react-bootstrap";
import VideoMedia from "./VideoMedia";

const PostMedia = ({
  mediaUrl,
  thumbnailUrl,
  carrouselSlides,
  post,
  setUrlHasError,
}) => {
  const [playVideo, setPlayVideo] = useState(false);

  const handleOnClick = () => {
    setPlayVideo(true);
  };

  return (
    <div
      id="postImgContainer"
      className="md:w-full xs:w-full radius-[5px] flex flex-col p-0 relative"
    >
      {!playVideo ? (
        carrouselSlides ? (
          <CarouselMedia
            carrouselSlides={carrouselSlides}
            setUrlHasError={setUrlHasError}
          />
        ) : (
          <>
            <ImageMedia
              url={thumbnailUrl ? thumbnailUrl : mediaUrl}
              post={post}
              setUrlHasError={setUrlHasError}
            />
            {(thumbnailUrl && mediaUrl) && (
              <button
                className="py-1.5 px-3 position-absolute top-1/2 start-1/2 translate-middle bg-white border rounded-lg hover:!bg-[#FF9933] hover:!border-[#FF9933]"
                onClick={handleOnClick}
              >
                <PlayArrowIcon />
              </button>
            )}
          </>
        )
      ) : (
        <VideoMedia url={mediaUrl} setUrlHasError={setUrlHasError} />
      )}
    </div>
  );
};

export default PostMedia;
