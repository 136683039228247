import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  getPostsByAccountId,
  setPosts,
} from "../../features/slices/postsSlice";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Post from "../../features/media/feedPost/Post";
import { keywords } from "../../dummy";

const BusinessProfilePage = () => {
  const { posts, isLoading } = useSelector((state) => state.posts);
  const { accountId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPostsByAccountId({ accountId: accountId, keywords: keywords }));
  }, []);

  const handleLeftArrowClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    console.log('posts', posts);
  },[posts])

  return (
    <div className="md:h-[90%] flex flex-wrap justify-center gap-4 md:overflow-y-scroll  md:px-64 md:pb-12">
      <div className="w-full relative flex-center header bg-[#66CC33] py-4 font-bold text-[18px]">
        <span className="absolute left-3" onClick={handleLeftArrowClick}>
          <KeyboardArrowLeftIcon
            style={{ color: "white", fontSize: "30px", cursor: "pointer" }}
          />
        </span>
        <h3 className="text-[15px] text-white font-bold">
          {posts[0]?.ig_account_name}
        </h3>
      </div>
      {posts?.map((post, index) => {
        if (post.status !== "deleted") {
          return <Post key={index} index={index} post={post} />;
        }
      })}
      {isLoading && <p>Cargando...</p>}
    </div>
  );
};

export default BusinessProfilePage;
